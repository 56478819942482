import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderCarriersEditPropsInterface,
  ProviderCarriersUpdateInterface,
} from '../../../interfaces/providersInterface';
import { updateProviderCarriersAPIRequest } from '../../../api/provider/providerCarrier';

const initialState: ProviderCarriersEditPropsInterface = {
  editProviderCarriersLoading: false,
  editProviderCarriersData: {},
  editProviderCarriersError: null,
  editProviderCarriersStatus: 'IDLE',
};

export const editProviderCarriersRequest: any = createAsyncThunk(
  'provider/carriers/edit',
  async (carrier: ProviderCarriersUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateProviderCarriersAPIRequest(carrier);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderCarriersSlice = createSlice({
  name: 'editProviderCarriers',
  initialState,
  reducers: {
    clearEditProviderCarriersResponse: (state) => {
      state.editProviderCarriersLoading = false;
      state.editProviderCarriersStatus = 'IDLE';
      state.editProviderCarriersData = {};
      state.editProviderCarriersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProviderCarriersRequest.pending, (state, action) => {
        state.editProviderCarriersLoading = true;
        state.editProviderCarriersStatus = 'PENDING';
      })
      .addCase(editProviderCarriersRequest.fulfilled, (state, action) => {
        state.editProviderCarriersData = action.payload;
        state.editProviderCarriersStatus = 'SUCCESS';
        state.editProviderCarriersLoading = false;
      })
      .addCase(editProviderCarriersRequest.rejected, (state, action) => {
        state.editProviderCarriersLoading = false;
        state.editProviderCarriersError = action.payload;
        state.editProviderCarriersStatus = 'FAILED';
      });
  },
});

export const { clearEditProviderCarriersResponse } = editProviderCarriersSlice.actions;

export const editProviderCarriersSelector = (state: RootState) => state.EditProviderCarriers;

export default editProviderCarriersSlice.reducer;
