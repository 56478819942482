import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientAddressFetchByIdInitialPropsInterface,
  PatientAddressGetByIdInterface,
  PatientFectchByIdInitialPropsInterface,
  PatientFetchByIdInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import { getPatientByIdAPIRequest, getPatientsAPIRequest } from '../../../api/patient/patient';
import { getPatientAddressByIdAPIRequest } from '../../../api/patient/patientAddress';

const initialState: PatientAddressFetchByIdInitialPropsInterface = {
  fetchPatientAddressByIdLoading: false,
  fetchPatientAddressByIdData: {},
  fetchPatientAddressByIdStatus: 'IDLE',
  fetchPatientAddressByIdError: null,
};

export const getPatientAddressByIdRequest: any = createAsyncThunk(
  'patients/get/address/id',
  async (searchParams: PatientAddressGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientAddressByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientAddressByIdSlice = createSlice({
  name: 'fetchPatientAddressById',
  initialState,
  reducers: {
    clearFetchPatientAddressByIdResponse: (state) => {
      state.fetchPatientAddressByIdLoading = false;
      state.fetchPatientAddressByIdData = {};
      state.fetchPatientAddressByIdStatus = 'IDLE';
      state.fetchPatientAddressByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientAddressByIdRequest.pending, (state, action) => {
        state.fetchPatientAddressByIdLoading = true;
        state.fetchPatientAddressByIdStatus = 'PENDING';
      })
      .addCase(getPatientAddressByIdRequest.fulfilled, (state, action) => {
        state.fetchPatientAddressByIdLoading = false;
        state.fetchPatientAddressByIdStatus = 'SUCCESS';
        state.fetchPatientAddressByIdData = action.payload;
      })
      .addCase(getPatientAddressByIdRequest.rejected, (state, action) => {
        state.fetchPatientAddressByIdLoading = false;
        state.fetchPatientAddressByIdError = action.payload;
        state.fetchPatientAddressByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientAddressByIdResponse } = fetchPatientAddressByIdSlice.actions;

export const getPatientAddressByIdSelector = (state: RootState) => state.FetchPatientAddressById;

export default fetchPatientAddressByIdSlice.reducer;
