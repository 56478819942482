import { Modal } from 'flowbite-react';
import { usStates } from '../../../../constants/systemConstants';
import { SelectInput } from '../../../atoms/SelectInput';
import { TextArea } from '../../../atoms/TextArea';
import { CommonButton } from '../../../atoms/CommonButton';
import { useState } from 'react';
import { useAppDispatch } from '../../../../hooks/storeHooks/hooks';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { addClaimApplyNoteRequest } from '../../../../redux/slices/patient/addClaimApplyNoteSlice';
import { getPatientCaseOptionsSelector } from '../../../../redux/slices/patient/getPatientCaseOptionSlice';
import { claimStatus } from '../../../../constants/claimConstants';
import { TableContent } from '../../../table/TableContent';

const columns = [
  {
    title: 'Claim ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Claim Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Select',
    dataIndex: 'select',
    key: 'select',
  },
];

export const ApplyNotes = ({ applyBotesModalOpen, setOpenApplyNotesModal, applyNoteHistoryDataSource }: any) => {
  const dispatch = useAppDispatch();
  const { caseOptionPatientData } = useAppSelector(getPatientCaseOptionsSelector);
  const [formData, setFormData] = useState({});

  const onChangeFields = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    // dispatch(addClaimApplyNoteRequest())
  };

  // console.log('formData', formData);
  return (
    <Modal
      show={applyBotesModalOpen}
      onClose={() => {
        setOpenApplyNotesModal(false);
      }}
    >
      <Modal.Header>Apply Note to Multiple Claims</Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="Status"
            options={claimStatus}
            enableDefaultPlaceholder={true}
            name="status"
            onChange={onChangeFields}
          />
          <SelectInput
            label="Category"
            options={[]}
            enableDefaultPlaceholder={true}
            name="category"
            onChange={onChangeFields}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="Reason"
            options={usStates}
            enableDefaultPlaceholder={true}
            name="reason"
            onChange={onChangeFields}
          />
          <SelectInput
            label="AR-Category"
            options={[]}
            enableDefaultPlaceholder={true}
            name="aRCategory"
            onChange={onChangeFields}
          />
        </div>
        <div className="grid md:grid-cols-1 mt-6">
          <TextArea onChange={onChangeFields} name="note" label="Notes" />
        </div>
        <TableContent enableActions={false} columns={columns} dataSource={applyNoteHistoryDataSource} />
      </Modal.Body>
      <Modal.Footer>
        <CommonButton
          type="button"
          label={'Close'}
          buttonType="secondary"
          onClick={() => {
            setFormData({});
            setOpenApplyNotesModal(false);
          }}
        />
        <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
