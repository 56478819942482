import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchVisitsInitialInterface, FetchVisitsInterface } from '../../../interfaces/billingInterface';
import { getVisitsAPIRequest } from '../../../api/billing/visits';

const initialState: FetchVisitsInitialInterface = {
  visitLoading: false,
  visitData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  visitError: null,
  visitStatus: 'IDLE',
};

export const getVisitRequest: any = createAsyncThunk(
  'visits/getVisitRequest',
  async (visitData: FetchVisitsInterface, thunkAPI: any) => {
    try {
      const response: any = await getVisitsAPIRequest(visitData);
      // console.log(response)
      return response;
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitsSlice = createSlice({
  name: 'fetchVisits',
  initialState,
  reducers: {
    clearFetchVisitsResponse: (state) => {
      state.visitLoading = false;
      state.visitStatus = 'IDLE';
      state.visitData = {
        currentPage: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
      };
      state.visitError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitRequest.pending, (state, action) => {
        state.visitLoading = true;
        state.visitStatus = 'PENDING';
      })
      .addCase(getVisitRequest.fulfilled, (state, action) => {
        state.visitLoading = false;
        state.visitData = action.payload;
        state.visitStatus = 'SUCCESS';
      })
      .addCase(getVisitRequest.rejected, (state, action) => {
        state.visitLoading = false;
        state.visitError = action.payload;
        state.visitStatus = 'FAILED';
      });
  },
});

export const { clearFetchVisitsResponse } = getVisitsSlice.actions;

export const getVisitsSelector = (state: RootState) => state.GetVisits;

export default getVisitsSlice.reducer;
