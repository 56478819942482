import { RightSheetInterface } from '../../interfaces/commonInterfaces';
import { CommonAlert } from '../atoms/Alert';
import { CommonButton } from '../atoms/CommonButton';
import { InputField } from '../atoms/InputField';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { SecondaryButton } from '../atoms/SecondaryButton';
import { SelectInput } from '../atoms/SelectInput';
import { UploadInput } from '../atoms/UploadInput';
import './rightSheet.css';

export const RightSheet: React.FC<RightSheetInterface> = ({
  onClose,
  children,
  title,
  submitButtonTitle,
  cancelButtonTitle,
  alertOnClose,
  onSubmit,
  loading,
  enableAlert = false,
  alertDetails,
  enableCancelButton = true,
  enableSubmitButton = true,
}) => {
  return (
    <>
      <div className="right-sheet rightsheet-sm">
        <div className="right-sheet__header">
          <h5 className="heading">{title}</h5>
          <div className="right-sheet__header-close-btn" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div className="right-sheet__body">
          {enableAlert && (
            <CommonAlert
              icon={alertDetails?.icon}
              message={alertDetails?.message}
              color={alertDetails?.color}
              onClose={alertOnClose}
              divClassName="flex justify-center items-center mt-4"
              alertClassName="w-1/2"
            />
          )}
          {children}
        </div>
        <div className="right-sheet__footer">
          <div className="flex justify-end">
            {enableCancelButton && (
              <div className="mr-2">
                <CommonButton type="reset" label={cancelButtonTitle} buttonType="secondary" onClick={onClose} />
              </div>
            )}
            {enableSubmitButton && (
              <div className="">
                <CommonButton
                  type="button"
                  label={submitButtonTitle}
                  buttonType="primary"
                  onClick={onSubmit}
                  loading={loading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
