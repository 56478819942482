import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchClaimsInitialInterface,
  FetchClaimsInterface,
  FetchVisitsInitialInterface,
  FetchVisitsInterface,
  SendClaimInitialInterface,
  SendClaimsInterface,
} from '../../../interfaces/billingInterface';
import { getVisitsAPIRequest } from '../../../api/billing/visits';
import { getClaimsAPIRequest, sendClaimAPIRequest } from '../../../api/billing/claims';

const initialState: SendClaimInitialInterface = {
  claimSendLoading: false,
  claimSendData: {},
  claimSendError: null,
  claimSendStatus: 'IDLE',
};

export const sendClaimRequest: any = createAsyncThunk(
  'primaryClaims/sendClaim',
  async (claimData: SendClaimsInterface, thunkAPI: any) => {
    try {
      const response: any = await sendClaimAPIRequest(claimData);
      console.log(response);
      return response;
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const sendClaimSlice = createSlice({
  name: 'sendClaim',
  initialState,
  reducers: {
    clearSendClaimResponse: (state) => {
      state.claimSendLoading = false;
      state.claimSendStatus = 'IDLE';
      state.claimSendData = {};
      state.claimSendError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendClaimRequest.pending, (state, action) => {
        state.claimSendLoading = true;
        state.claimSendStatus = 'PENDING';
      })
      .addCase(sendClaimRequest.fulfilled, (state, action) => {
        state.claimSendLoading = false;
        state.claimSendData = action.payload;
        state.claimSendStatus = 'SUCCESS';
      })
      .addCase(sendClaimRequest.rejected, (state, action) => {
        state.claimSendLoading = false;
        state.claimSendError = action.payload;
        state.claimSendStatus = 'FAILED';
      });
  },
});

export const { clearSendClaimResponse } = sendClaimSlice.actions;

export const sendClaimSelector = (state: RootState) => state.SendClaim;

export default sendClaimSlice.reducer;
