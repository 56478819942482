import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { PatientVisitFormPropsInterface } from '../../../../interfaces/patientInterface';
import { getPatientCaseOptionsSelector } from '../../../../redux/slices/patient/getPatientCaseOptionSlice';
import { Calendar } from '../../../atoms/Calendar';
import { SelectInput } from '../../../atoms/SelectInput';
import { TextArea } from '../../../atoms/TextArea';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const VisitForm: React.FC<PatientVisitFormPropsInterface> = ({
  onChange,
  visitFormEditData,
  errorObject,
  onChangeVisitData,
}) => {
  const { caseOptionPatientData } = useAppSelector(getPatientCaseOptionsSelector);

  return (
    <>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        <SelectInput
          label={'Select Case'}
          name="caseId"
          onChange={onChange}
          options={caseOptionPatientData}
          defaultValue={visitFormEditData?.caseId ? visitFormEditData?.caseId.toString() : undefined}
          error={errorObject && errorObject?.caseId}
          errorMessage={errorObject && errorObject?.caseId}
          required={true}
        />
        {/* <Calendar
          onSelectedDateChanged={onChangeVisitData}
          name="visitDate"
          label="Visit Date"
          defaultDate={visitFormEditData?.visitDate ? new Date(visitFormEditData?.visitDate) : undefined}
          required={true}
        /> */}
        <DatePickerField
          label="Visit Date"
          name="visitDate"
          // selectedDate={licenseFormEditData?.effectiveDate ? new Date(licenseFormEditData?.effectiveDate) : null}
          selectedDate={formatDatePickerDate(visitFormEditData?.visitDate)}
          onChange={onChangeVisitData}
        />
      </div>
      <div className="grid md:grid-cols-1 mt-6">
        <TextArea
          onChange={onChange}
          name="noteToBiller"
          label="Notes"
          defaultValue={visitFormEditData?.noteToBiller ?? undefined}
        />
      </div>
    </>
  );
};
