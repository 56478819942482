import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchClaimByIdInitialInterface, SendClaimsInterface } from '../../../interfaces/billingInterface';
import { getClaimByIdAPIRequest, getClaimsAPIRequest } from '../../../api/billing/claims';

const initialState: FetchClaimByIdInitialInterface = {
  claimByIdLoading: false,
  claimByIdData: {},
  claimByIdError: null,
  claimByIdStatus: 'IDLE',
};

export const getClaimByIdRequest: any = createAsyncThunk(
  'get/claimById',
  async (claimData: SendClaimsInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimByIdAPIRequest(claimData);
      // console.log(response);
      return response;
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimByIdSlice = createSlice({
  name: 'fetchClaimById',
  initialState,
  reducers: {
    clearFetchClaimByIdResponse: (state) => {
      state.claimByIdLoading = false;
      state.claimByIdStatus = 'IDLE';
      state.claimByIdData = {};
      state.claimByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimByIdRequest.pending, (state, action) => {
        state.claimByIdLoading = true;
        state.claimByIdStatus = 'PENDING';
      })
      .addCase(getClaimByIdRequest.fulfilled, (state, action) => {
        state.claimByIdLoading = false;
        state.claimByIdData = action.payload;
        state.claimByIdStatus = 'SUCCESS';
      })
      .addCase(getClaimByIdRequest.rejected, (state, action) => {
        state.claimByIdLoading = false;
        state.claimByIdError = action.payload;
        state.claimByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimByIdResponse } = getClaimByIdSlice.actions;

export const getClaimByIdSelector = (state: RootState) => state.GetClaimById;

export default getClaimByIdSlice.reducer;
