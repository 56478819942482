import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  ProviderLicenseEditPropsInterface,
  ProviderLicenseUpdateInterface,
} from "../../../interfaces/providersInterface"; 
import { updateProviderLicenseAPIRequest } from "../../../api/provider/providerLicense";

const initialState: ProviderLicenseEditPropsInterface = {
  editProviderLicenseLoading: false,
  editProviderLicenseData: {},
  editProviderLicenseError: null,
  editProviderLicenseStatus: "IDLE",
};

export const editProviderLicenseRequest: any = createAsyncThunk(
  "provider/license/edit",
  async (patient: ProviderLicenseUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateProviderLicenseAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderLicenseSlice = createSlice({
  name: "editProviderLicense",
  initialState,
  reducers: {
    clearEditProviderLicenseResponse: (state) => {
      state.editProviderLicenseLoading = false;
      state.editProviderLicenseStatus = "IDLE";
      state.editProviderLicenseData = {};
      state.editProviderLicenseError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProviderLicenseRequest.pending, (state, action) => {
        state.editProviderLicenseLoading = true;
        state.editProviderLicenseStatus = "PENDING";
      })
      .addCase(editProviderLicenseRequest.fulfilled, (state, action) => {
        state.editProviderLicenseData = action.payload;
        state.editProviderLicenseStatus = "SUCCESS";
        state.editProviderLicenseLoading = false;
      })
      .addCase(editProviderLicenseRequest.rejected, (state, action) => {
        state.editProviderLicenseLoading = false;
        state.editProviderLicenseError = action.payload;
        state.editProviderLicenseStatus = "FAILED";
      });
  },
});

export const { clearEditProviderLicenseResponse } =
  editProviderLicenseSlice.actions;

export const editProviderLicenseSelector = (state: RootState) =>
  state.EditProviderLicense;

export default editProviderLicenseSlice.reducer;
