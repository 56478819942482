import { PatientCaseOptionsInterface, PatientClaimPrintInterface } from '../../interfaces/patientInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getPatientClaimsAPIRequest = async (patientCase: PatientCaseOptionsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientCase?.patientId}/claim`,
    'get',
    {}
  );
};

export const printClaimAPIRequest = async (patientData: PatientClaimPrintInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientData?.patientId}/claim/${patientData?.claimId}/print`,
    'post',
    {
      data: { ...patientData?.printOptions },
    }
  );
};

export const createClaimsAPIRequest = async (patientData: PatientCaseOptionsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientData?.patientId}/create-claim-list`,
    'post',
    {}
  );
};
