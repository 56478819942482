export const providerType = [
  { id: 'ATTENDING', name: 'Attending' },
  { id: 'REFERRING', name: 'Referring ' },
];

export const providerEmploymentType = [
  { id: 'FULL_TIME', name: 'Full Time' },
  { id: 'PART_TIME', name: 'Part Time ' },
];

export const providerStatus = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
];
