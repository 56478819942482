import { useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { PatientTransactionInterface } from '../../../../interfaces/patientInterface';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
  },
  {
    title: 'Facility',
    dataIndex: 'facilityId',
    key: 'facilityId',
  },
  {
    title: 'Referring Provider',
    dataIndex: 'referringProvider',
    key: 'referringProvider',
  },
  {
    title: 'Insurance',
    dataIndex: 'insurance',
    key: 'insurance',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
];

const dataSource = [
  {
    id: '45239 P',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
  {
    id: '45239 P',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityId: 'The Rivers of Grosse Pointe',
    referringProvider: 'KIMBERLY SNYDER',
    insurance: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
];

const billColumns = [
  {
    title: 'Total Billed',
    dataIndex: 'totalBilled',
    key: 'totalBilled',
  },
  {
    title: 'Total Payment',
    dataIndex: 'totalPayment',
    key: 'totalPayment',
  },
  {
    title: 'Total Adjustments',
    dataIndex: 'totalAdjustments',
    key: 'totalAdjustments',
  },
  {
    title: 'Insurance Balance',
    dataIndex: 'insuranceBalanace',
    key: 'insuranceBalanace',
  },
  {
    title: 'Patient Balance',
    dataIndex: 'patientBalance',
    key: 'patientBalance',
  },
  {
    title: 'Last Statement Sent Date',
    dataIndex: 'lastStatementSentDate',
    key: 'lastStatementSentDate',
  },
  {
    title: 'Total Outstanding Balance',
    dataIndex: 'totalOutStandingBalance',
    key: 'totalOutStandingBalance',
  },
];

const billDataSource = [
  {
    totalBilled: '$682.00',
    totalPayment: '$256.32',
    totalAdjustments: '$171.68',
    insuranceBalanace: '$254.00',
    patientBalance: '$0.00',
    lastStatementSentDate: 'N/A',
    totalOutStandingBalance: '	$254.00',
  },
];

export const Transaction: React.FC<PatientTransactionInterface> = ({ mode }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [claimFormData, setClaimFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
  });

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    // const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    // setMode('EDIT');
    // setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onSubmit = () => {
    // addressFormData['state'] = 'FL';
    // addressFormData['country'] = 'US';
    // let obj = {
    //   userId: selectedId,
    //   addressData: addressFormData,
    // };
    // dispatch(editAddressRequest(obj));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleAddNewModal = () => {
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    setVisibleModal(false);
  };

  const handleClaimFormChange = (e: any) => {
    setClaimFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <>
      {visibleAlert && <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />}
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      <TableContent
        enableActions={mode === 'VIEW' ? false : true}
        columns={columns}
        dataSource={dataSource}
        enableDelete={true}
        enableEdit={true}
        enableView={true}
        onDelete={onDelete}
        onEdit={onEdit}
        onView={onView}
      />

      <TableContent
        enableActions={false}
        columns={billColumns}
        dataSource={billDataSource}
        enableDelete={false}
        enableEdit={false}
        enableView={false}
      />
    </>
  );
};
