import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchDenialReportsInterface,
  FetchDenialReportsPropsInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getDenialReportsAPIRequest } from '../../../../api/payments/denialReports';

const initialState: FetchDenialReportsPropsInterface = {
  fetchDenialReportsLoading: false,
  fetchDenialReportsData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchDenialReportsError: null,
  fetchDenialReportsStatus: 'IDLE',
};

export const getDenialReportsRequest: any = createAsyncThunk(
  'denial-reports/get',
  async (searchParams: FetchDenialReportsInterface, thunkAPI: any) => {
    try {
      const response: any = await getDenialReportsAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getDenialReportsSlice = createSlice({
  name: 'fetchDenialReports',
  initialState,
  reducers: {
    clearDenialReportsResponse: (state) => {
      state.fetchDenialReportsLoading = false;
      state.fetchDenialReportsStatus = 'IDLE';
      state.fetchDenialReportsData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchDenialReportsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDenialReportsRequest.pending, (state, action) => {
        state.fetchDenialReportsLoading = true;
        state.fetchDenialReportsStatus = 'PENDING';
      })
      .addCase(getDenialReportsRequest.fulfilled, (state, action) => {
        state.fetchDenialReportsLoading = false;
        state.fetchDenialReportsStatus = 'SUCCESS';
        state.fetchDenialReportsData = action.payload;
      })
      .addCase(getDenialReportsRequest.rejected, (state, action) => {
        state.fetchDenialReportsLoading = false;
        state.fetchDenialReportsError = action.payload;
        state.fetchDenialReportsStatus = 'FAILED';
      });
  },
});

export const { clearDenialReportsResponse } = getDenialReportsSlice.actions;

export const getDenialReportsSelector = (state: RootState) => state.GetDenialReports;

export default getDenialReportsSlice.reducer;
