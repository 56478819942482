import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { EditPatientCaseInterface, PatientCaseEditPropsInterface } from '../../../interfaces/patientInterface';
import { editPatientCaseAPIRequest } from '../../../api/patient/patientCase';

const initialState: PatientCaseEditPropsInterface = {
  editPatientCaseLoading: false,
  editPatientCaseData: {},
  editPatientCaseError: null,
  editPatientCaseStatus: 'IDLE',
};

export const editPatientCaseRequest: any = createAsyncThunk(
  'patient/case/edit',
  async (patient: EditPatientCaseInterface, thunkAPI: any) => {
    try {
      const response: any = await editPatientCaseAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editPatientCaseSlice = createSlice({
  name: 'editPatientCase',
  initialState,
  reducers: {
    clearEditPatientCaseResponse: (state) => {
      state.editPatientCaseLoading = false;
      state.editPatientCaseStatus = 'IDLE';
      state.editPatientCaseData = {};
      state.editPatientCaseError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPatientCaseRequest.pending, (state, action) => {
        state.editPatientCaseLoading = true;
        state.editPatientCaseStatus = 'PENDING';
      })
      .addCase(editPatientCaseRequest.fulfilled, (state, action) => {
        state.editPatientCaseData = action.payload;
        state.editPatientCaseStatus = 'SUCCESS';
        state.editPatientCaseLoading = false;
      })
      .addCase(editPatientCaseRequest.rejected, (state, action) => {
        state.editPatientCaseLoading = false;
        state.editPatientCaseError = action.payload;
        state.editPatientCaseStatus = 'FAILED';
      });
  },
});

export const { clearEditPatientCaseResponse } = editPatientCaseSlice.actions;

export const editPatientCaseSelector = (state: RootState) => state.EditPatientCase;

export default editPatientCaseSlice.reducer;
