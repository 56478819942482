import { useEffect, useState } from 'react';
import { TableContent } from '../../../table/TableContent';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { PatientsAddressForm } from './PatientsAddressForm';
import {
  addPatientAddressRequest,
  addPatientAddressSelector,
  clearAddPatientAddressResponse,
} from '../../../../redux/slices/patient/addPatientAddressSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { PatientAddInterface, PatientAddressInterface } from '../../../../interfaces/patientInterface';
import { getPatientsByIdSelector } from '../../../../redux/slices/patient/getPatientByIdSlice';
import { Spinner } from '../../../atoms/Spinner';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import {
  deletePatientAddressByIdRequest,
  deletePatientAddressByIdSelector,
} from '../../../../redux/slices/patient/deletePatientAddressSlice';
import {
  clearFetchPatientAddressByIdResponse,
  getPatientAddressByIdRequest,
  getPatientAddressByIdSelector,
} from '../../../../redux/slices/patient/getPatientAddressByIdSlice';
import {
  clearEditPatientAddressResponse,
  editPatientAddressRequest,
  editPatientAddressSelector,
} from '../../../../redux/slices/patient/editPatientAddressSlice';
import { createPatientAddressValidation } from '../../../../utils/patientValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { FaStar } from 'react-icons/fa6';

export const PatientAddress: React.FC<PatientAddressInterface> = ({
  selectedId,
  fetchPatientById,
  mode,
  addDataLoading,
  fetchDataLoading,
}) => {
  const dispatch = useAppDispatch();
  const { addPatientAddressStatus, addPatientAddressLoading } = useAppSelector(addPatientAddressSelector);
  const { fetchPatientByIdData, fetchPatientByIdStatus } = useAppSelector(getPatientsByIdSelector);
  const { deletePatientAddressByIdLoading, deletePatientAddressByIdStatus } = useAppSelector(
    deletePatientAddressByIdSelector
  );
  const { fetchPatientAddressByIdData, fetchPatientAddressByIdStatus } = useAppSelector(getPatientAddressByIdSelector);
  const { editPatientAddressLoading, editPatientAddressStatus } = useAppSelector(editPatientAddressSelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [addressFormData, setAddressFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const [addressMode, setAddressMode] = useState<any>('CREATE');
  const [selectedAddressId, setSelectedAddressId] = useState<number>();
  const [errorObj, setErrorObj] = useState<any>({});

  const columns = [
    {
      title: 'Primary',
      dataIndex: 'primary',
      key: 'primary',
      render: (data: any, row: any) => {
        return (
          <>
            {data ? (
              <FaStar
                color="#FFBF00"
                size={30}
                style={{ borderColor: 'black' }}
                onClick={() => handlePrimaryAddress(row)}
              />
            ) : (
              <FaStar size={20} onClick={() => handlePrimaryAddress(row)} />
            )}
          </>
        );
      },
    },
    {
      title: 'Address',
      dataIndex: 'fullAddress',
      key: 'fullAddress',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
    },
  ];

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      // console.log('=== fetchPatientByIdData ===', fetchPatientByIdData);

      setDataSource(tableDataTransform(fetchPatientByIdData));
      setVisibleModal(false);
    }
  }, [fetchPatientByIdStatus]);

  useEffect(() => {
    setVisibleModal(false);
    setVisibleAlert(false);
  }, []);

  useEffect(() => {
    if (addPatientAddressStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'address');
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setAddressFormData({});
        setErrorObj({});
        setAddressMode('CREATE');
      }, 3000);
      dispatch(clearAddPatientAddressResponse());
    } else if (addPatientAddressStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddPatientAddressResponse());
    }
  }, [addPatientAddressStatus]);

  useEffect(() => {
    if (fetchPatientAddressByIdStatus === 'SUCCESS') {
      let obj = {
        address1: fetchPatientAddressByIdData?.address1,
        address2: fetchPatientAddressByIdData?.address2,
        city: fetchPatientAddressByIdData?.city,
        country: fetchPatientAddressByIdData?.country,
        id: fetchPatientAddressByIdData?.id,
        primary: fetchPatientAddressByIdData?.primary,
        state: fetchPatientAddressByIdData?.state,
        zipCode: fetchPatientAddressByIdData?.zip,
      };
      setAddressFormData(obj);
      dispatch(clearFetchPatientAddressByIdResponse());
      setVisibleModal(true);
    } else if (fetchPatientAddressByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [fetchPatientAddressByIdStatus]);

  useEffect(() => {
    if (deletePatientAddressByIdStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'address');
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    } else if (deletePatientAddressByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [deletePatientAddressByIdStatus]);

  useEffect(() => {
    if (editPatientAddressStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'address');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setErrorObj({});
        setAddressFormData({});
        setAddressMode('CREATE');
      }, 3000);
      dispatch(clearEditPatientAddressResponse());
    } else if (editPatientAddressStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientAddressResponse());
    }
  }, [editPatientAddressStatus]);

  // console.log('selectedId', selectedId);

  const onEdit = (event: any, rowIndex: any) => {
    // console.log('=== test ===');

    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setAddressMode('EDIT');
    setSelectedAddressId(dataSet?.id);
    getAddressDetailsGetById(dataSet?.id);
    // setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const getAddressDetailsGetById = (id: number) => {
    dispatch(getPatientAddressByIdRequest({ patientId: selectedId, addressId: id }));
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedAddressId(dataSet?.id);
    dispatch(
      deletePatientAddressByIdRequest({
        patientId: selectedId,
        addressId: dataSet?.id,
      })
    );
  };

  const onSubmit = () => {
    // console.log('==== addressMode ====', addressMode);

    let objBeforeSubmit = addressFormData;

    if (objBeforeSubmit?.zipCode) {
      objBeforeSubmit.zip = parseInt(addressFormData?.zipCode);
    }

    if (addressMode === 'CREATE') {
      let obj = {
        patientId: selectedId,
        addressData: objBeforeSubmit,
      };

      const validationDetails = createPatientAddressValidation(obj?.addressData);
      // console.log('==== addressMode - validationDetails ====', addressMode, validationDetails);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addPatientAddressRequest(obj));
      }
    } else if (addressMode === 'EDIT') {
      let obj = {
        patientId: selectedId,
        addressData: objBeforeSubmit,
        addressId: selectedAddressId,
      };

      const validationDetails = createPatientAddressValidation(obj?.addressData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(editPatientAddressRequest(obj));
      }
    }
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleAddNewModal = () => {
    setAddressFormData({});
    setAddressMode('CREATE');
    setVisibleModal(true);
    setErrorObj({});
  };

  const onCloseModal = () => {
    setAddressFormData({});
    setAddressMode('CREATE');
    setVisibleModal(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleAddressFormChange = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setAddressFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePrimaryAddress = (row: any) => {
    // console.log('=== primary data ===', row);
    const obj = {
      address1: row?.address1,
      address2: row?.address2,
      city: row?.city,
      state: row?.state,
      zipCode: row?.zip,
      primary: true,
    };
    // console.log('=== primary data - obj ===', obj);
    const reqObj = {
      patientId: selectedId,
      addressData: obj,
      addressId: row?.id,
    };

    dispatch(editPatientAddressRequest(reqObj));
  };

  const tableDataTransform = (data: any) => {
    return [
      ...data?.map((item: any, key: any) => ({
        primary: item?.primary,
        fullAddress: (item?.address1 ?? '') + (item?.address2 ? `, ${item?.address2}` : ''),
        address1: item?.address1 ?? '',
        address2: item?.address2 ?? '',
        state: item?.state,
        zip: item?.zip,
        city: item?.city,
        id: item?.id,
      })),
    ];
  };

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      {fetchDataLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns}
              dataSource={dataSource}
              enableDelete={true}
              enableEdit={true}
              enableView={false}
              onDelete={onDelete}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={mode === 'VIEW' ? false : true} mode={mode} />
          )}
        </>
      )}

      {visibleModal && (
        <RightSheet
          title="Add / Edit Address"
          submitButtonTitle={addressMode === 'CREATE' ? 'Submit' : 'Update'}
          cancelButtonTitle="Cancel"
          children={
            <PatientsAddressForm
              onChange={handleAddressFormChange}
              addressMode={addressMode}
              addressFormEditData={addressFormData}
              errorObject={errorObj}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          loading={addPatientAddressLoading || editPatientAddressLoading}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      )}
    </>
  );
};
