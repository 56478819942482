import { CheckboxGroupPropsInterface } from "../../interfaces/atomInterfaces";
import { Checkbox } from "./Checkbox";

export const CheckboxGroup: React.FC<CheckboxGroupPropsInterface> = ({
  groupLabel,
  checkboxes,
  onChange,
}) => {
  return (
    <div>
      <p className="block mb-2 text-sm font-normal text-gray-900 dark:text-white">
        {groupLabel}
      </p>
      <div className="flex flex-col">
        {checkboxes.map((checkboxProps, index) => (
          <Checkbox key={index} {...checkboxProps} onChange={onChange} />
        ))}
      </div>
    </div>
  );
};
