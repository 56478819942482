import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { SelectInput } from '../../atoms/SelectInput';
import { TextArea } from '../../atoms/TextArea';
import { TableContent } from '../../table/TableContent';
import { CommonButton } from '../../atoms/CommonButton';
import { claimStatus } from '../../../constants/claimConstants';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
  },
];

export const EditStatusModal = ({
  editStatusModalOpen,
  setOpenEditStatusModal,
  editStatusFormData,
  onSubmit,
  onChangeFields,
}: any) => {
  return (
    <Modal
      show={editStatusModalOpen}
      onClose={() => {
        setOpenEditStatusModal(false);
      }}
    >
      <Modal.Header>Apply Note to Multiple Claims</Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="Status"
            options={claimStatus}
            enableDefaultPlaceholder={true}
            name="status"
            onChange={onChangeFields}
            defaultValue={editStatusFormData?.status ?? undefined}
          />
          <SelectInput
            label="Category"
            options={[]}
            enableDefaultPlaceholder={true}
            name="category"
            onChange={onChangeFields}
            defaultValue={editStatusFormData?.category ?? undefined}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="Reason"
            options={[]}
            enableDefaultPlaceholder={true}
            name="reason"
            onChange={onChangeFields}
            defaultValue={editStatusFormData?.reason ?? undefined}
          />
          <SelectInput
            label="AR-Category"
            options={[]}
            enableDefaultPlaceholder={true}
            name="aRCategory"
            onChange={onChangeFields}
            defaultValue={editStatusFormData?.aRCategory ?? undefined}
          />
        </div>
        <div className="grid md:grid-cols-1 mt-6">
          <TextArea
            onChange={onChangeFields}
            name="note"
            label="Notes"
            defaultValue={editStatusFormData?.note ?? undefined}
          />
        </div>
        <TableContent enableActions={false} columns={columns} dataSource={[]} />
      </Modal.Body>
      <Modal.Footer>
        <CommonButton
          type="button"
          label={'Close'}
          buttonType="secondary"
          onClick={() => {
            setOpenEditStatusModal(false);
          }}
        />
        <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={onSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
