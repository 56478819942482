import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserByIdAPIRequest } from '../../../api/user/user';
import { UserFetchByIdInitialInterface, UserFetchInterface } from '../../../interfaces/userInterface';
import { RootState } from '../../store';

const initialState: UserFetchByIdInitialInterface = {
  userByIdLoading: false,
  userByIdUserData: {},
  userByIdError: null,
  userByIdStatus: 'IDLE',
};

export const getUserByIdRequest: any = createAsyncThunk(
  'users/get/id',
  async (user: UserFetchInterface, thunkAPI: any) => {
    try {
      const response: any = await getUserByIdAPIRequest(user);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getUserByIdSlice = createSlice({
  name: 'fetchUserById',
  initialState,
  reducers: {
    clearFetchUserByIdResponse: (state) => {
      state.userByIdLoading = false;
      state.userByIdStatus = 'IDLE';
      state.userByIdUserData = {};
      state.userByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserByIdRequest.pending, (state, action) => {
        state.userByIdLoading = true;
        state.userByIdStatus = 'PENDING';
      })
      .addCase(getUserByIdRequest.fulfilled, (state, action) => {
        state.userByIdLoading = false;
        state.userByIdUserData = action.payload;
        state.userByIdStatus = 'SUCCESS';
      })
      .addCase(getUserByIdRequest.rejected, (state, action) => {
        state.userByIdLoading = false;
        state.userByIdError = action.payload;
        state.userByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchUserByIdResponse } = getUserByIdSlice.actions;

export const getUserByIdSelector = (state: RootState) => state.GetUserById;

export default getUserByIdSlice.reducer;
