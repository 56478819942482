import { accidentType, illnessIndicator, mediPlans, usStates } from '../../../../constants/systemConstants';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { CaseFormPropsInterface } from '../../../../interfaces/patientInterface';
import { getPatientsByIdDemoSelector } from '../../../../redux/slices/patient/getPatientByIdDemoSlice';
import { getSystemFacilitiesSelector } from '../../../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemInsuranceSelector } from '../../../../redux/slices/system/getSystemInsuranceSlice';
import { getSystemProvidersSelector } from '../../../../redux/slices/system/getSystemProvidersSlice';
import { Calendar } from '../../../atoms/Calendar';
import { Checkbox } from '../../../atoms/Checkbox';
import { InputField } from '../../../atoms/InputField';
import { SelectInput } from '../../../atoms/SelectInput';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const CaseForm: React.FC<CaseFormPropsInterface> = ({
  onChange,
  caseFormEditData,
  errorObject,
  onChangeStartDate,
  onChangeEndDate,
  onChangeDateSimilarSymptoms,
  onChangeDateFirstConsultationDate,
  onChangeHospitalizationDateFrom,
  onChangeHospitalizationDateTo,
  onChangeIllnessIndicatorDate,
  patientInsurance,
  handleCaseCheckChange,
}) => {
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const filterByInsuranceType = (patientInsuranceArr = patientInsurance, type = '') => {
    return patientInsuranceArr?.filter((el: any) => {
      if (el?.type === type) {
        return {
          id: el?.insuranceId,
          name: el?.name,
        };
      }
    });
  };

  return (
    <>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <InputField
          label="Description"
          name="description"
          onChange={onChange}
          defaultValue={caseFormEditData?.description ?? undefined}
          error={errorObject && errorObject?.description}
          errorMessage={errorObject && errorObject?.description}
        />
        <SelectInput
          label="Plan"
          options={mediPlans}
          enableDefaultPlaceholder={true}
          name="casePlanType"
          onChange={onChange}
          defaultValue={caseFormEditData?.casePlanType ? caseFormEditData?.casePlanType.toString() : undefined}
          error={errorObject && errorObject?.casePlanType}
          errorMessage={errorObject && errorObject?.casePlanType}
        />
        <Checkbox
          label="Cash Case"
          onChange={handleCaseCheckChange}
          name="cashCase"
          defaultChecked={caseFormEditData?.cashCase}
        />
        <Checkbox
          label="Case Closed"
          onChange={handleCaseCheckChange}
          name="caseClosed"
          defaultChecked={caseFormEditData?.caseClosed}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Facility"
          options={systemFacilityData}
          enableDefaultPlaceholder={true}
          name="facilityId"
          onChange={onChange}
          defaultValue={caseFormEditData?.facilityId ? caseFormEditData?.facilityId.toString() : undefined}
          error={errorObject && errorObject?.facilityId}
          errorMessage={errorObject && errorObject?.facilityId}
          required={true}
        />
        <SelectInput
          label="Referring Provider"
          options={systemProvidersProviderData}
          enableDefaultPlaceholder={true}
          name="providerId"
          onChange={onChange}
          defaultValue={caseFormEditData?.providerId ? caseFormEditData?.providerId.toString() : undefined}
          error={errorObject && errorObject?.providerId}
          errorMessage={errorObject && errorObject?.providerId}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Primary Insurance"
          options={filterByInsuranceType(patientInsurance, 'PRIMARY')}
          enableDefaultPlaceholder={true}
          name="primaryInsuranceId"
          onChange={onChange}
          defaultValue={
            caseFormEditData?.primaryInsuranceId ? caseFormEditData?.primaryInsuranceId.toString() : undefined
          }
          error={errorObject && errorObject?.primaryInsuranceId}
          errorMessage={errorObject && errorObject?.primaryInsuranceId}
        />
        <SelectInput
          label="Secondary Insurance"
          options={filterByInsuranceType(patientInsurance, 'SECONDARY')}
          enableDefaultPlaceholder={true}
          name="secondaryInsuranceId"
          onChange={onChange}
          defaultValue={
            caseFormEditData?.secondaryInsuranceId ? caseFormEditData?.secondaryInsuranceId.toString() : undefined
          }
          error={errorObject && errorObject?.secondaryInsuranceId}
          errorMessage={errorObject && errorObject?.secondaryInsuranceId}
        />
        <SelectInput
          label="Tertiary Insurance"
          options={filterByInsuranceType(patientInsurance, 'TERTIARY')}
          enableDefaultPlaceholder={true}
          name="tertiaryInsurance"
          onChange={onChange}
          defaultValue={
            caseFormEditData?.tertiaryInsurance ? caseFormEditData?.tertiaryInsurance.toString() : undefined
          }
          error={errorObject && errorObject?.tertiaryInsurance}
          errorMessage={errorObject && errorObject?.tertiaryInsurance}
          disabled={false}
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <InputField
          label="Authorization Number"
          name="authorizationNumber"
          onChange={onChange}
          defaultValue={caseFormEditData?.authorizationNumber ?? undefined}
          error={errorObject && errorObject?.authorizationNumber}
          errorMessage={errorObject && errorObject?.authorizationNumber}
        />
        <InputField
          label="Authorization # of Visits"
          name="authorizedNumVisits"
          onChange={onChange}
          defaultValue={caseFormEditData?.authorizedNumVisits ?? undefined}
          error={errorObject && errorObject?.authorizedNumVisits}
          errorMessage={errorObject && errorObject?.authorizedNumVisits}
        />
        {/* <Calendar
          label="Start Date"
          onSelectedDateChanged={onChangeStartDate}
          defaultDate={caseFormEditData?.endDate ? new Date(caseFormEditData?.endDate) : undefined}
        /> */}
        <DatePickerField
          label="Start Date"
          // name="effectiveDate"
          selectedDate={formatDatePickerDate(caseFormEditData?.startDate)}
          onChange={onChangeStartDate}
        />
        {/* <Calendar
          label="End Date"
          onSelectedDateChanged={onChangeEndDate}
          defaultDate={caseFormEditData?.endDate ? new Date(caseFormEditData?.endDate) : undefined}
        /> */}
        <DatePickerField
          label="End Date"
          // name="effectiveDate"
          selectedDate={formatDatePickerDate(caseFormEditData?.endDate)}
          onChange={onChangeEndDate}
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <SelectInput
          label="Illness Indicator"
          options={illnessIndicator}
          enableDefaultPlaceholder={true}
          name="illnessIndicatorType"
          onChange={onChange}
          defaultValue={
            caseFormEditData?.illnessIndicatorType ? caseFormEditData?.illnessIndicatorType.toString() : undefined
          }
          error={errorObject && errorObject?.illnessIndicatorType}
          errorMessage={errorObject && errorObject?.illnessIndicatorType}
        />
        {/* <Calendar
          label="Illness / Injury / LMP Date"
          onSelectedDateChanged={onChangeIllnessIndicatorDate}
          defaultDate={
            caseFormEditData?.illnessIndicatorDate ? new Date(caseFormEditData?.illnessIndicatorDate) : undefined
          }
        /> */}
        <DatePickerField
          label="Illness / Injury / LMP Date"
          selectedDate={formatDatePickerDate(caseFormEditData?.illnessIndicatorDate)}
          onChange={onChangeIllnessIndicatorDate}
        />
        <SelectInput
          label="Accident Type"
          options={accidentType}
          enableDefaultPlaceholder={true}
          name="accidentType"
          onChange={onChange}
          defaultValue={caseFormEditData?.accidentType ? caseFormEditData?.accidentType.toString() : undefined}
          error={errorObject && errorObject?.accidentType}
          errorMessage={errorObject && errorObject?.accidentType}
        />
        <SelectInput
          label="Accident state"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="accidentState"
          onChange={onChange}
          defaultValue={caseFormEditData?.accidentState ? caseFormEditData?.accidentState.toString() : undefined}
          error={errorObject && errorObject?.accidentState}
          errorMessage={errorObject && errorObject?.accidentState}
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        {/* <Calendar
          label="Date Similar Symptoms"
          onSelectedDateChanged={onChangeDateSimilarSymptoms}
          defaultDate={caseFormEditData?.similarSymptoms ? new Date(caseFormEditData?.similarSymptoms) : undefined}
        /> */}
        <DatePickerField
          label="Date Similar Symptoms"
          selectedDate={formatDatePickerDate(caseFormEditData?.dateSimilarSymptoms)}
          onChange={onChangeDateSimilarSymptoms}
        />
        {/* <Calendar
          label="First Consultation Date"
          onSelectedDateChanged={onChangeDateFirstConsultationDate}
          defaultDate={
            caseFormEditData?.firstConsultationDate ? new Date(caseFormEditData?.firstConsultationDate) : undefined
          }
        /> */}
        <DatePickerField
          label="First Consultation Date"
          selectedDate={formatDatePickerDate(caseFormEditData?.firstConsultationDate)}
          onChange={onChangeDateFirstConsultationDate}
        />
        {/* <Calendar
          label="Hospitalization Date From"
          onSelectedDateChanged={onChangeHospitalizationDateFrom}
          defaultDate={
            caseFormEditData?.hospitalizationDateFrom ? new Date(caseFormEditData?.hospitalizationDateFrom) : undefined
          }
        /> */}
        <DatePickerField
          label="Hospitalization Date From"
          selectedDate={formatDatePickerDate(caseFormEditData?.hospitalizationDateFrom)}
          onChange={onChangeHospitalizationDateFrom}
        />
        {/* <Calendar
          label="Hospitalization Date To"
          onSelectedDateChanged={onChangeHospitalizationDateTo}
          defaultDate={
            caseFormEditData?.hospitalizationDateTo ? new Date(caseFormEditData?.hospitalizationDateTo) : undefined
          }
        /> */}
        <DatePickerField
          label="Hospitalization Date To"
          selectedDate={formatDatePickerDate(caseFormEditData?.hospitalizationDateTo)}
          onChange={onChangeHospitalizationDateTo}
        />
      </div>
    </>
  );
};
