import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PatientVisitEIByIdPropsInterface, UpdatePatientVisitEIById } from '../../../interfaces/patientInterface';
import { updatePatientVisitEIByIdAPIRequest } from '../../../api/patient/patientVisit';

const initialState: PatientVisitEIByIdPropsInterface = {
  updatePatientVisitEIByIdLoading: false,
  updatePatientVisitEIByIdData: {},
  updatePatientVisitEIByIdError: null,
  updatePatientVisitEIByIdStatus: 'IDLE',
};

export const updatePatientVisitEIByIdRequest: any = createAsyncThunk(
  'patient/visit/eligibilty/update',
  async (patient: UpdatePatientVisitEIById, thunkAPI: any) => {
    try {
      const response: any = await updatePatientVisitEIByIdAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updatePatientVisitEIByIdSlice = createSlice({
  name: 'updatePatientVisitEIById',
  initialState,
  reducers: {
    clearUpdatePatientVisitEIByIdResponse: (state) => {
      state.updatePatientVisitEIByIdLoading = false;
      state.updatePatientVisitEIByIdStatus = 'IDLE';
      state.updatePatientVisitEIByIdData = {};
      state.updatePatientVisitEIByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePatientVisitEIByIdRequest.pending, (state, action) => {
        state.updatePatientVisitEIByIdLoading = true;
        state.updatePatientVisitEIByIdStatus = 'PENDING';
      })
      .addCase(updatePatientVisitEIByIdRequest.fulfilled, (state, action) => {
        state.updatePatientVisitEIByIdData = action.payload;
        state.updatePatientVisitEIByIdStatus = 'SUCCESS';
        state.updatePatientVisitEIByIdLoading = false;
      })
      .addCase(updatePatientVisitEIByIdRequest.rejected, (state, action) => {
        console.log('=== error ===', action);

        state.updatePatientVisitEIByIdLoading = false;
        state.updatePatientVisitEIByIdError = action.payload;
        state.updatePatientVisitEIByIdStatus = 'FAILED';
      });
  },
});

export const { clearUpdatePatientVisitEIByIdResponse } = updatePatientVisitEIByIdSlice.actions;

export const updatePatientVisitEIByIdSelector = (state: RootState) => state.UpdatePatientVisitEIById;

export default updatePatientVisitEIByIdSlice.reducer;
