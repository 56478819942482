import './tableSearch.css';

export const TableSearch = () => {
  return (
    <>
      <div className="table-content__search">
        <form>
          <label htmlFor="search" className="table-content__search-label">
            Search
          </label>
          <div className="main-content-header__global-search-content">
            <div className="table-content__search-icon">
              <svg
                className="w-4 h-4 text-gray5 dark:text-gray5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input type="search" id="search" className="table-content__search-input" placeholder="Search" required />
            <button type="submit" className="table-content__search-btn">
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
