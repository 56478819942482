import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addPatientAPIRequest } from '../../../api/patient/patient';
import {
  AddPatientInitialInterface,
  PatientAddInterface,
  PatientAddressAddInterface,
  PatientAddressAddPropsInterface,
} from '../../../interfaces/patientInterface';
import { addPatientAddressAPIRequest } from '../../../api/patient/patientAddress';

const initialState: PatientAddressAddPropsInterface = {
  addPatientAddressLoading: false,
  addPatientAddressData: {},
  addPatientAddressError: null,
  addPatientAddressStatus: 'IDLE',
};

export const addPatientAddressRequest: any = createAsyncThunk(
  'patient/address/add',
  async (patient: PatientAddressAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addPatientAddressAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPatientAddressSlice = createSlice({
  name: 'addPatientAddress',
  initialState,
  reducers: {
    clearAddPatientAddressResponse: (state) => {
      state.addPatientAddressLoading = false;
      state.addPatientAddressStatus = 'IDLE';
      state.addPatientAddressData = {};
      state.addPatientAddressError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPatientAddressRequest.pending, (state, action) => {
        state.addPatientAddressLoading = true;
        state.addPatientAddressStatus = 'PENDING';
      })
      .addCase(addPatientAddressRequest.fulfilled, (state, action) => {
        state.addPatientAddressData = action.payload;
        state.addPatientAddressStatus = 'SUCCESS';
        state.addPatientAddressLoading = false;
      })
      .addCase(addPatientAddressRequest.rejected, (state, action) => {
        state.addPatientAddressLoading = false;
        state.addPatientAddressError = action.payload;
        state.addPatientAddressStatus = 'FAILED';
      });
  },
});

export const { clearAddPatientAddressResponse } = addPatientAddressSlice.actions;

export const addPatientAddressSelector = (state: RootState) => state.AddPatientAddress;

export default addPatientAddressSlice.reducer;
