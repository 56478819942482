import Axios from 'axios';
import { authenticatedRequest } from '../../utils/commonAxios';
import {
  EditAddressInterface,
  FetchAddressByIDInterface,
  FetchAddressListPropsInterface,
} from '../../interfaces/userInterface';

export const getAddressListAPIRequest = async (searchParams: FetchAddressListPropsInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/user/${searchParams?.userId}/address`, 'get', {
    data: {},
  });
};

export const getAddressByIdAPIRequest = async (address: FetchAddressByIDInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/user/${address?.userId}/address/${address.addressId}`,
    'get',
    {
      data: {},
    }
  );
};

export const addAddressAPIRequest = async (address: EditAddressInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/user/${address?.userId}/address`, 'post', {
    data: { ...address?.addressData },
  });
};
