import { useEffect, useState } from 'react';
import {
  illnessIndicatior,
  maritalStatus,
  patientClaimEmploymentStatus,
  patientEmploymentStatus,
  patientRelationship,
} from '../../../constants/patientConstants';
import { basicCondition, usStates } from '../../../constants/systemConstants';
import { sexStatus } from '../../../constants/userConstants';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { ClaimFormPropsInterface } from '../../../interfaces/patientInterface';
import { getPatientInsuranceByTypeSelector } from '../../../redux/slices/patient/getPatientInsuranceByTypeSlice';
import { getSystemInsuranceSelector } from '../../../redux/slices/system/getSystemInsuranceSlice';
import { Calendar } from '../../atoms/Calendar';
import { Checkbox } from '../../atoms/Checkbox';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { DatePickerField } from '../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../utils/commonFunctions';
import { TableContent } from '../../table/TableContent';
import { ClaimCPTSearch } from './ClaimCPTSearch';
import { ClaimICDCodeSearchModal } from './ClaimICDCodeSearchModal';
import { ClaimEditCPTModal } from './ClaimEditCPTModal';
import { getSystemFacilitiesSelector } from '../../../redux/slices/system/getSystemFacilitiesSlice';
import {
  clearFetchVisitCPTByIdResponse,
  getVisitCPTByIdRequest,
  getVisitCPTByIdSelector,
} from '../../../redux/slices/billing/getVisitCPTByIdSlice';
import moment from 'moment';
import { validDateFormat } from '../../../utils/commonFunctions';
import { ConfirmModal } from '../../list/patients/visit/ConfirmModal';
import {
  clearDeleteVisitICDCodeResponse,
  deleteVisitCPTCodeSelector,
  deleteVisitICDCodeRequest,
} from '../../../redux/slices/patient/deleteVisitICDCodeSlice';
import {
  addVisitICDCodeRequest,
  addVisitICDCodeSelector,
  clearAddVisitICDCodeResponse,
} from '../../../redux/slices/patient/addVisitICDCodeSlice';

const icdArr = [
  {
    id: 1,
    name: 'A',
  },
  {
    id: 1,
    name: 'B',
  },
  {
    id: 1,
    name: 'C',
  },
  {
    id: 1,
    name: 'D',
  },
  {
    id: 1,
    name: 'E',
  },
  {
    id: 1,
    name: 'F',
  },
  {
    id: 1,
    name: 'G',
  },
  {
    id: 1,
    name: 'H',
  },
];

const columns = [
  {
    title: 'A. Date(s) of service',
    dataIndex: 'dateOfService',
    key: 'dateOfService',
    render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'B. POS',
    dataIndex: 'bpos',
    key: 'bpos',
  },
  {
    title: 'C. EMG',
    dataIndex: 'cemg',
    key: 'cemg',
  },
  {
    title: 'D. Procedure',
    dataIndex: 'dprocedure',
    key: 'dprocedure',
  },
  {
    title: 'Modifier',
    dataIndex: 'modifier',
    key: 'modifier',
    render: (text: string, record: any) => {
      return (
        <ul>
          {record?.modifier?.map((item: any, key: any) => {
            return (
              <li>
                Mod {key + 1} : {item}
              </li>
            );
          })}
        </ul>
      );
    },
  },
  {
    title: 'E. Diag Ref',
    dataIndex: 'ediagRef',
    key: 'ediagRef',
  },
  {
    title: 'F. Charges',
    dataIndex: 'fcharges',
    key: 'fcharges',
  },
  {
    title: 'G. Units',
    dataIndex: 'units',
    key: 'units',
  },
];

const sampleArr = [
  {
    id: 59,
    dateOfService: '2009-09-09 00:00:00.0',
    modifier: [null, null, null, null],
    units: 1,
    bpos: '31',
    cemg: '',
    dprocedure: '99304',
    ediagRef: '',
    fcharges: 147.0,
  },
];

export const ClaimForm: React.FC<ClaimFormPropsInterface> = ({
  onChange,
  claimFormEditData,
  mode,
  errorObject,
  onChangeDOB,
  onChangeDate,
  payerData,
  handlePayerFields,
  handleOtherPayerFields,
  otherPayerData,
  visitId,
  icdDataSource,
  cptDataSource,
  icdCodesDataSource,
  getICDCodeList,
  cptCodesDataSource,
  modifiersDataSource,
  fetchClaimById,
}) => {
  const dispatch = useAppDispatch();
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { fetchPatientInsuranceByTypeData } = useAppSelector(getPatientInsuranceByTypeSelector);
  const { visitCPTData, visitCPTStatus } = useAppSelector(getVisitCPTByIdSelector);
  const { deleteVisitICDCodeStatus } = useAppSelector(deleteVisitCPTCodeSelector);
  const { addVisitICDCodeStatus, addVisitICDCodeError } = useAppSelector(addVisitICDCodeSelector);

  // console.log('=== fetchPatientInsuranceByTypeData ===', fetchPatientInsuranceByTypeData);
  // console.log('=== payerData ===', payerData);

  const [visibleCPTModal, setVisibleCPTModal] = useState(false);
  const [selectedCPT, setSelectedCPT] = useState('');

  const [visibleICDModal, setVisibleICDModal] = useState(false);
  const [selectedICD, setSelectedICD] = useState('');

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [modeChild, setModeChild] = useState<any>('CREATE');
  const [editData, setEditData] = useState<any>({});
  const [icdCodesArr, setICDCodesArr] = useState<any>([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedICDIndex, setSelectedICDIndex] = useState<any>();

  useEffect(() => {
    if (visitCPTStatus === 'SUCCESS') {
      // console.log('=== visitCPTData ===', visitCPTData);
      handleICDCodes(visitCPTData?.icdCodes);
      setEditData(visitCPTData);
      // setICDCodesArr();
      setVisibleCPTModal(true);
    } else if (visitCPTStatus === 'FAILED') {
      setAlertObj({
        color: 'error',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitCPTByIdResponse());
  }, [visitCPTStatus]);

  useEffect(() => {
    if (deleteVisitICDCodeStatus === 'SUCCESS') {
      setSelectedICDIndex('');
      setAlertObj({
        color: 'success',
        message: 'Deleted Successfully!',
        error: false,
      });
      setVisibleAlert(true);
      fetchClaimById && fetchClaimById(claimFormEditData?.claimId);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
        setOpenConfirm(false);
      }, 3000);
    } else if (deleteVisitICDCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearDeleteVisitICDCodeResponse());
  }, [deleteVisitICDCodeStatus]);

  useEffect(() => {
    if (addVisitICDCodeStatus === 'SUCCESS') {
      // console.log('===       selectedICDIndex ===', selectedICDIndex);

      if (selectedICDIndex) {
        replaceICD(selectedICDIndex);
      } else {
        setSelectedICDIndex('');
        fetchClaimById && fetchClaimById(claimFormEditData?.claimId);
      }
      setAlertObj({
        color: 'success',
        message: 'Successfully Added!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    } else if (addVisitICDCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addVisitICDCodeError?.message,
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearAddVisitICDCodeResponse());
  }, [addVisitICDCodeStatus]);

  const handleICDCodes = (arr: any) => {
    // console.log('=== arr ==', arr);
    let newArr: any = [];
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      let obj: any = {
        code: element?.code,
        id: element?.id,
        checked: true,
        value: element?.value,
      };
      newArr[newArr.length] = obj;
    }
    // console.log('=== final arr ==', newArr);
    setICDCodesArr(newArr);
  };

  const onCloseCPTModal = (close: any) => {
    setVisibleCPTModal(close);
  };

  const onCloseICDModal = (close: any) => {
    setVisibleICDModal(close);
  };

  const handleAddedCPTRow = (data: any) => {};

  const handleAddedICDRow = (data: any) => {
    // console.log('=== added ===', data);
    // setSelectedICD((prev: any) => ({ ...prev, [selectedICD]: data?.code }));
    dispatch(addVisitICDCodeRequest({ visitId: visitId, visitData: { code: data?.code } }));
  };

  const getCPTById = async (cptId: number) => {
    dispatch(getVisitCPTByIdRequest({ visitId, cptId }));
  };

  const onEditCPT = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = sampleArr[rowIndex];
    getCPTById(dataSet?.id);
  };

  const generateAlphabet = (key: number) => {
    return [...Array(26)].reduce((acc, _, i) => {
      if (i === key) {
        return String.fromCharCode(i + 65); // ASCII value of 'A' is 65
      }
      return acc; // Continue with the previous accumulated value
    }, ''); // Initialize with an empty string
  };

  const deleteICD = (rowIndex: any) => {
    const dataSet = claimFormEditData?.visitICDItemList[rowIndex];
    setSelectedICD(dataSet?.id);
    setOpenConfirm(true);
  };

  const confirmDelete = () => {
    dispatch(deleteVisitICDCodeRequest({ visitId: visitId, icd: selectedICD }));
  };

  const replaceICD = (id: any) => {
    dispatch(deleteVisitICDCodeRequest({ visitId: visitId, icd: id }));
  };

  const onCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handlePayerDropdown = (data: any) => {
    const finalArr = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const obj = {
        id: element?.payerId,
        name: element?.name,
      };
      finalArr[finalArr.length] = obj;
    }
    return finalArr;
  };

  return (
    <>
      {/* Payer */}
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <SelectInput
          label="Payer Name"
          name="payerName"
          options={fetchPatientInsuranceByTypeData ? handlePayerDropdown(fetchPatientInsuranceByTypeData) : []}
          enableDefaultPlaceholder={true}
          onChange={handlePayerFields}
          defaultValue={payerData.payerId ?? undefined}
          error={errorObject && errorObject?.payerName}
          errorMessage={errorObject && errorObject?.payerName}
        />
        <InputField
          label="Payer Id"
          name="payerId"
          onChange={handlePayerFields}
          // defaultValue={payerData.payerId ?? undefined}
          value={payerData.payerId ?? ''}
          error={errorObject && errorObject?.payerId}
          errorMessage={errorObject && errorObject?.payerId}
          disabled={true}
        />
        <InputField
          label="Insured I.D Number"
          name="insuredIdNumber"
          onChange={handlePayerFields}
          // defaultValue={claimFormEditData?.insuredIdNumber ?? ''}
          value={payerData?.insuredIdNumber ?? ''}
          error={errorObject && errorObject?.insuredIdNumber}
          errorMessage={errorObject && errorObject?.insuredIdNumber}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <Checkbox
          label="isShowPayerAddress"
          onChange={onChange}
          name="showPayerAddress"
          defaultChecked={claimFormEditData?.showPayerAddress}
        />
      </div>
      {claimFormEditData?.showPayerAddress && (
        <>
          <div className="grid md:grid-cols-3 gap-4 mt-6">
            <InputField
              label="Payer Address 1"
              name="payerAddress1"
              onChange={handlePayerFields}
              // defaultValue={claimFormEditData?.payerAddress1 ?? undefined}
              value={payerData?.payerAddress1 ?? ''}
              error={errorObject && errorObject?.payerAddress1}
              errorMessage={errorObject && errorObject?.payerAddress1}
            />
            <InputField
              label="Payer Address 2"
              name="payerAddress2"
              onChange={handlePayerFields}
              // defaultValue={claimFormEditData?.payerAddress2 ?? undefined}
              value={payerData?.payerAddress2 ?? ''}
            />
            <InputField
              label="City"
              name="payerCity"
              onChange={handlePayerFields}
              // defaultValue={claimFormEditData?.payerCity ?? undefined}
              value={payerData?.payerCity ?? ''}
              error={errorObject && errorObject?.payerCity}
              errorMessage={errorObject && errorObject?.payerCity}
            />
          </div>
          <div className="grid md:grid-cols-3 gap-4 mt-6">
            <SelectInput
              label="State"
              options={usStates}
              enableDefaultPlaceholder={true}
              name="payerState"
              onChange={handlePayerFields}
              // defaultValue={claimFormEditData?.payerState ?? undefined}
              value={payerData?.payerState ?? ''}
              error={errorObject && errorObject?.payerState}
              errorMessage={errorObject && errorObject?.payerState}
            />
            <InputField
              label="Zip Code"
              name="payerZip"
              onChange={handlePayerFields}
              // defaultValue={claimFormEditData?.payerZip ?? undefined}
              value={payerData?.payerZip ?? ''}
              error={errorObject && errorObject?.payerZip}
              errorMessage={errorObject && errorObject?.payerZip}
            />
            <InputField
              label="Payer Office Number"
              name="payerOfficeNumber"
              onChange={handlePayerFields}
              value={payerData?.payerOfficeNumber ?? ''}
              // defaultValue={claimFormEditData?.payerOfficeNumber ?? undefined}
              error={errorObject && errorObject?.payerOfficeNumber}
              errorMessage={errorObject && errorObject?.payerOfficeNumber}
            />
          </div>
        </>
      )}
      <hr />

      {/* Patient */}
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <InputField
          label="Patient's First Name"
          name="patientFName"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientFName ?? undefined}
          error={errorObject && errorObject?.patientFName}
          errorMessage={errorObject && errorObject?.patientFName}
        />
        <InputField
          label="Patient's Middle Name"
          name="patientMName"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientMName ?? undefined}
          error={errorObject && errorObject?.patientMName}
          errorMessage={errorObject && errorObject?.patientMName}
        />
        <InputField
          label="Patient's Last Name"
          name="patientLName"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientLName ?? undefined}
          error={errorObject && errorObject?.patientLName}
          errorMessage={errorObject && errorObject?.patientLName}
        />
        {/* <Calendar
          label="Patient's Date of Birth"
          onSelectedDateChanged={(date) => onChangeDate('patientDob', date)}
          defaultDate={claimFormEditData?.patientDob ? new Date(claimFormEditData?.patientDob) : undefined}
        /> */}
        <DatePickerField
          label="Patient's Date of Birth"
          selectedDate={formatDatePickerDate(claimFormEditData?.patientDob)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate('patientDob', date);
            }
          }}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <SelectInput
          label="Gender"
          name="patientSex"
          onChange={onChange}
          options={sexStatus}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={
            claimFormEditData && claimFormEditData?.patientSex ? claimFormEditData?.patientSex.toString() : undefined
          }
          error={errorObject && errorObject?.patientSex}
          errorMessage={errorObject && errorObject?.patientSex}
        />
        <InputField
          label="Patient's Address 1"
          name="patientAddress1"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientAddress1 ?? undefined}
          error={errorObject && errorObject?.patientAddress1}
          errorMessage={errorObject && errorObject?.patientAddress1}
        />
        <InputField
          label="Patient's Address 2"
          name="patientAddress2"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientAddress2 ?? undefined}
        />
        <InputField
          label="City"
          name="patientCity"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientCity ?? undefined}
          error={errorObject && errorObject?.patientCity}
          errorMessage={errorObject && errorObject?.patientCity}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Patient's State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="patientState"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientState ?? undefined}
          error={errorObject && errorObject?.patientState}
          errorMessage={errorObject && errorObject?.patientState}
        />
        <InputField
          label="Patient's Zip Code"
          name="patientZip"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientZip ?? undefined}
          error={errorObject && errorObject?.patientZip}
          errorMessage={errorObject && errorObject?.patientZip}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Patient Relationship"
          options={patientRelationship}
          enableDefaultPlaceholder={true}
          name="patientRelationship"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientRelationship ?? undefined}
          error={errorObject && errorObject?.patientRelationship}
          errorMessage={errorObject && errorObject?.patientRelationship}
        />
        <SelectInput
          label="Marital Status"
          options={maritalStatus}
          enableDefaultPlaceholder={true}
          name="patientMaritalStatus"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientMaritalStatus ?? undefined}
          error={errorObject && errorObject?.patientMaritalStatus}
          errorMessage={errorObject && errorObject?.patientMaritalStatus}
        />

        <SelectInput
          label="Employment Status"
          options={patientClaimEmploymentStatus}
          enableDefaultPlaceholder={true}
          name="patientEmploymentStatus"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientEmploymentStatus ?? undefined}
          error={errorObject && errorObject?.patientEmploymentStatus}
          errorMessage={errorObject && errorObject?.patientEmploymentStatus}
        />
      </div>
      <hr />

      {/* Patient Condition related */}
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <SelectInput
          label="Employment"
          options={basicCondition}
          enableDefaultPlaceholder={true}
          name="patientEmployment"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientEmployment ?? undefined}
          error={errorObject && errorObject?.patientEmployment}
          errorMessage={errorObject && errorObject?.patientEmployment}
        />
        <SelectInput
          label="Auto Accident"
          options={basicCondition}
          enableDefaultPlaceholder={true}
          name="patientAutoAccident"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientAutoAccident ?? undefined}
          error={errorObject && errorObject?.patientAutoAccident}
          errorMessage={errorObject && errorObject?.patientAutoAccident}
        />
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="patientAccidentState"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientAccidentState ?? undefined}
          error={errorObject && errorObject?.patientAccidentState}
          errorMessage={errorObject && errorObject?.patientAccidentState}
        />
        <SelectInput
          label="Other Accident"
          options={basicCondition}
          enableDefaultPlaceholder={true}
          name="patientOtherAccident"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientOtherAccident ?? undefined}
          error={errorObject && errorObject?.patientOtherAccident}
          errorMessage={errorObject && errorObject?.patientOtherAccident}
        />
      </div>
      <hr />

      {/* Insured */}
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Insured's First Name"
          name="insuredFName"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredFName ?? undefined}
          error={errorObject && errorObject?.insuredFName}
          errorMessage={errorObject && errorObject?.insuredFName}
        />
        <InputField
          label="Insured's Middle Name"
          name="insuredMName"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredMName ?? undefined}
          error={errorObject && errorObject?.insuredMName}
          errorMessage={errorObject && errorObject?.insuredMName}
        />
        <InputField
          label="Insured's Last Name"
          name="insuredLName"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredLName ?? undefined}
          error={errorObject && errorObject?.insuredLName}
          errorMessage={errorObject && errorObject?.insuredLName}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Insured Address 1"
          name="insuredAddress1"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredAddress1 ?? undefined}
          error={errorObject && errorObject?.insuredAddress1}
          errorMessage={errorObject && errorObject?.insuredAddress1}
        />
        <InputField
          label="Insured Address 2"
          name="insuredAddress2"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredAddress2 ?? undefined}
        />
        <InputField
          label="City"
          name="insuredCity"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredCity ?? undefined}
          error={errorObject && errorObject?.insuredCity}
          errorMessage={errorObject && errorObject?.insuredCity}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Insured State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="insuredState"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredState ?? undefined}
          error={errorObject && errorObject?.insuredState}
          errorMessage={errorObject && errorObject?.insuredState}
        />
        <InputField
          label="Insured Zip Code"
          name="insuredZip"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredZip ?? undefined}
          error={errorObject && errorObject?.insuredZip}
          errorMessage={errorObject && errorObject?.insuredZip}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Insured's Policy Group"
          name="insuredPolicyGroup"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredPolicyGroup ?? undefined}
          error={errorObject && errorObject?.insuredPolicyGroup}
          errorMessage={errorObject && errorObject?.insuredPolicyGroup}
        />
        {/* <Calendar
          label="Insured Date of Birth"
          onSelectedDateChanged={(date) => onChangeDate('insuredDob', date)}
          defaultDate={claimFormEditData?.insuredDob ? new Date(claimFormEditData?.insuredDob) : undefined}
        /> */}
        <DatePickerField
          label="Insured Date of Birth"
          selectedDate={formatDatePickerDate(claimFormEditData?.insuredDob)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate('insuredDob', date);
            }
          }}
        />
        <SelectInput
          label="Gender"
          name="insuredSex"
          onChange={onChange}
          options={sexStatus}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={
            claimFormEditData && claimFormEditData?.insuredSex ? claimFormEditData?.insuredSex.toString() : undefined
          }
          error={errorObject && errorObject?.insuredSex}
          errorMessage={errorObject && errorObject?.insuredSex}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Employer Name"
          name="insuredEmployerName"
          onChange={onChange}
          defaultValue={claimFormEditData?.insuredEmployerName ?? undefined}
          error={errorObject && errorObject?.insuredEmployerName}
          errorMessage={errorObject && errorObject?.insuredEmployerName}
        />
        <InputField
          label="Insurance Plan Name"
          name="insurancePlanName"
          onChange={onChange}
          defaultValue={claimFormEditData?.insurancePlanName ?? undefined}
          error={errorObject && errorObject?.insurancePlanName}
          errorMessage={errorObject && errorObject?.insurancePlanName}
        />
      </div>
      <hr />

      {/* Other */}
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Other Insured First Name"
          name="otherInsuredFName"
          onChange={onChange}
          defaultValue={claimFormEditData?.otherInsuredFName ?? undefined}
          error={errorObject && errorObject?.otherInsuredFName}
          errorMessage={errorObject && errorObject?.otherInsuredFName}
        />
        <InputField
          label="Other Insured Middle Name"
          name="otherInsuredMName"
          onChange={onChange}
          defaultValue={claimFormEditData?.otherInsuredMName ?? undefined}
          error={errorObject && errorObject?.otherInsuredMName}
          errorMessage={errorObject && errorObject?.otherInsuredMName}
        />
        <InputField
          label="Other Insured Last Name"
          name="otherInsuredLName"
          onChange={onChange}
          defaultValue={claimFormEditData?.otherInsuredLName ?? undefined}
          error={errorObject && errorObject?.otherInsuredLName}
          errorMessage={errorObject && errorObject?.otherInsuredLName}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Other Insured's Policy"
          name="otherInsuredPolicy"
          onChange={onChange}
          defaultValue={claimFormEditData?.otherInsuredPolicy ?? undefined}
          error={errorObject && errorObject?.otherInsuredPolicy}
          errorMessage={errorObject && errorObject?.otherInsuredPolicy}
        />
        {/* <Calendar
          label="Other Insured Date of Birth"
          onSelectedDateChanged={(date) => onChangeDate('otherInsuredDob', date)}
          defaultDate={claimFormEditData?.otherInsuredDob ? new Date(claimFormEditData?.otherInsuredDob) : undefined}
        /> */}
        <DatePickerField
          label="Other Insured Date of Birth"
          selectedDate={formatDatePickerDate(claimFormEditData?.otherInsuredDob)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate('otherInsuredDob', date);
            }
          }}
        />
        <SelectInput
          label="Gender"
          name="otherInsuredSex"
          onChange={onChange}
          options={sexStatus}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={
            claimFormEditData && claimFormEditData?.otherInsuredSex
              ? claimFormEditData?.otherInsuredSex.toString()
              : undefined
          }
          error={errorObject && errorObject?.otherInsuredSex}
          errorMessage={errorObject && errorObject?.otherInsuredSex}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        {/* <Calendar
          label="Primary Payment Date"
          onSelectedDateChanged={(date) => onChangeDate('primaryPaymentDate', date)}
          defaultDate={
            claimFormEditData?.primaryPaymentDate ? new Date(claimFormEditData?.primaryPaymentDate) : undefined
          }
        /> */}
        <DatePickerField
          label="Primary Payment Date"
          selectedDate={formatDatePickerDate(claimFormEditData?.primaryPaymentDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate('primaryPaymentDate', date);
            }
          }}
        />
        <SelectInput
          label="Other Payer Name"
          name="otherPayerName"
          options={fetchPatientInsuranceByTypeData ? handlePayerDropdown(fetchPatientInsuranceByTypeData) : []}
          enableDefaultPlaceholder={true}
          onChange={handleOtherPayerFields}
          // defaultValue={claimFormEditData?.otherPayer?.payerId ?? undefined}
          value={otherPayerData?.otherPayerId ?? ''}
          error={errorObject && errorObject?.otherPayerName}
          errorMessage={errorObject && errorObject?.otherPayerName}
        />
        <InputField
          label="Other Payer ID"
          name="otherPayerId"
          onChange={handleOtherPayerFields}
          disabled={true}
          // defaultValue={claimFormEditData?.otherPayer?.payerId ?? undefined}
          value={otherPayerData?.otherPayerId ?? ''}
          error={errorObject && errorObject?.otherPayerId}
          errorMessage={errorObject && errorObject?.otherPayerId}
        />
        <InputField
          label="Other Payer Claim ID"
          name="otherPayerClaimId"
          onChange={handleOtherPayerFields}
          // defaultValue={claimFormEditData?.otherPayerClaimId ?? undefined}
          value={otherPayerData?.otherPayerClaimId ?? ''}
          error={errorObject && errorObject?.otherPayerClaimId}
          errorMessage={errorObject && errorObject?.otherPayerClaimId}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Other Patient Relationship"
          options={patientRelationship}
          enableDefaultPlaceholder={true}
          name="otherPatientRelationship"
          onChange={onChange}
          defaultValue={claimFormEditData?.otherPatientRelationship ?? undefined}
          error={errorObject && errorObject?.otherPatientRelationship}
          errorMessage={errorObject && errorObject?.otherPatientRelationship}
        />
        <InputField
          label="Other Payer Group Name"
          name="otherPayerGroupName"
          onChange={onChange}
          defaultValue={claimFormEditData?.otherPayerGroupName ?? undefined}
          error={errorObject && errorObject?.otherPayerGroupName}
          errorMessage={errorObject && errorObject?.otherPayerGroupName}
        />
        <InputField
          label="Other Payer Group Number"
          name="otherPayerGroupNumber"
          onChange={onChange}
          defaultValue={claimFormEditData?.otherPayerGroupNumber ?? undefined}
          error={errorObject && errorObject?.otherPayerGroupNumber}
          errorMessage={errorObject && errorObject?.otherPayerGroupNumber}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Other Payer Address 1"
          name="otherPayerAddress1"
          onChange={handleOtherPayerFields}
          // defaultValue={claimFormEditData?.otherPayerAddress1 ?? undefined}
          value={otherPayerData?.otherPayerAddress1 ?? ''}
          error={errorObject && errorObject?.otherPayerAddress1}
          errorMessage={errorObject && errorObject?.otherPayerAddress1}
        />
        <InputField
          label="Other Payer Address 2"
          name="otherPayerAddress2"
          onChange={handleOtherPayerFields}
          // defaultValue={claimFormEditData?.otherPayerAddress2 ?? undefined}
          value={otherPayerData?.otherPayerAddress2 ?? ''}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="City"
          name="otherPayerCity"
          onChange={handleOtherPayerFields}
          // defaultValue={claimFormEditData?.otherPayerCity ?? undefined}
          value={otherPayerData?.otherPayerCity ?? ''}
          error={errorObject && errorObject?.otherPayerCity}
          errorMessage={errorObject && errorObject?.otherPayerCity}
        />
        <SelectInput
          label="Other Payer State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="otherPayerState"
          onChange={handleOtherPayerFields}
          // defaultValue={claimFormEditData?.otherPayerState ?? undefined}
          value={otherPayerData?.otherPayerState ?? ''}
          error={errorObject && errorObject?.otherPayerState}
          errorMessage={errorObject && errorObject?.otherPayerState}
        />
        <InputField
          label="Other Payer Zip Code"
          name="otherPayerZip"
          onChange={handleOtherPayerFields}
          // defaultValue={claimFormEditData?.otherPayerZip ?? undefined}
          value={otherPayerData?.otherPayerZip ?? ''}
          error={errorObject && errorObject?.otherPayerZip}
          errorMessage={errorObject && errorObject?.otherPayerZip}
        />
      </div>

      <hr />

      <div className="grid md:grid-cols-2 gap-4 mt-6">
        {/* <Calendar
          label="Date of Condition"
          onSelectedDateChanged={(date) => onChangeDate('dateOfCondition', date)}
          defaultDate={claimFormEditData?.dateOfCondition ? new Date(claimFormEditData?.dateOfCondition) : undefined}
        /> */}
        <DatePickerField
          label="Date of Condition"
          selectedDate={formatDatePickerDate(claimFormEditData?.dateOfCondition)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate('dateOfCondition', date);
            }
          }}
        />
        <SelectInput
          label="Illness Indicator"
          options={illnessIndicatior}
          enableDefaultPlaceholder={true}
          name="illnessIndicator"
          onChange={onChange}
          defaultValue={claimFormEditData?.illnessIndicator ?? undefined}
          error={errorObject && errorObject?.illnessIndicator}
          errorMessage={errorObject && errorObject?.illnessIndicator}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Referring Physician First Name"
          name="referringPhysicianFName"
          onChange={onChange}
          defaultValue={claimFormEditData?.referringPhysicianFName ?? undefined}
          error={errorObject && errorObject?.referringPhysicianFName}
          errorMessage={errorObject && errorObject?.referringPhysicianFName}
        />
        <InputField
          label="Referring Physician Middle Name"
          name="referringPhysicianMName"
          onChange={onChange}
          defaultValue={claimFormEditData?.referringPhysicianMName ?? undefined}
          error={errorObject && errorObject?.referringPhysicianMName}
          errorMessage={errorObject && errorObject?.referringPhysicianMName}
        />
        <InputField
          label="Referring Physician Last Name"
          name="referringPhysicianLName"
          onChange={onChange}
          defaultValue={claimFormEditData?.referringPhysicianLName ?? undefined}
          error={errorObject && errorObject?.referringPhysicianLName}
          errorMessage={errorObject && errorObject?.referringPhysicianLName}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Referring NPI"
          name="referringPhysicianNpi"
          onChange={onChange}
          defaultValue={claimFormEditData?.referringPhysicianNpi ?? undefined}
          error={errorObject && errorObject?.referringPhysicianNpi}
          errorMessage={errorObject && errorObject?.referringPhysicianNpi}
        />
        <InputField
          label="Other ID"
          name="referringPhysicianOtherId"
          onChange={onChange}
          defaultValue={claimFormEditData?.referringPhysicianOtherId ?? undefined}
          error={errorObject && errorObject?.referringPhysicianOtherId}
          errorMessage={errorObject && errorObject?.referringPhysicianOtherId}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        {/* <Calendar
          label="Hos. Date from"
          onSelectedDateChanged={(date) => onChangeDate('hospDateFrom', date)}
          defaultDate={claimFormEditData?.hospDateFrom ? new Date(claimFormEditData?.hospDateFrom) : undefined}
        /> */}
        <DatePickerField
          label="Hos. Date from"
          selectedDate={formatDatePickerDate(claimFormEditData?.hospDateFrom)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate('hospDateFrom', date);
            }
          }}
        />
        {/* <Calendar
          label="Hos. Date to"
          onSelectedDateChanged={(date) => onChangeDate('hospDateTo', date)}
          defaultDate={claimFormEditData?.hospDateTo ? new Date(claimFormEditData?.hospDateTo) : undefined}
        /> */}
        <DatePickerField
          label="Hos. Date to"
          selectedDate={formatDatePickerDate(claimFormEditData?.hospDateTo)}
          onChange={(date: Date | null) => {
            if (date) {
              onChangeDate('hospDateTo', date);
            }
          }}
        />
        <InputField
          label="Claim Narrative"
          name="claimNarrative"
          onChange={onChange}
          defaultValue={claimFormEditData?.claimNarrative ?? undefined}
          error={errorObject && errorObject?.claimNarrative}
          errorMessage={errorObject && errorObject?.claimNarrative}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Resubmission Code (Payer ICN)"
          name="resubmissionCode"
          onChange={onChange}
          defaultValue={claimFormEditData?.resubmissionCode ?? undefined}
          error={errorObject && errorObject?.resubmissionCode}
          errorMessage={errorObject && errorObject?.resubmissionCode}
        />
        <InputField
          label="Prior Authorization Number"
          name="priorAuthorizationNumber"
          onChange={onChange}
          defaultValue={claimFormEditData?.priorAuthorizationNumber ?? undefined}
          error={errorObject && errorObject?.priorAuthorizationNumber}
          errorMessage={errorObject && errorObject?.priorAuthorizationNumber}
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        {[...Array(8)].map((_, i) => {
          //render alphebet
          return (
            <div className="flex" style={{ alignItems: 'end' }} key={i}>
              <InputField
                label={`${generateAlphabet(i)}`}
                name={claimFormEditData?.visitICDItemList[i]?.icdCode}
                onChange={onChange}
                disabled={true}
                value={
                  claimFormEditData?.visitICDItemList[i]?.icdCode ? claimFormEditData?.visitICDItemList[i]?.icdCode : ''
                }
              />
              <CustomSearchButton
                onClick={() => {
                  setVisibleICDModal(true);
                  // setSelectedICD(claimFormEditData?.visitICDItemList[i].icdCode);
                  setSelectedICDIndex(claimFormEditData?.visitICDItemList[i]?.id);
                }}
              />
              <CustomBinButton
                onClick={() => {
                  deleteICD(i);
                }}
              />
            </div>
          );
        })}
        {/* {claimFormEditData?.visitICDItemList?.map((item: any, key: any) => {
          return (
            <div className="flex" style={{ alignItems: 'end' }} key={key}>
              <InputField
                label={`${generateAlphabet(key)}`}
                name={item?.icdCode}
                onChange={onChange}
                disabled={true}
                value={item?.icdCode ? item?.icdCode : ''}
              />
              <CustomSearchButton
                onClick={() => {
                  setVisibleICDModal(true);
                  setSelectedICD(item?.icdCode);
                }}
              />
              <CustomBinButton onClick={() => {}} />
            </div>
          );
        })} */}
      </div>
      <hr />
      <div className="grid md:grid-cols-1 gap-4 mt-6">
        <TableContent
          enableActions={true}
          columns={columns}
          dataSource={claimFormEditData?.visitCPTCodeItemList}
          // dataSource={sampleArr}
          enableEdit={true}
          onEdit={onEditCPT}
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Tax ID"
          name="taxId"
          onChange={onChange}
          defaultValue={claimFormEditData?.taxId ?? undefined}
          error={errorObject && errorObject?.taxId}
          errorMessage={errorObject && errorObject?.taxId}
        />
        <SelectInput
          label="Type"
          options={[{ id: 'EI', name: 'EI' }]}
          enableDefaultPlaceholder={true}
          name="type"
          onChange={onChange}
          defaultValue={claimFormEditData?.type ?? undefined}
          error={errorObject && errorObject?.type}
          errorMessage={errorObject && errorObject?.type}
        />
        <InputField
          label="Patient Acct #"
          name="patientAcct"
          onChange={onChange}
          defaultValue={claimFormEditData?.patientAcct ?? undefined}
          error={errorObject && errorObject?.patientAcct}
          errorMessage={errorObject && errorObject?.patientAcct}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <SelectInput
          label="Accept Assign"
          options={basicCondition}
          enableDefaultPlaceholder={true}
          name="acceptAssign"
          onChange={onChange}
          defaultValue={claimFormEditData?.acceptAssign ?? undefined}
          error={errorObject && errorObject?.acceptAssign}
          errorMessage={errorObject && errorObject?.acceptAssign}
        />
        <InputField
          label="Total Charge"
          name="totalCharge"
          onChange={onChange}
          defaultValue={claimFormEditData?.totalCharge ?? undefined}
          error={errorObject && errorObject?.totalCharge}
          errorMessage={errorObject && errorObject?.totalCharge}
        />
        <InputField
          label="Amount Paid"
          name="amountPaid"
          onChange={onChange}
          defaultValue={claimFormEditData?.amountPaid ?? undefined}
          error={errorObject && errorObject?.amountPaid}
          errorMessage={errorObject && errorObject?.amountPaid}
        />
        <InputField
          label="Balance"
          name="balance"
          onChange={onChange}
          defaultValue={claimFormEditData?.balance ?? undefined}
          error={errorObject && errorObject?.balance}
          errorMessage={errorObject && errorObject?.balance}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <InputField
          label="Rendering Provider First Name"
          name="renderingProviderFName"
          onChange={onChange}
          defaultValue={claimFormEditData?.renderingProviderFName ?? undefined}
          error={errorObject && errorObject?.renderingProviderFName}
          errorMessage={errorObject && errorObject?.renderingProviderFName}
        />
        <InputField
          label="Rendering Provider Middle Name"
          name="renderingProviderMName"
          onChange={onChange}
          defaultValue={claimFormEditData?.renderingProviderMName ?? undefined}
          error={errorObject && errorObject?.renderingProviderMName}
          errorMessage={errorObject && errorObject?.renderingProviderMName}
        />
        <InputField
          label="Rendering Provider Last Name"
          name="renderingProviderLName"
          onChange={onChange}
          defaultValue={claimFormEditData?.renderingProviderLName ?? undefined}
          error={errorObject && errorObject?.renderingProviderLName}
          errorMessage={errorObject && errorObject?.renderingProviderLName}
        />
        <InputField
          label="Taxonomy"
          name="renderingProviderTaxonomy"
          onChange={onChange}
          defaultValue={claimFormEditData?.renderingProviderTaxonomy ?? undefined}
          error={errorObject && errorObject?.renderingProviderTaxonomy}
          errorMessage={errorObject && errorObject?.renderingProviderTaxonomy}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Rendering Provider NPI"
          name="renderingProviderNpi"
          onChange={onChange}
          defaultValue={claimFormEditData?.renderingProviderNpi ?? undefined}
          error={errorObject && errorObject?.renderingProviderNpi}
          errorMessage={errorObject && errorObject?.renderingProviderNpi}
        />
        <InputField
          label="Rendering Provider ID"
          name="renderingProviderId"
          onChange={onChange}
          defaultValue={claimFormEditData?.renderingProviderId ?? undefined}
          error={errorObject && errorObject?.renderingProviderId}
          errorMessage={errorObject && errorObject?.renderingProviderId}
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="Facility"
          options={systemFacilityData}
          enableDefaultPlaceholder={true}
          name="facilityId"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityId ?? undefined}
          error={errorObject && errorObject?.facilityId}
          errorMessage={errorObject && errorObject?.facilityId}
        />
        <InputField
          label="Address 1"
          name="facilityAddress1"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityAddress1 ?? undefined}
          error={errorObject && errorObject?.facilityAddress1}
          errorMessage={errorObject && errorObject?.facilityAddress1}
        />
        <InputField
          label="Address2"
          name="facilityAddress2"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityAddress2 ?? undefined}
          error={errorObject && errorObject?.facilityAddress2}
          errorMessage={errorObject && errorObject?.facilityAddress2}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <InputField
          label="City"
          name="facilityCity"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityCity ?? undefined}
          error={errorObject && errorObject?.facilityCity}
          errorMessage={errorObject && errorObject?.facilityCity}
        />
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="facilityState"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityState ?? undefined}
          error={errorObject && errorObject?.facilityState}
          errorMessage={errorObject && errorObject?.facilityState}
        />
        <InputField
          label="Zip Code"
          name="facilityZip"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityZip ?? undefined}
          error={errorObject && errorObject?.facilityZip}
          errorMessage={errorObject && errorObject?.facilityZip}
        />
        <InputField
          label="Ext"
          name="facilityExt"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityExt ?? undefined}
          error={errorObject && errorObject?.facilityExt}
          errorMessage={errorObject && errorObject?.facilityExt}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Facility NPI"
          name="facilityNpi"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityNpi ?? undefined}
          error={errorObject && errorObject?.facilityNpi}
          errorMessage={errorObject && errorObject?.facilityNpi}
        />
        <InputField
          label="Facility ID"
          name="facilityId"
          onChange={onChange}
          defaultValue={claimFormEditData?.facilityId ?? undefined}
          error={errorObject && errorObject?.facilityId}
          errorMessage={errorObject && errorObject?.facilityId}
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Billing Provider"
          name="billingProviderName"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderName ?? undefined}
          error={errorObject && errorObject?.billingProviderName}
          errorMessage={errorObject && errorObject?.billingProviderName}
        />
        <InputField
          label="Address 1"
          name="billingProviderAddress1"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderAddress1 ?? undefined}
          error={errorObject && errorObject?.billingProviderAddress1}
          errorMessage={errorObject && errorObject?.billingProviderAddress1}
        />
        <InputField
          label="Address2"
          name="billingProviderAddress2"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderAddress2 ?? undefined}
          error={errorObject && errorObject?.billingProviderAddress2}
          errorMessage={errorObject && errorObject?.billingProviderAddress2}
        />
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <InputField
          label="City"
          name="billingProviderCity"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderCity ?? undefined}
          error={errorObject && errorObject?.billingProviderCity}
          errorMessage={errorObject && errorObject?.billingProviderCity}
        />
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="billingProviderState"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderState ?? undefined}
          error={errorObject && errorObject?.billingProviderState}
          errorMessage={errorObject && errorObject?.billingProviderState}
        />
        <InputField
          label="Zip Code"
          name="billingProviderZip"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderZip ?? undefined}
          error={errorObject && errorObject?.billingProviderZip}
          errorMessage={errorObject && errorObject?.billingProviderZip}
        />
        <InputField
          label="Phone"
          name="billingProviderPhone"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderPhone ?? undefined}
          error={errorObject && errorObject?.billingProviderPhone}
          errorMessage={errorObject && errorObject?.billingProviderPhone}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Taxonomy"
          name="billingProviderTaxonomy"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderTaxonomy ?? undefined}
          error={errorObject && errorObject?.billingProviderTaxonomy}
          errorMessage={errorObject && errorObject?.billingProviderTaxonomy}
        />
        <InputField
          label="Billing Provider NPI"
          name="billingProviderNpi"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderNpi ?? undefined}
          error={errorObject && errorObject?.billingProviderNpi}
          errorMessage={errorObject && errorObject?.billingProviderNpi}
        />
        <InputField
          label="Billing Provider ID"
          name="billingProviderBillId"
          onChange={onChange}
          defaultValue={claimFormEditData?.billingProviderBillId ?? undefined}
          error={errorObject && errorObject?.billingProviderBillId}
          errorMessage={errorObject && errorObject?.billingProviderBillId}
        />
      </div>

      {visibleCPTModal && (
        <ClaimEditCPTModal
          openAddCPT={visibleCPTModal}
          setOpenAddCPT={onCloseCPTModal}
          mode={mode}
          editData={editData}
          icdCodesDataSource={icdCodesArr}
          visitId={visitId}
          cptCodesDataSource={cptCodesDataSource}
          modifiersDataSource={modifiersDataSource}
        />
      )}

      {visibleICDModal && (
        <ClaimICDCodeSearchModal
          icdModalOpen={visibleICDModal}
          setOpenICDModal={onCloseICDModal}
          handleAddedICDRow={handleAddedICDRow}
        />
      )}

      {openConfirm && (
        <ConfirmModal
          openConfirm={openConfirm}
          setOpenConfirm={onCloseConfirm}
          title="Delete ICD/Diagnosis Code"
          content="Are you sure you want to delete this ICD/Diagnosis Code?"
          submitButtonTitle="Yes"
          cancelButtonTitle="No"
          handleSubmit={confirmDelete}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}
    </>
  );
};

const CustomSearchButton = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      style={{
        borderRadius: '5px',
        background: '#11C8DE',
        height: '41px',
        marginLeft: '5px',
        width: '35px !important',
        padding: '10px',
      }}
    >
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // className="w-4 h-4"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
            stroke="#6b7280"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>{' '}
        </g>
      </svg>
    </button>
  );
};

const CustomBinButton = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      style={{
        borderRadius: '5px',
        background: '#FAB1A0',
        height: '41px',
        marginLeft: '5px',
        width: '35px !important',
        padding: '10px',
      }}
    >
      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            d="M1.5 3.75C1.08579 3.75 0.75 4.08579 0.75 4.5C0.75 4.91421 1.08579 5.25 1.5 5.25V3.75ZM22.5 5.25C22.9142 5.25 23.25 4.91421 23.25 4.5C23.25 4.08579 22.9142 3.75 22.5 3.75V5.25ZM1.5 5.25H22.5V3.75H1.5V5.25Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M9.75 1.5V0.75V1.5ZM8.25 3H7.5H8.25ZM7.5 4.5C7.5 4.91421 7.83579 5.25 8.25 5.25C8.66421 5.25 9 4.91421 9 4.5H7.5ZM15 4.5C15 4.91421 15.3358 5.25 15.75 5.25C16.1642 5.25 16.5 4.91421 16.5 4.5H15ZM15.75 3H16.5H15.75ZM14.25 0.75H9.75V2.25H14.25V0.75ZM9.75 0.75C9.15326 0.75 8.58097 0.987053 8.15901 1.40901L9.21967 2.46967C9.36032 2.32902 9.55109 2.25 9.75 2.25V0.75ZM8.15901 1.40901C7.73705 1.83097 7.5 2.40326 7.5 3H9C9 2.80109 9.07902 2.61032 9.21967 2.46967L8.15901 1.40901ZM7.5 3V4.5H9V3H7.5ZM16.5 4.5V3H15V4.5H16.5ZM16.5 3C16.5 2.40326 16.2629 1.83097 15.841 1.40901L14.7803 2.46967C14.921 2.61032 15 2.80109 15 3H16.5ZM15.841 1.40901C15.419 0.987053 14.8467 0.75 14.25 0.75V2.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L15.841 1.40901Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25H9ZM10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75H10.5ZM10.5 17.25V9.75H9V17.25H10.5Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25H13.5ZM15 9.75C15 9.33579 14.6642 9 14.25 9C13.8358 9 13.5 9.33579 13.5 9.75H15ZM15 17.25V9.75H13.5V17.25H15Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M18.865 21.124L18.1176 21.0617L18.1176 21.062L18.865 21.124ZM17.37 22.5L17.3701 21.75H17.37V22.5ZM6.631 22.5V21.75H6.63093L6.631 22.5ZM5.136 21.124L5.88343 21.062L5.88341 21.0617L5.136 21.124ZM4.49741 4.43769C4.46299 4.0249 4.10047 3.71818 3.68769 3.75259C3.2749 3.78701 2.96818 4.14953 3.00259 4.56231L4.49741 4.43769ZM20.9974 4.56227C21.0318 4.14949 20.7251 3.78698 20.3123 3.75259C19.8995 3.7182 19.537 4.02495 19.5026 4.43773L20.9974 4.56227ZM18.1176 21.062C18.102 21.2495 18.0165 21.4244 17.878 21.5518L18.8939 22.6555C19.3093 22.2732 19.5658 21.7486 19.6124 21.186L18.1176 21.062ZM17.878 21.5518C17.7396 21.6793 17.5583 21.75 17.3701 21.75L17.3699 23.25C17.9345 23.25 18.4785 23.0379 18.8939 22.6555L17.878 21.5518ZM17.37 21.75H6.631V23.25H17.37V21.75ZM6.63093 21.75C6.44274 21.75 6.26142 21.6793 6.12295 21.5518L5.10713 22.6555C5.52253 23.0379 6.06649 23.25 6.63107 23.25L6.63093 21.75ZM6.12295 21.5518C5.98449 21.4244 5.89899 21.2495 5.88343 21.062L4.38857 21.186C4.43524 21.7486 4.69172 22.2732 5.10713 22.6555L6.12295 21.5518ZM5.88341 21.0617L4.49741 4.43769L3.00259 4.56231L4.38859 21.1863L5.88341 21.0617ZM19.5026 4.43773L18.1176 21.0617L19.6124 21.1863L20.9974 4.56227L19.5026 4.43773Z"
            fill="#71717A"
          ></path>{' '}
        </g>
      </svg>
    </button>
  );
};
