import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EditAddressInitialInterface, EditAddressInterface } from '../../../interfaces/userInterface';
import { RootState } from '../../store';
import { addAddressAPIRequest } from '../../../api/user/address';

const initialState: EditAddressInitialInterface = {
  editAddressLoading: false,
  editAddressData: {},
  editAddressError: null,
  success: false,
  editAddressStatus: 'IDLE',
};

export const editAddressRequest: any = createAsyncThunk(
  'users/edit/address',
  async (address: EditAddressInterface, thunkAPI: any) => {
    try {
      const response: any = await addAddressAPIRequest(address);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editAddressSlice = createSlice({
  name: 'editAddress',
  initialState,
  reducers: {
    clearEditAddressResponse: (state) => {
      state.editAddressLoading = false;
      state.editAddressStatus = 'IDLE';
      state.editAddressData = {};
      state.editAddressError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editAddressRequest.pending, (state, action) => {
        state.editAddressLoading = true;
        state.editAddressStatus = 'PENDING';
      })
      .addCase(editAddressRequest.fulfilled, (state, action) => {
        state.editAddressLoading = false;
        state.editAddressData = action.payload;
        state.editAddressStatus = 'SUCCESS';
      })
      .addCase(editAddressRequest.rejected, (state, action) => {
        state.editAddressLoading = false;
        state.editAddressError = action.payload;
        state.editAddressStatus = 'FAILED';
      });
  },
});

export const { clearEditAddressResponse } = editAddressSlice.actions;

export const addUserAddressSelector = (state: RootState) => state.EditAddress;

export default editAddressSlice.reducer;
