import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { TextArea } from '../../../atoms/TextArea';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { useAppDispatch } from '../../../../hooks/storeHooks/hooks';
import { addVisitNoteRequest } from '../../../../redux/slices/patient/addVisitNoteSlice';
import moment from 'moment';
import { validDateFormat } from '../../../../utils/commonFunctions';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    render: (text: string, record: any) => {
      return (
        <>
          <b>{record?.responsible}</b>: {record?.comment}
        </>
      );
    },
  },
];

export const NotesModal = ({ visibleEditModal, setVisibleEditModal, noteHistoryDataSource, visitId }: any) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({});
  const [errorObject, setErrorObject] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  const handleFieldValueChange = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const validation = (data: any) => {
    let err: any = {};

    if (!data?.note) {
      err.note = 'Required !';
    }
    setErrorObject(err);
    return err;
  };

  const onSubmit = () => {
    const validationData = validation(formData);

    if (Object.keys(validationData)?.length > 0) {
      return;
    }
    dispatch(addVisitNoteRequest({ visitId: visitId, visitData: formData }));
  };

  return (
    <Modal show={visibleEditModal} onClose={() => setVisibleEditModal(false)}>
      <Modal.Header>View Notes</Modal.Header>
      <Modal.Body>
        {noteHistoryDataSource?.length > 0 && <TableContent columns={columns} dataSource={noteHistoryDataSource} />}
        <div className="grid md:grid-cols-1 gap-5 mt-2">
          <TextArea
            onChange={handleFieldValueChange}
            name="note"
            label="Note"
            //   error={errorObject && errorObject?.patientVisitStatus}
            //   errorMessage={errorObject && errorObject?.patientVisitStatus}
          />
        </div>
        <div className="flex gap-5 mt-5">
          <CommonButton
            type="button"
            label={'Close'}
            buttonType="secondary"
            onClick={() => setVisibleEditModal(false)}
          />
          <CommonButton type="button" label={'Save'} buttonType="primary" onClick={onSubmit} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
