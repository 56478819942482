import { authenticatedRequest, nonAuthenticatedRequest } from '../../utils/commonAxios';

export const getSystemVisitStatusAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/visit-status`, 'get', {
    data: {},
  });
};

export const getIPDataAPIRequest = async () => {
  return await nonAuthenticatedRequest(`https://api.ipify.org?format=json`, 'get', {
    headers: {
      Accept: 'application/json',
      'Content-Type': `multipart/form-data;`,
    },
  });
  // return await fetch(`https://api.ipify.org?format=json`, {
  //   method: 'GET',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': `multipart/form-data;`,
  //   },
  // }).then((res) => res.json());
};
