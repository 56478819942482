import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchInsuranceInitialInterface, FetchInsuranceInterface } from '../../../interfaces/insuranceInterface';
import { getInsuranceAPIRequest } from '../../../api/insurance/insurance';

const initialState: FetchInsuranceInitialInterface = {
  insuranceLoading: false,
  insuranceData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  insuranceError: null,
  insuranceStatus: 'IDLE',
};

export const getInsuranceRequest: any = createAsyncThunk(
  'insurances/get',
  async (insuranceData: FetchInsuranceInterface, thunkAPI: any) => {
    try {
      const response: any = await getInsuranceAPIRequest(insuranceData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getInsuranceSlice = createSlice({
  name: 'fetchInsurances',
  initialState,
  reducers: {
    clearFetchInsuranceResponse: (state) => {
      state.insuranceLoading = false;
      state.insuranceStatus = 'IDLE';
      state.insuranceData = {
        currentPage: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
      };
      state.insuranceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsuranceRequest.pending, (state, action) => {
        state.insuranceLoading = true;
        state.insuranceStatus = 'PENDING';
      })
      .addCase(getInsuranceRequest.fulfilled, (state, action) => {
        state.insuranceLoading = false;
        state.insuranceData = action.payload;
        state.insuranceStatus = 'SUCCESS';
      })
      .addCase(getInsuranceRequest.rejected, (state, action) => {
        state.insuranceLoading = false;
        state.insuranceError = action.payload;
        state.insuranceStatus = 'FAILED';
      });
  },
});

export const { clearFetchInsuranceResponse } = getInsuranceSlice.actions;

export const getInsuranceSelector = (state: RootState) => state.GetInsurance;

export default getInsuranceSlice.reducer;
