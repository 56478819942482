import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { OtherPayerData, PatientClaimInterface, PayerSpData } from '../../../../interfaces/patientInterface';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { getPatientsByIdSelector } from '../../../../redux/slices/patient/getPatientByIdSlice';
import { Spinner } from '../../../atoms/Spinner';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { PrintModal } from './PrintModal';
import { convertDate, validDateFormat } from '../../../../utils/commonFunctions';
import {
  clearPrintPatientClaimResponse,
  printPatientClaimRequest,
  printPatientClaimSelector,
} from '../../../../redux/slices/patient/printPatientClaimSlice';
import { ApplyNotes } from './ApplyNotes';
import { EditStatusModal } from './EditStatusModal';
import { NotesModal } from './NotesModal';
import { ClaimHistoryModal } from './ClaimHistoryModal';
import { ClearingHouseResponseModal } from './ClearingHouseResponseModal';
import { ViewERAModal } from './ViewERAModal';
import { TableSearch } from '../../../tableSearch/TableSearch';
import {
  clearFetchClaimByIdResponse,
  getClaimByIdRequest,
  getClaimByIdSelector,
} from '../../../../redux/slices/billing/getClaimByIdSlice';
import { RightSheetLarge } from '../../../rightSheet/RightSheetLarge';
import { ClaimForm } from '../../../billing/primaryClaims/ClaimForm';
import {
  clearEditClaimResponse,
  editClaimRequest,
  editClaimSelector,
} from '../../../../redux/slices/billing/editClaimSlice';
import {
  addClaimApplyNoteSelector,
  clearAddClaimApplyNoteResponse,
} from '../../../../redux/slices/patient/addClaimApplyNoteSlice';
import {
  getClaimApplyNoteHistoryRequest,
  getClaimApplyNoteHistorySelector,
  clearFetchVisitApplyNoteHistoryResponse,
} from '../../../../redux/slices/patient/getClaimApplyNoteHistorySlice';
import { claimOtherPayerJson, claimPayerJson } from '../../../../constants/billingConstants';
import { clearFetchICDCodesResponse, getICDCodesSelector } from '../../../../redux/slices/system/getICDCodesSlice';
import { clearFetchCPTCodesResponse, getCPTCodesSelector } from '../../../../redux/slices/system/getCPTCodesSlice';
import { clearFetchModifiersResponse, getModifiersSelector } from '../../../../redux/slices/system/getModifiersSlice';
import { getICDCodeListRequest } from '../../../../redux/slices/patient/getICDCodeListSlice';
import { getCPTCodeListRequest } from '../../../../redux/slices/patient/getCPTCodeListSlice';
import moment from 'moment';
import { getPatientInsuranceByTypeRequest } from '../../../../redux/slices/patient/getPatientInsuranceByTypeSlice';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'Facility',
    dataIndex: 'facilityName',
    key: 'facilityName',
  },
  {
    title: 'Referring Provider',
    dataIndex: 'refProvider',
    key: 'refProvider',
  },
  {
    title: 'Insurance',
    dataIndex: 'insuranceName',
    key: 'insuranceName',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text: string) => moment(text).format(validDateFormat),
  },
];

const dataSource1 = [
  {
    id: '1',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityName: 'The Rivers of Grosse Pointe',
    refProvider: 'KIMBERLY SNYDER',
    insuranceName: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
  {
    id: '1',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityName: 'The Rivers of Grosse Pointe',
    refProvider: 'KIMBERLY SNYDER',
    insuranceName: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
  {
    id: '1',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityName: 'The Rivers of Grosse Pointe',
    refProvider: 'KIMBERLY SNYDER',
    insuranceName: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
  {
    id: '1',
    visitId: '62554',
    visitDate: '12/19/2023',
    facilityName: 'The Rivers of Grosse Pointe',
    refProvider: 'KIMBERLY SNYDER',
    insuranceName: 'MI Medicare Plus Blue',
    status: 'Claim Created',
    date: '12/19/2023',
  },
];

const billColumns = [
  {
    title: 'Total Billed',
    dataIndex: 'totalBilled',
    key: 'totalBilled',
  },
  {
    title: 'Total Payment',
    dataIndex: 'totalPayment',
    key: 'totalPayment',
  },
  {
    title: 'Total Adjustments',
    dataIndex: 'totalAdjustments',
    key: 'totalAdjustments',
  },
  {
    title: 'Insurance Balance',
    dataIndex: 'insuranceBalanace',
    key: 'insuranceBalanace',
  },
  {
    title: 'Patient Balance',
    dataIndex: 'patientBalance',
    key: 'patientBalance',
  },
  {
    title: 'Last Statement Sent Date',
    dataIndex: 'lastStatementSentDate',
    key: 'lastStatementSentDate',
  },
  {
    title: 'Total Outstanding Balance',
    dataIndex: 'totalOutStandingBalance',
    key: 'totalOutStandingBalance',
  },
];

const billDataSource = [
  {
    totalBilled: '$682.00',
    totalPayment: '$256.32',
    totalAdjustments: '$171.68',
    insuranceBalanace: '$254.00',
    patientBalance: '$0.00',
    lastStatementSentDate: 'N/A',
    totalOutStandingBalance: '	$254.00',
  },
];

const settingsActionArr = [
  {
    id: 'EDIT',
    title: 'Edit',
  },
  {
    id: 'EDIT_STATUS',
    title: 'Edit Status',
  },
  {
    id: 'NOTES',
    title: 'Notes',
  },
];

const dottedActionsArr = [
  {
    id: 'CLAIM_HISTORY',
    title: 'Claim History',
  },
  {
    id: 'CLEARING_HOUSE_RESPONSE',
    title: 'Clearing House Response',
  },
  {
    id: 'VIEW_ERA',
    title: 'View ERA',
  },
];

export const Claim: React.FC<PatientClaimInterface> = ({
  selectedId,
  fetchPatientById,
  fetchDataLoading,
  addDataLoading,
  mode,
}) => {
  const dispatch = useAppDispatch();
  const { fetchPatientByIdData, fetchPatientByIdStatus } = useAppSelector(getPatientsByIdSelector);
  const { printPatientClaimData, printPatientClaimStatus } = useAppSelector(printPatientClaimSelector);
  const { claimByIdData, claimByIdStatus } = useAppSelector(getClaimByIdSelector);
  const { claimEditData, claimEditStatus, claimEditError } = useAppSelector(editClaimSelector);
  const { addClaimsApplyNoteStatus, addClaimsApplyNoteError } = useAppSelector(addClaimApplyNoteSelector);
  const { statusApplyNoteHistory, applyNoteHistoryData, errorApplyNoteHistory } = useAppSelector(
    getClaimApplyNoteHistorySelector
  );

  const { icdCodesData, icdCodesStatus, icdCodesError } = useAppSelector(getICDCodesSelector);
  const { cptCodesData, cptCodesStatus, cptCodesError } = useAppSelector(getCPTCodesSelector);
  const { modifiersData, modifiersStatus, modifiersError } = useAppSelector(getModifiersSelector);

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [claimFormData, setClaimFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  const [dataSource, setDataSource] = useState<any>([]);
  const [visiblePrintModal, setVisiblePrintModal] = useState(false);
  const [selectId, setSelectedId] = useState<any>();
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [printFormData, setPrintFormData] = useState<any>({});
  const [dottedActionClicked, setDottedActionClicked] = useState<boolean>(false);
  const [settingActionClicked, setSettingActionClicled] = useState<boolean>(false);

  const [visibleApplyNotes, setVisibleApplyNotes] = useState<boolean>(false);
  const [applyNoteHistoryDataSource, setApplyNoteHistoryDataSource] = useState<any>([]);

  const [visibleEditStatus, setVisibleEditStatus] = useState<boolean>(false);
  const [visitEditStatusDataSource, setVisitEditStatusDataSource] = useState<any>([]);
  const [editStatusFormData, setEditStatusFormdata] = useState<any>({});

  const [visibleNotesModal, setVisibleNotesModal] = useState<boolean>(false);
  const [noteHistoryDataSource, setNoteHistoryDataSource] = useState<any>([]);
  const [editNotesFormData, setEditNotesFormdata] = useState<any>({});

  const [visibleClaimHistory, setVisibleClaimHistoty] = useState<boolean>(false);
  const [claimHistoryDataSource, setClaimHistoryDataSource] = useState<any>([]);

  const [visibleHouseResponse, setVisibleHouseResponse] = useState<boolean>(false);
  const [houseResponseDataSource, setHouseResponseDataSource] = useState<any>([]);

  const [visibleClaimERA, setVisibleClaimERA] = useState<boolean>(false);
  const [claimERADataSource, setClaimERADataSource] = useState<any>([]);

  const [claimFormEditData, setClaimFormEditData] = useState<any>({});
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [otherPayerData, setOtherPayerData] = useState<OtherPayerData>(claimOtherPayerJson);
  const [payerData, setPayerData] = useState<PayerSpData>(claimPayerJson);
  const [visitId, setVisitId] = useState<number>();

  const [icdCodesDataSource, setICDCodesDataSource] = useState<any>([]);
  const [cptCodesDataSource, setCPTCodesDataSource] = useState<any>([]);
  const [modifiersDataSource, setModifiersDataSource] = useState<any>([]);

  const [cptDataSource, setCPTDataSource] = useState<any>([]);
  const [icdDataSource, setICDDataSource] = useState<any>([]);

  // console.log('=== selectedId ===', selectedId);

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      const convertedData = convertDataSet(fetchPatientByIdData);
      setDataSource(convertedData);
    }
  }, [fetchPatientByIdStatus]);

  useEffect(() => {
    if (printPatientClaimStatus === 'SUCCESS') {
      setVisiblePrintModal(false);
      if (printPatientClaimData?.url !== null) {
        window.open(printPatientClaimData?.url, '_blank', 'noopener');
      } else {
        setAlertObj({
          color: 'failure',
          message: 'Something is wrong with the print URL!',
          error: false,
        });
        setVisibleAlert(true);
      }
      setTimeout(() => {
        dispatch(clearPrintPatientClaimResponse());
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (printPatientClaimStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearPrintPatientClaimResponse());
    }
  }, [printPatientClaimStatus]);

  useEffect(() => {
    if (claimByIdStatus === 'SUCCESS') {
      const claimData: any = claimByIdData;
      setPayerData({
        payerId: claimData?.payer?.payerId,
        payerName: claimData?.payer?.name,
        insuredIdNumber: claimData?.insuredIdNumber,
        payerAddress1: claimData?.payerAddress1,
        payerAddress2: claimData?.payerAddress2,
        payerCity: claimData?.payerCity,
        payerState: claimData?.payerState,
        payerZip: claimData?.payerZip,
        payerOfficeNumber: claimData?.payerOfficeNumber,
      });
      setOtherPayerData({
        otherPayerId: claimData?.otherPayer?.payerId,
        otherPayerClaimId: claimData?.otherPayerClaimId,
        otherPayerAddress1: claimData?.otherPayerAddress1,
        otherPayerAddress2: claimData?.otherPayerAddress2,
        otherPayerCity: claimData?.otherPayerCity,
        otherPayerState: claimData?.otherPayerState,
        otherPayerZip: claimData?.otherPayerZip,
      });
      setClaimFormEditData(claimByIdData);
      setVisibleEdit(true);
    } else if (claimByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchClaimByIdResponse());
  }, [claimByIdStatus]);

  useEffect(() => {
    if (claimEditStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'claim');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleEdit(false);
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (claimEditStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: claimEditError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearEditClaimResponse());
  }, [claimEditStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (icdCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setICDCodesDataSource(icdCodesData?.items);
    } else if (icdCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in ICD Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchICDCodesResponse());
  }, [icdCodesStatus]);

  useEffect(() => {
    // console.log('===icdCodesStatus =====', icdCodesStatus);
    if (cptCodesStatus === 'SUCCESS') {
      // console.log('===icdCodesData =====', icdCodesData);

      setCPTCodesDataSource(cptCodesData?.items);
    } else if (cptCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in CPT Codes Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchCPTCodesResponse());
  }, [cptCodesStatus]);

  useEffect(() => {
    if (modifiersStatus === 'SUCCESS') {
      setModifiersDataSource(modifiersData?.items);
    } else if (modifiersStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in Modifier Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchModifiersResponse());
  }, [modifiersStatus]);

  useEffect(() => {
    if (addClaimsApplyNoteStatus === 'SUCCESS') {
      setVisibleApplyNotes(false);
      setAlertObj({
        color: 'success',
        message: 'Successfully Added !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addClaimsApplyNoteStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addClaimsApplyNoteError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearAddClaimApplyNoteResponse());
  }, [addClaimsApplyNoteStatus]);

  useEffect(() => {
    if (statusApplyNoteHistory === 'SUCCESS') {
      setApplyNoteHistoryDataSource(applyNoteHistoryData);
      setVisibleApplyNotes(true);
    } else if (statusApplyNoteHistory === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: errorApplyNoteHistory?.message ?? 'Something went wrong in Apply Notes History Data Source !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitApplyNoteHistoryResponse());
  }, [statusApplyNoteHistory]);

  const getICDCodeList = (visitId: number) => {
    dispatch(getICDCodeListRequest({ visitId }));
  };

  const getCPTCodeList = (visitId: number) => {
    dispatch(getCPTCodeListRequest({ visitId }));
  };

  const convertDataSet = (dataSet: []) => {
    let newArr = [];

    for (let index = 0; index < dataSet.length; index++) {
      const element: any = dataSet[index];
      let obj = {
        facilityName: element?.facilityName,
        date: convertDate(element?.date),
        insuranceName: element?.insuranceName,
        id: element?.id,
        refProvider: element?.refProvider,
        visitDate: convertDate(element?.visitDate),
        status: element?.status,
        visitId: element?.visitId,
      };
      newArr[newArr.length] = obj;
    }

    return newArr;
  };

  const fetchClaimById = async (claimId: number) => {
    dispatch(getClaimByIdRequest({ claimId }));
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    // const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    // setMode('EDIT');
    // setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const handleDottedActions = (id: any, rowIndex: any) => {
    setSettingActionClicled(false);
    setDottedActionClicked(true);
    setErrorObj({});
    const dataSet = dataSource[rowIndex];
    if (id === 'CLAIM_HISTORY') {
      handleVisibleClaimHistory();
    } else if (id === 'CLEARING_HOUSE_RESPONSE') {
      handleVisibleClearingHouseResponse();
    } else if (id === 'VIEW_ERA') {
      handleVisibleClaimERA();
    }
  };

  const handleSettingsAction = (id: any, rowIndex: any) => {
    setSettingActionClicled(true);
    setDottedActionClicked(false);
    setErrorObj({});
    getPatientInsurance();
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setVisitId(dataSet?.visitId);

    if (id === 'EDIT') {
      fetchClaimById(dataSet?.id);
    } else if (id === 'EDIT_STATUS') {
      handleVisibleEditStatus();
    } else if (id === 'NOTES') {
      handleVisibleNotes();
    }
  };

  const getPatientInsurance = () => {
    dispatch(getPatientInsuranceByTypeRequest({ patientId: selectedId, type: 'ALL' }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleAddNewModal = () => {
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    setVisibleModal(false);
  };

  const handleClaimFormChange = (e: any) => {
    setClaimFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePrint = (event: any, rowIndex: any) => {
    const dataSet = dataSource[rowIndex];
    setSelectedToEdit(dataSet);
    setVisiblePrintModal(true);
  };

  const onClosePrintModal = (data: boolean) => {
    setVisiblePrintModal(data);
  };

  const handlePrintForm = (e: any) => {
    setPrintFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const printPdf = () => {
    let obj = {
      claimType: printFormData.claimType ? printFormData.claimType : '',
      claimPrintType: printFormData.claimPrintType ? printFormData.claimPrintType : '',
      isNeedPrintDate: printFormData.isNeedPrintDate ? printFormData.isNeedPrintDate : false,
    };

    let finalObject = {
      patientId: selectedId,
      claimId: selectedToEdit?.id,
      printOptions: obj,
    };

    dispatch(printPatientClaimRequest(finalObject));
  };

  const handleVisibleApplyNotes = () => {
    setVisibleApplyNotes(true);
    // getApplyNoteHistory();
  };

  const handleCloseApplyNotes = (close: boolean) => {
    setVisibleApplyNotes(close);
  };

  const handleVisibleEditStatus = () => {
    setVisibleEditStatus(true);
  };

  const handleCloseEditStatus = (close: boolean) => {
    setVisibleEditStatus(close);
  };

  const onChangeEditStatusFields = (event: any) => {
    setEditStatusFormdata((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onSubmitEditStatus = () => {};

  const handleVisibleNotes = () => {
    setVisibleNotesModal(true);
  };

  const handleCloseNotes = (close: boolean) => {
    setVisibleNotesModal(close);
  };

  const onChangeNotesFields = (event: any) => {
    setEditNotesFormdata((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const getApplyNoteHistory = (patientId: number) => {
    dispatch(getClaimApplyNoteHistoryRequest({}));
  };

  const onSubmitNotes = () => {};

  const handleVisibleClaimHistory = () => {
    setVisibleClaimHistoty(true);
  };

  const handleCloseClaimHistory = (close: boolean) => {
    setVisibleClaimHistoty(close);
  };

  const handleVisibleClearingHouseResponse = () => {
    setVisibleHouseResponse(true);
  };

  const handleCloseClearingHouseResponse = (close: boolean) => {
    setVisibleHouseResponse(close);
  };

  const handleVisibleClaimERA = () => {
    setVisibleClaimERA(true);
  };

  const handleCloseClaimERA = (close: boolean) => {
    setVisibleClaimERA(close);
  };

  const editFormOnClose = () => {
    setVisibleEdit(false);
  };

  // Function to reset the payer data fields
  const resetPayerData = () => {
    const { payerName, showPayerAddress, payerId, ...resetData } = claimPayerJson;
    return resetData;
  };

  const getPayerDataFromClaim = (claimFormEditData: any) => {
    const { payerName, showPayerAddress, payerId, ...resetData } = claimPayerJson;

    return Object.keys(resetData).reduce((acc: any, key: any) => {
      acc[key] = claimFormEditData[key];
      return acc;
    }, {});
  };

  const resetOtherPayerData = () => {
    const { otherPayerId, ...resetData } = claimOtherPayerJson;
    return resetData;
  };

  const getOtherPayerDataFromClaim = (otherPayerData: any) => {
    const { otherPayerId, ...resetData } = claimOtherPayerJson;
    return Object.keys(resetData).reduce((acc: any, key: any) => {
      acc[key] = otherPayerData[key];
      return acc;
    }, {});
  };

  const onChangeClaimEditFormFields = (event: any) => {
    const { name, value, checked } = event.target;
    if (name === 'showPayerAddress') {
      setClaimFormEditData((prev: any) => ({ ...prev, [name]: checked }));
    } else {
      setClaimFormEditData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const onChangeClaimEditFormDates = (name: string, date: Date) => {
    setClaimFormEditData((prev: any) => ({ ...prev, [name]: date }));
  };

  const handlePayerFields = (event: any) => {
    const { name, value } = event.target;
    if (name === 'payerName') {
      if (value === claimFormEditData?.payer?.id) {
        setPayerData((prev: any) => ({
          ...prev,
          [name]: value,
          payerId: value,
          ...getPayerDataFromClaim(claimFormEditData),
        }));
      } else {
        setPayerData((prev: any) => ({
          ...prev,
          [name]: value,
          payerId: value,
          ...resetPayerData(),
        }));
      }
    } else {
      setPayerData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const handleOtherPayerFields = (event: any) => {
    const { name, value } = event.target;
    if (name === 'otherPayerName') {
      if (value === claimFormEditData?.otherPayer?.id) {
        setOtherPayerData((prev: any) => ({
          ...prev,
          otherPayerId: value,
          ...getOtherPayerDataFromClaim(claimFormEditData),
        }));
      } else {
        setOtherPayerData((prev: any) => ({
          ...prev,
          otherPayerId: value,
          ...resetOtherPayerData(),
        }));
      }
    } else {
      setOtherPayerData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onSubmitEditForm = () => {
    const allFields = { ...payerData, ...otherPayerData, ...claimFormEditData };

    console.log('=== allFields ===', allFields);

    if (allFields?.patientDob) {
      allFields.patientDob = convertDate(allFields?.patientDob);
    }

    if (allFields?.insuredDob) {
      allFields.insuredDob = convertDate(allFields?.insuredDob);
    }

    if (allFields?.otherInsuredDob) {
      allFields.otherInsuredDob = convertDate(allFields?.otherInsuredDob);
    }

    if (allFields?.primaryPaymentDate) {
      allFields.primaryPaymentDate = convertDate(allFields?.primaryPaymentDate);
    }

    if (allFields?.dateOfCondition) {
      allFields.dateOfCondition = convertDate(allFields?.dateOfCondition);
    }

    if (allFields?.hospDateFrom) {
      allFields.hospDateFrom = convertDate(allFields?.hospDateFrom);
    }

    if (allFields?.hospDateTo) {
      allFields.hospDateTo = convertDate(allFields?.hospDateTo);
    }

    if (allFields?.patientEmployment) {
      allFields.patientEmployment = 'UNKNOWN';
    }

    const obj = {
      claimId: selectId,
      claimEdit: allFields,
    };
    // console.log('=== submit edit ===', obj);
    dispatch(editClaimRequest(obj));
  };

  return (
    <>
      {visibleAlert && <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />}
      <div className="flex w-96 ml-auto">
        <CommonButton
          label="Apply Notes"
          buttonType="primary"
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path
                fill-rule="evenodd"
                d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                clip-rule="evenodd"
              />
            </svg>
          }
          onClick={handleVisibleApplyNotes}
          style={{ marginRight: '3px' }}
        />
        {mode !== 'VIEW' && (
          <CommonButton
            label="Create Secondary Claims"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {fetchDataLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns}
              dataSource={dataSource}
              enablePrint={true}
              enableDots={true}
              enableSettings={true}
              onHandlePrint={handlePrint}
              onHandleDottedAction={handleDottedActions}
              onHandleSettingsAction={handleSettingsAction}
              dottedActions={dottedActionsArr}
              settingActions={settingsActionArr}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={false} mode={mode} />
          )}
        </>
      )}
      {visiblePrintModal ? (
        <PrintModal
          openModal={visiblePrintModal}
          setOpenModal={onClosePrintModal}
          onChange={handlePrintForm}
          printView={printPdf}
        />
      ) : (
        <></>
      )}

      {visibleApplyNotes && (
        <ApplyNotes
          applyBotesModalOpen={visibleApplyNotes}
          setOpenApplyNotesModal={handleCloseApplyNotes}
          applyNoteHistoryDataSource={applyNoteHistoryDataSource}
        />
      )}

      {visibleEditStatus && (
        <EditStatusModal
          editStatusModalOpen={visibleEditStatus}
          setOpenEditStatusModal={handleCloseEditStatus}
          editStatusFormData={editStatusFormData}
          onChangeFields={onChangeEditStatusFields}
          onSubmit={onSubmitEditStatus}
        />
      )}

      {visibleNotesModal && (
        <NotesModal
          visibleNotesModal={visibleNotesModal}
          setVisibleNotesModal={handleCloseNotes}
          editNotesFormData={editNotesFormData}
          onChangeNotesFields={onChangeNotesFields}
          onSubmit={onSubmitNotes}
        />
      )}

      {visibleClaimHistory && (
        <ClaimHistoryModal
          claimHistoryModal={visibleClaimHistory}
          setClaimHistoryModal={handleCloseClaimHistory}
          dataSource={claimHistoryDataSource}
        />
      )}

      {visibleHouseResponse && (
        <ClearingHouseResponseModal
          clearingHouseResponseModal={visibleHouseResponse}
          setClearingHouseResponseModal={handleCloseClearingHouseResponse}
          dataSource={houseResponseDataSource}
        />
      )}

      {visibleClaimERA && <ViewERAModal visibleClaimERA={visibleClaimERA} setVisibleClaimERA={handleCloseClaimERA} />}

      {visibleEdit && (
        <RightSheetLarge
          title="Claim Preview"
          children={
            <ClaimForm
              claimFormEditData={claimFormEditData}
              onChange={onChangeClaimEditFormFields}
              onChangeDate={onChangeClaimEditFormDates}
              payerData={payerData}
              handlePayerFields={handlePayerFields}
              handleOtherPayerFields={handleOtherPayerFields}
              otherPayerData={otherPayerData}
              visitId={visitId}
              getICDCodeList={getICDCodeList}
              getCPTCodeList={getCPTCodeList}
              cptDataSource={cptDataSource}
              icdDataSource={icdDataSource}
              icdCodesDataSource={icdCodesDataSource}
              cptCodesDataSource={cptCodesDataSource}
              modifiersDataSource={modifiersDataSource}
              fetchClaimById={fetchClaimById}
            />
          }
          enableStepper={false}
          onClose={editFormOnClose}
          submitButtonTitle="Update Claim"
          cancelButtonTitle="Cancel"
          enableAlert={false}
          enableTitleAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
          onSubmit={onSubmitEditForm}
        />
      )}
    </>
  );
};
