import { useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { TableSearch } from '../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';

const columns = [
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'January',
    dataIndex: 'january',
    key: 'january',
  },
  {
    title: 'February',
    dataIndex: 'february',
    key: 'february',
  },
  {
    title: 'March',
    dataIndex: 'march',
    key: 'march',
  },
  {
    title: 'April',
    dataIndex: 'april',
    key: 'april',
  },
  {
    title: 'May',
    dataIndex: 'may',
    key: 'may',
  },
  {
    title: 'June',
    dataIndex: 'june',
    key: 'june',
  },
  {
    title: 'July',
    dataIndex: 'july',
    key: 'july',
  },
  {
    title: 'August',
    dataIndex: 'august',
    key: 'august',
  },
  {
    title: 'September',
    dataIndex: 'september',
    key: 'september',
  },
  {
    title: 'October',
    dataIndex: 'october',
    key: 'october',
  },
  {
    title: 'November',
    dataIndex: 'november',
    key: 'november',
  },
  {
    title: 'December',
    dataIndex: 'december',
    key: 'december',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
  },
];

const dataSource = [
  {
    provider: 'Gihan',
    january: '191',
    february: '173',
    march: '132',
    april: '132	',
    may: '273',
    june: '132',
    july: '132',
    august: '132',
    september: '132',
    october: '132',
    november: '132',
    december: '132',
    count: '1825',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'visit-count', label: 'Visit Count Report', status: 'active', link: 'visit-count' },
];

export const VisitCountReportPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Visit Count Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      {/* <TableSearch /> */}
      {dataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
        </div>
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
