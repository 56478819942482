import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { DeleteModifierByIdInitialInterface, GetModifierByIdInterface } from '../../../interfaces/systemInterfaces';
import { deleteModifierByIdAPIRequest } from '../../../api/system/code';

const initialState: DeleteModifierByIdInitialInterface = {
  deleteModifierByIdLoading: false,
  deleteModifierByIdData: {},
  deleteModifierByIdError: null,
  deleteModifierByIdStatus: 'IDLE',
};

export const deleteModifierByIdRequest: any = createAsyncThunk(
  'codes/modifier/delete/id',
  async (code: GetModifierByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteModifierByIdAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteModifierByIdSlice = createSlice({
  name: 'deleteModifierById',
  initialState,
  reducers: {
    clearDeleteModifierByIdResponse: (state) => {
      state.deleteModifierByIdLoading = false;
      state.deleteModifierByIdStatus = 'IDLE';
      state.deleteModifierByIdData = [];
      state.deleteModifierByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteModifierByIdRequest.pending, (state, action) => {
        state.deleteModifierByIdLoading = true;
        state.deleteModifierByIdStatus = 'PENDING';
      })
      .addCase(deleteModifierByIdRequest.fulfilled, (state, action) => {
        state.deleteModifierByIdLoading = false;
        state.deleteModifierByIdData = action.payload;
        state.deleteModifierByIdStatus = 'SUCCESS';
      })
      .addCase(deleteModifierByIdRequest.rejected, (state, action) => {
        state.deleteModifierByIdLoading = false;
        state.deleteModifierByIdError = action.payload;
        state.deleteModifierByIdStatus = 'FAILED';
      });
  },
});

export const { clearDeleteModifierByIdResponse } = deleteModifierByIdSlice.actions;

export const deleteModifierByIdSelector = (state: RootState) => state.DeleteModifierCodeById;

export default deleteModifierByIdSlice.reducer;
