import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderCarrierApplicationStatusUpdatePropsInterface,
  ProviderCarrierApplicationStatusUpdateInterface,
} from '../../../interfaces/providersInterface';
import { editProviderCarrierApplicationStatusAPIRequest } from '../../../api/provider/providerCarrier';

const initialState: ProviderCarrierApplicationStatusUpdatePropsInterface = {
  editProviderApplicationStatusLoading: false,
  editProviderApplicationStatusData: {},
  editProviderApplicationStatusError: null,
  editProviderApplicationStatusStatus: 'IDLE',
};

export const editProviderCarrierApplicationStatusRequest: any = createAsyncThunk(
  'provider/carriers/updateAppStatus',
  async (carrier: ProviderCarrierApplicationStatusUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await editProviderCarrierApplicationStatusAPIRequest(carrier);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderCarrierApplicationStatusSlice = createSlice({
  name: 'editProviderCarrierApplicationStatus',
  initialState,
  reducers: {
    clearEditProviderCarrierApplicationStatus: (state) => {
      state.editProviderApplicationStatusLoading = false;
      state.editProviderApplicationStatusStatus = 'IDLE';
      state.editProviderApplicationStatusData = {};
      state.editProviderApplicationStatusError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProviderCarrierApplicationStatusRequest.pending, (state, action) => {
        state.editProviderApplicationStatusLoading = true;
        state.editProviderApplicationStatusStatus = 'PENDING';
      })
      .addCase(editProviderCarrierApplicationStatusRequest.fulfilled, (state, action) => {
        state.editProviderApplicationStatusData = action.payload;
        state.editProviderApplicationStatusLoading = false;
        state.editProviderApplicationStatusStatus = 'SUCCESS';
      })
      .addCase(editProviderCarrierApplicationStatusRequest.rejected, (state, action) => {
        state.editProviderApplicationStatusLoading = false;
        state.editProviderApplicationStatusStatus = 'FAILED';
        state.editProviderApplicationStatusError = action.payload;
      });
  },
});

export const { clearEditProviderCarrierApplicationStatus } = editProviderCarrierApplicationStatusSlice.actions;

export const editProviderCarrierApplicationStatusSelector = (state: RootState) =>
  state.EditProviderCarrierApplicationStatus;

export default editProviderCarrierApplicationStatusSlice.reducer;
