import { useState } from 'react';
import { claimStatus, statusReason } from '../../constants/patientConstants';
import { useAppSelector } from '../../hooks/storeHooks/hooks';
import { getSystemFacilitiesSelector } from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { Calendar } from '../atoms/Calendar';
import { InputField } from '../atoms/InputField';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { SelectInput } from '../atoms/SelectInput';
import { DatePickerField } from '../atoms/DatePickerField';
import { formatDatePickerDate } from '../../utils/commonFunctions';
import './tableSearch.css';
import { FaFileExcel } from 'react-icons/fa';
import moment from 'moment';
import { getSystemVisitStatusSelector } from '../../redux/slices/system/getSystemVisitStatusSlice';
import { convertingToDropdownArr } from '../../utils/commonFunctions';
import { CommonButton } from '../atoms/CommonButton';

export const VisitsTableAdvanceSearch = ({ onSubmit }: any) => {
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemVisitData } = useAppSelector(getSystemVisitStatusSelector);

  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDate = (name: string, date: any) => {
    setFilterObject((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  return (
    <>
      <div className="table-content__adv-search">
        <form>
          <div className="table-content__adv-search-content">
            <div className="grid md:grid-cols-6 gap-4">
              <InputField
                name="visitId"
                label="Visit ID"
                onChange={onChangeFilter}
                value={filterObject?.visitId ?? ''}
              />
              {/* <Calendar
                label="Visit Date From"
                onSelectedDateChanged={(date) => onChangeDate('visitDateFrom', date)}
                value={filterObject?.visitDateFrom ?? ''}
              /> */}
              <DatePickerField
                label="Visit Date From"
                selectedDate={formatDatePickerDate(filterObject?.visitDateFrom)}
                onChange={(date: Date | null) => {
                  if (date) {
                    onChangeDate('visitDateFrom', date);
                  }
                }}
              />
              {/* <Calendar
                label="Visit Date To"
                onSelectedDateChanged={(date) => onChangeDate('visitDateTo', date)}
                value={filterObject?.visitDateTo ?? ''}
              /> */}
              <DatePickerField
                label="Visit Date To"
                selectedDate={formatDatePickerDate(filterObject?.visitDateTo)}
                onChange={(date: Date | null) => {
                  if (date) {
                    onChangeDate('visitDateTo', date);
                  }
                }}
              />
              {/* <Calendar
                label="Visit Created From"
                onSelectedDateChanged={(date) => onChangeDate('visitCreatedFrom', date)}
                value={filterObject?.visitCreatedFrom ?? ''}
              /> */}
              <DatePickerField
                label="Visit Created From"
                selectedDate={formatDatePickerDate(filterObject?.visitCreatedFrom)}
                onChange={(date: Date | null) => {
                  if (date) {
                    onChangeDate('visitCreatedFrom', date);
                  }
                }}
              />
              {/* <Calendar
                label="Visit Created To"
                onSelectedDateChanged={(date) => onChangeDate('visitCreatedTo', date)}
                value={filterObject?.visitCreatedTo ?? ''}
              /> */}
              <DatePickerField
                label="Visit Created To"
                selectedDate={formatDatePickerDate(filterObject?.visitCreatedTo)}
                onChange={(date: Date | null) => {
                  if (date) {
                    onChangeDate('visitCreatedTo', date);
                  }
                }}
              />
              <InputField name="name" label="Patient Name" onChange={onChangeFilter} value={filterObject?.name ?? ''} />
            </div>
            <div className="grid md:grid-cols-6 gap-4 mb-4 mt-4 items-center">
              <SelectInput
                options={convertingToDropdownArr(systemVisitData)}
                defaultPlaceholder="All Status"
                name="visitStatusId"
                onChange={onChangeFilter}
                value={filterObject?.visitStatusId ?? ''}
              />
              <SelectInput
                options={statusReason}
                defaultPlaceholder="Select Reason"
                name="reason"
                onChange={onChangeFilter}
                value={filterObject?.reason ?? ''}
              />
              <SelectInput
                defaultPlaceholder="Select Carrier"
                name="carrierId"
                onChange={onChangeFilter}
                value={filterObject?.carrierId ?? ''}
              />
              <SelectInput
                options={systemProvidersProviderData}
                defaultPlaceholder="Select Provider/PA"
                name="providerId"
                onChange={onChangeFilter}
                value={filterObject?.providerId ?? ''}
              />
              <SelectInput
                options={systemFacilityData}
                defaultPlaceholder="Select Facility"
                name="facilityId"
                onChange={onChangeFilter}
                value={filterObject?.facilityId ?? ''}
              />
              <div className="flex gap-2 justify-end pt-2">
                <PrimaryButton
                  type="button"
                  label="Submit"
                  style={{ height: '40px', maxWidth: '120px' }}
                  onClick={() => onSubmit(filterObject)}
                />
                <CommonButton
                  label="Reset"
                  buttonType="secondary"
                  // icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                  style={{ height: '40px', maxWidth: '100px' }}
                  onClick={() => {
                    setFilterObject({});
                    onSubmit({});
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
