import { CommonButton } from '../../atoms/CommonButton';
import { FeeList } from './FeeList';
import { RightSheetForm } from './RightSheetForm';

export const CPTEditSheet = ({ onSaveEdit, onChange, codeData, mode, selectedId, errorObject, modifiers }: any) => {
  return (
    <>
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <CommonButton type="button" label={'Update'} buttonType="secondary" onClick={onSaveEdit} />
        </div>
      </div>
      <RightSheetForm />
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <div style={{ marginLeft: '5px' }}>Fee Amount</div>
        <div>
          <CommonButton type="button" label={'Add New'} buttonType="secondary" onClick={onSaveEdit} />
        </div>
      </div>
      <FeeList />
    </>
  );
};
