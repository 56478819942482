import { useState } from 'react';
import { DenialReportsInterface } from '../../../interfaces/userInterface';
import { CommonButton } from '../../atoms/CommonButton';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { SelectInput } from '../../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

export const DenialReportHeader: React.FC<DenialReportsInterface> = ({
  onChange,
  onSubmit,
  onExport,
  reportFilters,
  denialCodes,
  remarkCodes,
  providers,
  insurances,
}) => {
  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    e.preventDefault();
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <>
      <>
        <div className="grid md:grid-cols-5 gap-4 mt-6">
          <SelectInput
            label=""
            options={denialCodes}
            enableDefaultPlaceholder={false}
            name="denialCodes"
            onChange={onChange}
            defaultValue={reportFilters?.denialCode}
          />
          <SelectInput
            label=""
            options={remarkCodes}
            enableDefaultPlaceholder={false}
            name="remarkCodes"
            onChange={onChange}
            defaultValue={reportFilters?.remarkCode}
          />

          <SelectInput
            label=""
            options={providers}
            enableDefaultPlaceholder={true}
            name="remarkCodes"
            onChange={onChange}
            defaultValue={reportFilters?.provider}
          />

          <SelectInput
            label=""
            options={insurances}
            enableDefaultPlaceholder={true}
            name="insurances"
            onChange={onChange}
            defaultValue={reportFilters?.insurance}
          />

          <div className="grid md:grid-cols-2 gap-4 mt-auto mb-auto">
            <PrimaryButton
              type="button"
              label="Submit"
              style={{ height: '40px', maxWidth: '120px' }}
              onClick={() => onSubmit(filterObject)}
            />
            <PrimaryButton
              label="Export to Excel"
              icon={<FaFileExcel style={{ fontSize: '18px' }} />}
              style={{ height: '40px', maxWidth: '100px' }}
            />
          </div>
        </div>
      </>
    </>
  );
};
