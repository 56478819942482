import { Button, Modal } from 'flowbite-react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { useEffect, useState } from 'react';
import { CommonAlert } from '../../atoms/Alert';
import { InputField } from '../../atoms/InputField';
import { CommonButton } from '../../atoms/CommonButton';
import { TableContent } from '../../table/TableContent';
import {
  addVisitICDCodeRequest,
  addVisitICDCodeSelector,
  clearAddVisitICDCodeResponse,
} from '../../../redux/slices/patient/addVisitICDCodeSlice';
import { getICDCodesRequest, getICDCodesSelector } from '../../../redux/slices/system/getICDCodesSlice';
import { Pagination } from '../../pagination/Pagination';
import { Spinner } from '../../atoms/Spinner';

const PAGE_SIZE = 5;

const icdColumns = [
  {
    title: 'ICD Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'ICD Name',
    dataIndex: 'desc',
    key: 'desc',
  },
  {
    title: 'ICD Version',
    dataIndex: 'version',
    key: 'version',
  },
];

export const ClaimICDCodeSearchModal = ({ icdModalOpen, setOpenICDModal, handleAddedICDRow }: any) => {
  const dispatch = useAppDispatch();
  const { addVisitICDCodeStatus, addVisitICDCodeError } = useAppSelector(addVisitICDCodeSelector);
  const { icdCodesStatus, icdCodesData } = useAppSelector(getICDCodesSelector);

  const [formData, setFormData] = useState<any>({});
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);
  const [icdCodesDataSource, setIcdCodeDataSource] = useState<any>([]);

  useEffect(() => {
    setFetchDataLoading(true);
    dispatch(
      getICDCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: currentPage, size: PAGE_SIZE })
    );
  }, []);

  useEffect(() => {
    setFetchDataLoading(false);
    if (icdCodesStatus === 'SUCCESS') {
      setCurrentPage(icdCodesData?.currentPage);
      setTotalPages(icdCodesData?.totalPages);
      setTotalItems(icdCodesData?.totalItems);
      setIcdCodeDataSource(icdCodesData?.items);
    }
  }, [icdCodesStatus]);

  const handleFieldValueChange = (e: any) => {
    // setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));

    const { name, value } = e.target;

    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (
      (!updatedFormData?.code || updatedFormData?.code?.trim() === '') &&
      (!updatedFormData?.desc || updatedFormData?.desc?.trim() === '')
    ) {
      setIsSearchEnabled(false);
    } else {
      setIsSearchEnabled(true);
    }
  };

  const handleReset = () => {
    setFormData({});
    setIsSearchEnabled(false);
    dispatch(getICDCodesRequest({ code: '', desc: '', page: 0, size: PAGE_SIZE }));
  };

  const handleSubmit = () => {
    dispatch(
      getICDCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: currentPage, size: PAGE_SIZE })
    );
  };

  const onSearchPageChange = (page: any) => {
    setCurrentPage(page - 1);
    dispatch(
      getICDCodesRequest({ code: formData?.code ?? '', desc: formData?.desc ?? '', page: page - 1, size: PAGE_SIZE })
    );
  };

  const handleOnClose = () => {
    setOpenICDModal(false);
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleAddRow = (event: any, rowIndex: any) => {
    event.preventDefault();
    handleAddedICDRow(icdCodesDataSource[rowIndex]);
    handleOnClose();
  };

  //   console.log('=== icdCodesDataSource ===', icdCodesDataSource);

  return (
    <Modal show={icdModalOpen} onClose={handleOnClose}>
      <Modal.Header>Search ICD Code</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <InputField label="Code" name="code" onChange={handleFieldValueChange} value={formData?.code ?? ''} />
          <InputField onChange={handleFieldValueChange} name="desc" label="Description" value={formData?.desc ?? ''} />
        </div>
        <div className="grid md:grid-cols-2 gap-5 mt-6">
          <div className="col-span-1"></div>
          <div className="col-span-1 flex justify-end space-x-2">
            <CommonButton type="button" label={'Reset'} buttonType="secondary" onClick={handleReset} />
            <CommonButton
              type="button"
              label={'Search'}
              buttonType="primary"
              onClick={handleSubmit}
              disabled={!isSearchEnabled}
            />
          </div>
        </div>
        <div className="grid md:grid-cols-1 gap-5 mt-6 pb-1">
          {fetchDataLoading ? (
            <Spinner />
          ) : (
            <>
              {icdCodesDataSource && icdCodesDataSource?.length > 0 && (
                <>
                  <TableContent
                    enableActions={true}
                    columns={icdColumns}
                    dataSource={icdCodesDataSource}
                    enableAdd={true}
                    onHandleAdd={handleAddRow}
                  />
                  <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    totalPages={totalPages}
                    onPageChange={onSearchPageChange}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
