import { Alert } from 'flowbite-react';
import { AlertPropsInterface } from '../../interfaces/atomInterfaces';

export const CommonAlert: React.FC<AlertPropsInterface> = ({
  color = '',
  message = '',
  onClose,
  divClassName = 'flex justify-center items-center',
  alertClassName = 'w-3/12',
  icon,
}) => {
  return (
    <div className={divClassName}>
      <Alert className={alertClassName} color={color} onDismiss={() => onClose()} icon={icon}>
        {message}
      </Alert>
    </div>
  );
};
