import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  UpdateBatchPaymentInterface,
  UpdateBatchPaymentPropsInterface,
} from '../../../../interfaces/paymentInterfaces';
import { updatebatchPaymentAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: UpdateBatchPaymentPropsInterface = {
  editBatchPaymentLoading: false,
  editBatchPaymentData: {},
  editBatchPaymentStatus: 'IDLE',
  editBatchPaymentError: null,
};

export const updatebatchPaymentRequest: any = createAsyncThunk(
  'batch-payment/edit',
  async (payment: UpdateBatchPaymentInterface, thunkAPI: any) => {
    try {
      // const response: any = await updatebatchPaymentAPIRequest(payment);
      // return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateBatchPaymentSlice = createSlice({
  name: 'updateBatchPayment',
  initialState,
  reducers: {
    clearUpdateBatchPaymentResponse: (state) => {
      state.editBatchPaymentLoading = false;
      state.editBatchPaymentStatus = 'IDLE';
      state.editBatchPaymentData = {};
      state.editBatchPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatebatchPaymentRequest.pending, (state, action) => {
        state.editBatchPaymentLoading = true;
        state.editBatchPaymentStatus = 'PENDING';
      })
      .addCase(updatebatchPaymentRequest.fulfilled, (state, action) => {
        state.editBatchPaymentData = action.payload;
        state.editBatchPaymentLoading = false;
        state.editBatchPaymentStatus = 'SUCCESS';
      })
      .addCase(updatebatchPaymentRequest.rejected, (state, action) => {
        state.editBatchPaymentLoading = false;
        state.editBatchPaymentStatus = 'FAILED';
        state.editBatchPaymentError = action.payload;
      });
  },
});

export const { clearUpdateBatchPaymentResponse } = updateBatchPaymentSlice.actions;

export const updateBatchPaymentSelector = (state: RootState) => state.UpdateBatchPayment;

export default updateBatchPaymentSlice.reducer;
