import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

// import {
//   getProviderByIdAPIRequest,
//   getProvidersAPIRequest,
// } from "../../../api/patient/patient";
//import { getProviderCarriersByIdAPIRequest } from "../../../api/patient/patientCarriers";
import {
  ProviderCarriersFetchByIdInitialPropsInterface,
  ProviderCarriersGetByIdInterface,
} from '../../../interfaces/providersInterface';
// import { getProviderCarriersByIdAPIRequest } from "../../../api/provider/providerCarriers";
import { getProviderCarriersByIdAPIRequest } from '../../../api/provider/providerCarrier';

const initialState: ProviderCarriersFetchByIdInitialPropsInterface = {
  fetchProviderCarriersByIdLoading: false,
  fetchProviderCarriersByIdData: {},
  fetchProviderCarriersByIdStatus: 'IDLE',
  fetchProviderCarriersByIdError: null,
};

export const getProviderCarriersByIdRequest: any = createAsyncThunk(
  'patients/get/carriers/id',
  async (searchParams: ProviderCarriersGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getProviderCarriersByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchProviderCarriersByIdSlice = createSlice({
  name: 'fetchProviderCarriersById',
  initialState,
  reducers: {
    clearFetchProviderCarriersByIdResponse: (state) => {
      state.fetchProviderCarriersByIdLoading = false;
      state.fetchProviderCarriersByIdData = {};
      state.fetchProviderCarriersByIdStatus = 'IDLE';
      state.fetchProviderCarriersByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderCarriersByIdRequest.pending, (state, action) => {
        state.fetchProviderCarriersByIdLoading = true;
        state.fetchProviderCarriersByIdStatus = 'PENDING';
      })
      .addCase(getProviderCarriersByIdRequest.fulfilled, (state, action) => {
        state.fetchProviderCarriersByIdLoading = false;
        state.fetchProviderCarriersByIdStatus = 'SUCCESS';
        state.fetchProviderCarriersByIdData = action.payload;
      })
      .addCase(getProviderCarriersByIdRequest.rejected, (state, action) => {
        state.fetchProviderCarriersByIdLoading = false;
        state.fetchProviderCarriersByIdError = action.payload;
        state.fetchProviderCarriersByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchProviderCarriersByIdResponse } = fetchProviderCarriersByIdSlice.actions;

export const getProviderCarriersByIdSelector = (state: RootState) => state.FetchProviderCarriersById;

export default fetchProviderCarriersByIdSlice.reducer;
