import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ProviderFetchInitialInterface, ProviderFetchInterface } from '../../../interfaces/providersInterface';
import { getProvidersAPIRequest } from '../../../api/provider/provider';

const initialState: ProviderFetchInitialInterface = {
  loading: false,
  providerData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  error: null,
  status: 'IDLE',
};

export const getProvidersRequest: any = createAsyncThunk(
  'providers/get',
  async (searchParams: ProviderFetchInterface, thunkAPI: any) => {
    try {
      const response: any = await getProvidersAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getProvidersSlice = createSlice({
  name: 'fetchProviders',
  initialState,
  reducers: {
    clearFetchProvidersResponse: (state) => {
      state.loading = false;
      state.status = 'IDLE';
      state.providerData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersRequest.pending, (state, action) => {
        state.loading = true;
        state.status = 'PENDING';
      })
      .addCase(getProvidersRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.providerData = action.payload;
      })
      .addCase(getProvidersRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'FAILED';
      });
  },
});

export const { clearFetchProvidersResponse } = getProvidersSlice.actions;

export const getProvidersSelector = (state: RootState) => state.GetProviders;

export default getProvidersSlice.reducer;
