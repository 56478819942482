import { usStates } from '../../../constants/systemConstants';
import { FacilityFormInterface } from '../../../interfaces/facilitiesInterface';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';

export const FacilityForm: React.FC<FacilityFormInterface> = ({ onChange, formData, errorObject }) => {
  return (
    <>
      <div className="grid md:grid-cols-1 mt-6">
        <InputField
          label="Name"
          name="name"
          onChange={onChange}
          defaultValue={formData?.name ?? undefined}
          error={errorObject && errorObject?.name}
          errorMessage={errorObject && errorObject?.name}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Address 1"
          name="address1"
          onChange={onChange}
          defaultValue={formData?.address1 ?? undefined}
          error={errorObject && errorObject?.address1}
          errorMessage={errorObject && errorObject?.address1}
          required={true}
        />
        <InputField
          label="Address 2"
          name="address2"
          onChange={onChange}
          defaultValue={formData?.address2 ?? undefined}
        />
        <InputField
          label="City"
          name="city"
          onChange={onChange}
          defaultValue={formData?.city ?? undefined}
          error={errorObject && errorObject?.city}
          errorMessage={errorObject && errorObject?.city}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <SelectInput
          label="State"
          options={usStates}
          enableDefaultPlaceholder={true}
          name="state"
          onChange={onChange}
          defaultValue={formData?.state ?? undefined}
          error={errorObject && errorObject?.state}
          errorMessage={errorObject && errorObject?.state}
          required={true}
        />
        <InputField
          label="Zip Code"
          name="zipCode"
          onChange={onChange}
          defaultValue={formData?.zipCode ?? undefined}
          error={errorObject && errorObject?.zipCode}
          errorMessage={errorObject && errorObject?.zipCode}
          required={true}
          tooltip="Character length must be 5"
        />
        <InputField
          label="Extension"
          name="extension"
          onChange={onChange}
          defaultValue={formData?.extension ?? undefined}
          error={errorObject && errorObject?.extension}
          errorMessage={errorObject && errorObject?.extension}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="NPI"
          name="npi"
          onChange={onChange}
          defaultValue={formData?.npi ?? undefined}
          error={errorObject && errorObject?.npi}
          errorMessage={errorObject && errorObject?.npi}
          required={true}
          tooltip="Character length must be 10"
        />
        <InputField
          label="TIN"
          name="tin"
          onChange={onChange}
          defaultValue={formData?.tin ?? undefined}
          error={errorObject && errorObject?.tin}
          errorMessage={errorObject && errorObject?.tin}
          required={false}
          tooltip="Character length must be 9"
        />
        <InputField
          label="PTAN"
          name="ptan"
          onChange={onChange}
          defaultValue={formData?.ptan ?? undefined}
          error={errorObject && errorObject?.ptan}
          errorMessage={errorObject && errorObject?.ptan}
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Phone"
          name="phone"
          onChange={onChange}
          defaultValue={formData?.phone ?? undefined}
          error={errorObject && errorObject?.phone}
          errorMessage={errorObject && errorObject?.phone}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Fax"
          name="fax"
          onChange={onChange}
          defaultValue={formData?.fax ?? undefined}
          error={errorObject && errorObject?.fax}
          errorMessage={errorObject && errorObject?.fax}
          tooltip="10-digit fax number required"
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <InputField
          label="Email"
          name="email"
          onChange={onChange}
          defaultValue={formData?.email ?? undefined}
          error={errorObject && errorObject?.email}
          errorMessage={errorObject && errorObject?.email}
        />
        <InputField
          label="Website"
          name="webSite"
          onChange={onChange}
          defaultValue={formData?.webSite ?? undefined}
          error={errorObject && errorObject?.webSite}
          errorMessage={errorObject && errorObject?.webSite}
        />
      </div>
    </>
  );
};
