import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { getICDCodesRequest } from '../../../../redux/slices/system/getICDCodesSlice';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import AddCPTModal from './AddCPTModal';
import {
  clearDeleteVisitCPTCodeResponse,
  deleteVisitCPTCodeRequest,
  deleteVisitCPTCodeSelector,
} from '../../../../redux/slices/patient/deleteVisitCPTCodeSlice';
import { ConfirmModal } from './ConfirmModal';
import {
  clearFetchVisitCPTByIdResponse,
  getVisitCPTByIdRequest,
  getVisitCPTByIdSelector,
} from '../../../../redux/slices/billing/getVisitCPTByIdSlice';
import { MessageModal } from './MessageModal';

const PAGE_SIZE = 10;

const procedureCodesColumns = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (text: string) => `$${text}`,
  },
  {
    title: 'Modifier',
    dataIndex: 'modifier',
    key: 'modifier',
    render: (text: string, record: any) => {
      return (
        <ul>
          {record?.modifier?.map((item: any, key: any) => {
            return (
              <li>
                Mod {key + 1} : {item}
              </li>
            );
          })}
        </ul>
      );
    },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: 'ICD',
    dataIndex: 'icd',
    key: 'icd',
  },
];

const procedureCodes = [
  {
    code: '99309',
    description: 'Skilled Nursing Facility - Subsequent Visit - Moderate Complexity',
    charge: '$145.00',
    modifier: '1',
    unit: '',
    icd: 'A41.9, M62.81, N39.0, R26.81, R26.89, R53.1, Z91.81,',
  },
  {
    code: '99309',
    description: 'Skilled Nursing Facility - Subsequent Visit - Moderate Complexity',
    charge: '$145.00',
    modifier: '1',
    unit: '',
    icd: 'A41.9, M62.81, N39.0, R26.81, R26.89, R53.1, Z91.81,',
  },
  {
    code: '99309',
    description: 'Skilled Nursing Facility - Subsequent Visit - Moderate Complexity',
    charge: '$145.00',
    modifier: '1',
    unit: '',
    icd: 'A41.9, M62.81, N39.0, R26.81, R26.89, R53.1, Z91.81,',
  },
];

const customArr = [
  {
    id: 1,
    code: 'Test 1',
    value: null,
  },
  {
    id: 2,
    code: 'Test 2',
    value: null,
  },
  {
    id: 3,
    code: 'Test 3',
    value: null,
  },
  {
    id: 4,
    code: 'Test 4',
    value: null,
  },
];

export const ProcedureCode = ({
  onEditProcedureCode,
  cptDataSource,
  icdCodesDataSource,
  visitId,
  cptCodesDataSource,
  modifiersDataSource,
  getCPTCodeList,
  icdDataSource,
  handleRemoveCPTCodeDataSource,
  childMode,
}: any) => {
  const dispatch = useAppDispatch();
  const { deleteVisitCPTCodeStatus, deleteVisitCPTCodeError } = useAppSelector(deleteVisitCPTCodeSelector);
  const { visitCPTData, visitCPTStatus, visitCPTError } = useAppSelector(getVisitCPTByIdSelector);

  const [icdCodesArr, setICDCodesArr] = useState<any>([]);
  const [visibleAddCPT, setVisibleAddCPT] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedCPT, setSelectedCPT] = useState<number>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [mode, setMode] = useState<any>('CREATE');
  const [editData, setEditData] = useState<any>({});

  const [openMessage, setOpenMessage] = useState<boolean>(false);

  // console.log('=== icdDataSource ===', icdDataSource);

  useEffect(() => {
    if (visitCPTStatus === 'SUCCESS') {
      // console.log('=== visitCPTData ===', visitCPTData);
      handleICDCodes(visitCPTData?.icdCodes);
      setEditData(visitCPTData);
      // setICDCodesArr();
      setVisibleAddCPT(true);
    } else if (visitCPTStatus === 'FAILED') {
      setAlertObj({
        color: 'error',
        message: visitCPTError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchVisitCPTByIdResponse());
  }, [visitCPTStatus]);

  useEffect(() => {
    if (visibleAddCPT) {
      if (icdDataSource?.length > 0) {
        if (mode === 'CREATE') {
          handleICDCodes(icdDataSource);
        }
      } else {
        onProcedureCode();
      }
    }
  }, [visibleAddCPT, icdDataSource]);

  useEffect(() => {
    if (deleteVisitCPTCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Deleted Successfully!',
        error: false,
      });
      setVisibleAlert(true);
      getCPTCodeList(visitId);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
        setOpenConfirm(false);
      }, 3000);
    } else if (deleteVisitCPTCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'error',
        message: deleteVisitCPTCodeError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearDeleteVisitCPTCodeResponse());
  }, [deleteVisitCPTCodeStatus]);

  const handleICDCodes = (arr: any) => {
    // console.log('=== arr ==', arr);
    let newArr: any = [];
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      let obj: any = {
        code: element?.code,
        id: element?.id,
        checked: true,
      };
      if (mode === 'EDIT') {
        obj['value'] = element?.value;
      }
      newArr[newArr.length] = obj;
    }
    // console.log('=== final arr ==', newArr);
    setICDCodesArr(newArr);
  };

  const onProcedureCode = () => {
    dispatch(getICDCodesRequest({ page: currentPage, size: PAGE_SIZE }));
  };

  const getCPTById = async (cptId: number) => {
    dispatch(getVisitCPTByIdRequest({ visitId, cptId }));
  };

  const onCloseAddCPT = (data: boolean) => {
    setVisibleAddCPT(false);
  };

  const editCPT = (event: any, rowIndex: any) => {
    event?.preventDefault();
    setMode('EDIT');
    const dataSet = cptDataSource[rowIndex];
    setSelectedCPT(dataSet?.id);
    getCPTById(dataSet?.id);
  };

  const deleteCPT = (event: any, rowIndex: any) => {
    event?.preventDefault();
    const dataSet = cptDataSource[rowIndex];
    setSelectedCPT(dataSet?.id);
    setOpenConfirm(true);
  };

  const confirmDelete = () => {
    dispatch(deleteVisitCPTCodeRequest({ cpt: selectedCPT, visitId: visitId }));
  };

  const onCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const onCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleAddCPTModal = () => {
    setEditData({});
    setMode('CREATE');
    if (icdDataSource?.length > 0) {
      setVisibleAddCPT(true);
    } else {
      setOpenMessage(true);
    }
  };

  return (
    <>
      <div
        style={{
          borderRadius: '5px',
          border: '1px solid',
          padding: '6px',
          background: 'rgb(13,200,222)',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <div style={{ marginLeft: '5px' }}>CPT/Procedure Codes</div>
        {childMode === 'EDIT' && (
          <div>
            <CommonButton type="button" label={'Add New'} buttonType="secondary" onClick={handleAddCPTModal} />
          </div>
        )}
      </div>
      <div className="grid md:grid-cols-1 gap-5">
        <TableContent
          enableActions={childMode === 'EDIT' ? true : false}
          columns={procedureCodesColumns}
          dataSource={cptDataSource}
          enableDelete={true}
          enableEdit={true}
          onEdit={editCPT}
          onDelete={deleteCPT}
        />
      </div>
      {visibleAddCPT && (
        <AddCPTModal
          mode={mode}
          editData={editData}
          openAddCPT={visibleAddCPT}
          setOpenAddCPT={onCloseAddCPT}
          icdCodesDataSource={icdCodesArr}
          visitId={visitId}
          cptCodesDataSource={cptCodesDataSource}
          modifiersDataSource={modifiersDataSource}
          getCPTCodeList={getCPTCodeList}
          icdDataSource={icdDataSource}
          handleRemoveCPTCodeDataSource={handleRemoveCPTCodeDataSource}
          selectedCPTId={selectedCPT}
        />
      )}
      {openConfirm && (
        <ConfirmModal
          openConfirm={openConfirm}
          setOpenConfirm={onCloseConfirm}
          title="Delete Encounters/CPTs"
          content="Are you sure you want to delete this Encounters/CPTs?"
          submitButtonTitle="Yes"
          cancelButtonTitle="No"
          handleSubmit={confirmDelete}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
        />
      )}
      {openMessage && (
        <MessageModal
          content="Please add ICD/Diagnosis Codes first !"
          submitButtonTitle="OK"
          openMessage={openMessage}
          setOpenMessage={onCloseMessage}
        />
      )}
    </>
  );
};
