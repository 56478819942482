import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { CreateClaimsInitialInterface, PatientCaseOptionsInterface } from '../../../interfaces/patientInterface';
import { createClaimsAPIRequest } from '../../../api/patient/patientClaim';

const initialState: CreateClaimsInitialInterface = {
  createClaimsLoading: false,
  createClaimsPatientData: [],
  createClaimsError: null,
  createClaimsStatus: 'IDLE',
};

export const createClaimsRequest: any = createAsyncThunk(
  'create/claims',
  async (patientData: PatientCaseOptionsInterface, thunkAPI: any) => {
    try {
      const response: any = await createClaimsAPIRequest(patientData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const createClaimsSlice = createSlice({
  name: 'createClaims',
  initialState,
  reducers: {
    clearCreateClaimsResponse: (state) => {
      state.createClaimsLoading = false;
      state.createClaimsStatus = 'IDLE';
      state.createClaimsPatientData = [];
      state.createClaimsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClaimsRequest.pending, (state, action) => {
        state.createClaimsLoading = true;
        state.createClaimsStatus = 'PENDING';
      })
      .addCase(createClaimsRequest.fulfilled, (state, action) => {
        state.createClaimsLoading = false;
        state.createClaimsStatus = 'SUCCESS';
        state.createClaimsPatientData = action.payload;
      })
      .addCase(createClaimsRequest.rejected, (state, action) => {
        state.createClaimsLoading = false;
        state.createClaimsError = action.payload;
        state.createClaimsStatus = 'FAILED';
      });
  },
});

export const { clearCreateClaimsResponse } = createClaimsSlice.actions;

export const createClaimsSelector = (state: RootState) => state.CreateClaims;

export default createClaimsSlice.reducer;
