import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addPatientAPIRequest } from '../../../api/patient/patient';
import { AddPatientInitialInterface, PatientAddInterface } from '../../../interfaces/patientInterface';

const initialState: AddPatientInitialInterface = {
  addPatientLoading: false,
  addPatientData: {},
  addPatientError: null,
  success: false,
  addPatientStatus: 'IDLE',
};

export const addPatientRequest: any = createAsyncThunk(
  'patient/add',
  async (patient: PatientAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addPatientAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPatientSlice = createSlice({
  name: 'addPatient',
  initialState,
  reducers: {
    clearAddPatientResponse: (state) => {
      state.addPatientLoading = false;
      state.addPatientStatus = 'IDLE';
      state.addPatientData = {};
      state.addPatientError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPatientRequest.pending, (state, action) => {
        state.addPatientLoading = true;
        state.addPatientStatus = 'PENDING';
      })
      .addCase(addPatientRequest.fulfilled, (state, action) => {
        state.addPatientData = action.payload;
        state.addPatientStatus = 'SUCCESS';
        state.addPatientLoading = false;
      })
      .addCase(addPatientRequest.rejected, (state, action) => {
        state.addPatientLoading = false;
        state.addPatientError = action.payload;
        state.addPatientStatus = 'FAILED';
      });
  },
});

export const { clearAddPatientResponse } = addPatientSlice.actions;

export const addPatientSelector = (state: RootState) => state.AddPatient;

export default addPatientSlice.reducer;
