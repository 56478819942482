import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchModifierListInitialInterface } from '../../../interfaces/systemInterfaces';
import { getModifierListAPIRequest } from '../../../api/system/code';

const initialState: FetchModifierListInitialInterface = {
  modifierListLoading: false,
  modifierListData: [],
  modifierListError: null,
  modifierListStatus: 'IDLE',
};

export const getModifierListRequest: any = createAsyncThunk('codes/modifier/get/list', async (thunkAPI: any) => {
  try {
    const response: any = await getModifierListAPIRequest();
    return response;
  } catch (error: any) {
    if (!error?.response) {
      throw error;
    }
    return thunkAPI.rejectWithValue(error?.response.data);
  }
});

const getModifierListSlice = createSlice({
  name: 'fetchModifierList',
  initialState,
  reducers: {
    clearFetchModifierListResponse: (state) => {
      state.modifierListLoading = false;
      state.modifierListStatus = 'IDLE';
      state.modifierListData = [];
      state.modifierListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModifierListRequest.pending, (state, action) => {
        state.modifierListLoading = true;
        state.modifierListStatus = 'PENDING';
      })
      .addCase(getModifierListRequest.fulfilled, (state, action) => {
        state.modifierListLoading = false;
        state.modifierListData = action.payload;
        state.modifierListStatus = 'SUCCESS';
      })
      .addCase(getModifierListRequest.rejected, (state, action) => {
        state.modifierListLoading = false;
        state.modifierListError = action.payload;
        state.modifierListStatus = 'FAILED';
      });
  },
});

export const { clearFetchModifierListResponse } = getModifierListSlice.actions;

export const getModifierListSelector = (state: RootState) => state.GetModifierList;

export default getModifierListSlice.reducer;
