import { AddInsuranceInterface } from '../../interfaces/systemInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getSystemInsuranceAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/insurance`, 'get', {
    data: {},
  });
};

export const addInsuranceAPIRequest = async (insuranceData: AddInsuranceInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/insurance/`, 'post', {
    data: { ...insuranceData },
  });
};
