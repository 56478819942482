export const userRoles = [
  { id: 1, name: 'Admin' },
  { id: 2, name: 'Manager' },
  { id: 3, name: 'AR Caller' },
  { id: 4, name: 'Biller' },
  { id: 5, name: 'Office-Admin' },
  { id: 6, name: 'Management' },
  { id: 7, name: 'Credentialing Analyst' },
  { id: 8, name: 'Reporting Group' },
  { id: 9, name: 'Super Admin' },
  { id: 10, name: 'Scribe' },
  { id: 11, name: 'Customer Support' },
  { id: 12, name: 'Provider Access' },
  { id: 13, name: 'Provider Visit Access' },
  { id: 14, name: 'Provider Report Access' },
  { id: 15, name: 'Facility User' },
];

export const userStatus = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'DELETE', name: 'Delete' },
];

export const employmentStatus = [
  { id: 'NOT_EMPLOYED', name: 'Not employed' },
  { id: 'FULL_TIME', name: 'Full time' },
  { id: 'PART_TIME', name: 'Part time' },
  { id: 'RETIRED', name: 'Retired' },
  { id: 'UNKNOWN', name: 'Unknown' },
];

export const providerDegree = [
  { id: 1, name: 'APN' },
  { id: 2, name: 'BS' },
  { id: 3, name: 'DO' },
  { id: 4, name: 'LCP' },
  { id: 5, name: 'LCPC' },
  { id: 6, name: 'LCSW' },
  { id: 7, name: 'LMLP' },
  { id: 8, name: 'LP-T' },
  { id: 9, name: 'LPC' },
  { id: 10, name: 'LPC,LCAC' },
  { id: 11, name: 'LSCSW' },
  { id: 12, name: 'MA' },
  { id: 13, name: 'MC' },
  { id: 14, name: 'MD' },
  { id: 15, name: 'MPAS' },
  { id: 16, name: 'MS' },
  { id: 17, name: 'MSN' },
  { id: 18, name: 'MSW' },
];

export const sexStatus = [
  { id: 'MALE', name: 'Male' },
  { id: 'FEMALE', name: 'Female' },
];
