import { useEffect, useState } from 'react';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { TableContent } from '../../../table/TableContent';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';
import { Pagination } from '../../../pagination/Pagination';

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import moment from 'moment';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
  },
];

const dataSource = [
  {
    name: 'Fairmont Care',
    address: '5061 N Pulaski Rd',
    city: 'Chicago',
    state: 'IL',
    zip: '60630',
  },
  {
    name: 'Oak Brook Care',
    address: '2013 Midwest Road',
    city: 'Oak Brook',
    state: 'IL',
    zip: '60523',
  },
  {
    name: 'Symphony of Bronzeville Park',
    address: '3400 S Indiana Ave',
    city: 'Chicago',
    state: 'IL',
    zip: '60616',
  },
  {
    name: 'Community Care Center Nursing & Rehab',
    address: '4314 S Wabash Ave',
    city: 'Chicago',
    state: 'IL',
    zip: '60653',
  },
];

export const InsuranceFacilities = ({ mode }: any) => {
  return (
    <>
      <TableContent columns={columns} dataSource={dataSource} />
    </>
  );
};
