import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addPatientAPIRequest } from '../../../api/patient/patient';
import { PatientAddressEditPropsInterface, PatientAddressUpdateInterface } from '../../../interfaces/patientInterface';
import { updatePAtientAddressAPIRequest } from '../../../api/patient/patientAddress';

const initialState: PatientAddressEditPropsInterface = {
  editPatientAddressLoading: false,
  editPatientAddressData: {},
  editPatientAddressError: null,
  editPatientAddressStatus: 'IDLE',
};

export const editPatientAddressRequest: any = createAsyncThunk(
  'patient/address/edit',
  async (patient: PatientAddressUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updatePAtientAddressAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editPatientAddressSlice = createSlice({
  name: 'editPatientAddress',
  initialState,
  reducers: {
    clearEditPatientAddressResponse: (state) => {
      state.editPatientAddressLoading = false;
      state.editPatientAddressStatus = 'IDLE';
      state.editPatientAddressData = {};
      state.editPatientAddressError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPatientAddressRequest.pending, (state, action) => {
        state.editPatientAddressLoading = true;
        state.editPatientAddressStatus = 'PENDING';
      })
      .addCase(editPatientAddressRequest.fulfilled, (state, action) => {
        state.editPatientAddressData = action.payload;
        state.editPatientAddressStatus = 'SUCCESS';
        state.editPatientAddressLoading = false;
      })
      .addCase(editPatientAddressRequest.rejected, (state, action) => {
        state.editPatientAddressLoading = false;
        state.editPatientAddressError = action.payload;
        state.editPatientAddressStatus = 'FAILED';
      });
  },
});

export const { clearEditPatientAddressResponse } = editPatientAddressSlice.actions;

export const editPatientAddressSelector = (state: RootState) => state.EditPatientAddress;

export default editPatientAddressSlice.reducer;
