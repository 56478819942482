import { Modal } from 'flowbite-react';
import { TableContent } from '../../table/TableContent';
import { CommonButton } from '../../atoms/CommonButton';

const columns = [
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Patient',
    dataIndex: 'patientName',
    key: 'patientName',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Check #',
    dataIndex: 'check',
    key: 'check',
  },
];

export const BatchView = ({ openBatchView, setOpenBatchView, dataSource }: any) => {
  return (
    <Modal show={openBatchView} onClose={() => setOpenBatchView(false)}>
      <Modal.Header>Batch Payments</Modal.Header>
      <Modal.Body>
        <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
      </Modal.Body>
      <Modal.Footer>
        <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={() => setOpenBatchView(false)} />
      </Modal.Footer>
    </Modal>
  );
};
