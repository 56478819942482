import { useState } from 'react';
import userImage from '../../assets/images/user.png';
import './mainHeader.css';
import { AuthPropType, useAuth } from '../providers/AuthProvider';

export const MainHeader = () => {
  const { logout }: AuthPropType = useAuth();
  const [visibleDropDown, setVisibleDropdown] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark', !darkMode);
  };

  return (
    <>
      <div className="main-content-header">
        <div className="main-content-header__global-search">
          {/* <form>
            <label htmlFor="search" className="main-content-header__global-search-label">
              Search
            </label>
            <div className="main-content-header__global-search-content">
              <div className="main-content-header__global-search-icon">
                <svg
                  className="w-4 h-4 text-gray5 dark:text-gray5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="search"
                className="main-content-header__global-search-input"
                placeholder="Search"
                required
              />
              <button type="submit" className="main-content-header__global-search-btn">
                Search
              </button>
            </div>
          </form> */}
        </div>

        <div className="main-content-header__nav">
          <ul className="main-content-header__nav-list">
            <li className="p-0 m-0 main-content-header__nav-list-item">
              <button type="button" className="main-content-header__nav-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                  />
                </svg>
              </button>
            </li>
            <li className="p-0 m-0 main-content-header__nav-list-item">
              <div className="main-content-header__nav-user-menu">
                <button
                  type="button"
                  className="main-content-header__nav-user-menu-btn"
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                  onClick={() => setVisibleDropdown(!visibleDropDown)}
                >
                  <img className="rounded-full w-11 h-11" src={userImage} alt="User" />
                </button>

                {visibleDropDown && (
                  <div
                    className="main-content-header__nav-user-dropdown"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex={-1}
                  >
                    <div className="py-1" role="none">
                      <a
                        href="#"
                        className="main-content-header__nav-user-dropdown-item"
                        role="menuitem"
                        tabIndex={-1}
                        id="menu-item-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                        Account Settings
                      </a>
                      <a
                        // href="#"
                        className="main-content-header__nav-user-dropdown-item"
                        role="menuitem"
                        tabIndex={-1}
                        id="menu-item-1"
                        onClick={handleDarkMode}
                      >
                        {!darkMode ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                            />
                          </svg>
                        )}
                        {darkMode ? 'Light Mode' : 'Dark Mode'}
                      </a>
                      <form method="POST" action="#" role="none">
                        <button
                          type="submit"
                          className="main-content-header__nav-user-dropdown-item"
                          role="menuitem"
                          tabIndex={-1}
                          id="menu-item-3"
                          onClick={() => {
                            logout && logout();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                            />
                          </svg>
                          Sign Out
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
