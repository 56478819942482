import { InputField } from '../../components/atoms/InputField';
import { Toggle } from '../../components/atoms/Toggle';
import SimplibillLogo from '../../assets/images/simplibill-logo-white.svg';
import './login.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginValidation } from '../../utils/loginValidation';
import { useAppDispatch } from '../../redux/store';
import { authSelector, clearLoginResponse, loginUserRequest } from '../../redux/slices/auth/authSlice';
import { useAppSelector } from '../../hooks/storeHooks/hooks';
import { AuthPropType, useAuth } from '../../components/providers/AuthProvider';
import { CommonButton } from '../../components/atoms/CommonButton';
import { CommonAlert } from '../../components/atoms/Alert';
import { HiInformationCircle } from 'react-icons/hi';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemInsuranceRequest } from '../../redux/slices/system/getSystemInsuranceSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';

export const LoginPage = () => {
  const { login, user }: AuthPropType = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status, userInfo, error, loading } = useAppSelector(authSelector);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorObject, setErrorObject] = useState({});
  const [alertDetails, setAlertDetails] = useState({
    color: '',
    message: '',
  });
  const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    if (status === 'SUCCESS') {
      if (login) {
        login(userInfo);
      }
    } else if (status === 'FAILED') {
      setAlertDetails({ color: 'failure', message: error?.message });
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setAlertDetails({ color: '', message: '' });
      }, 3000);
      dispatch(clearLoginResponse());
    }
  }, [status, userInfo]);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
      fetchInsurance();
      fetchFacilities();
      fetchSystemProviders();
    } else {
      navigate('/');
    }
  }, [user]);

  const fetchFacilities = async () => {
    dispatch(getSystemFacilityRequest());
  };

  const fetchInsurance = async () => {
    dispatch(getSystemInsuranceRequest());
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleUsername = (e: any) => {
    setUsername(e.target.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    const validationResult = loginValidation({ username, password });
    setErrorObject(validationResult);

    if (Object.keys(validationResult).length > 0) {
      // If there are validation errors, do not proceed with login
      return;
    }

    // If there are no validation errors, proceed with login
    const credObj = { username, password };
    dispatch(loginUserRequest(credObj));
  };

  return (
    <>
      <div className="login-page">
        <div className="login-page__left">
          <img src={SimplibillLogo} alt="Simplibill logo" className="h-16" />
          {/* <h6 className="heading text-white">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim.
          </h6> */}
        </div>
        <div className="login-page__right">
          <form className="w-full">
            {alertVisible ? (
              <CommonAlert
                color={alertDetails?.color}
                message={alertDetails?.message}
                alertClassName="w-full mb-10"
                icon={HiInformationCircle}
              />
            ) : (
              <></>
            )}

            <h2 className="xl-heading font-bold">Login to Simplibill</h2>
            <div className="mt-16">
              <InputField
                label="Username"
                placeholder="Enter your username"
                onChange={handleUsername}
                required={true}
              />
            </div>
            <div className="mt-6">
              <InputField
                label="Password"
                placeholder="Enter your password"
                onChange={handlePassword}
                type="password"
                required={true}
              />
            </div>
            <div className="flex justify-between mt-6">
              <Toggle label="Remember me" />
              <a href="" className="link">
                Forgot password?
              </a>
            </div>
            <div className="mt-6">
              <CommonButton buttonType="primary" type="button" label="Login" onClick={handleLogin} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
