import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import {
  ProviderLicenseFetchByIdInitialPropsInterface,
  ProviderLicenseGetByIdInterface,
} from "../../../interfaces/providersInterface";
import { getProviderLicenseByIdAPIRequest } from "../../../api/provider/providerLicense";

const initialState: ProviderLicenseFetchByIdInitialPropsInterface = {
  fetchProviderLicenseByIdLoading: false,
  fetchProviderLicenseByIdData: {},
  fetchProviderLicenseByIdStatus: "IDLE",
  fetchProviderLicenseByIdError: null,
};

export const getProviderLicenseByIdRequest: any = createAsyncThunk(
  "patients/get/license/id",
  async (searchParams: ProviderLicenseGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getProviderLicenseByIdAPIRequest(
        searchParams
      );
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchProviderLicenseByIdSlice = createSlice({
  name: "fetchProviderLicenseById",
  initialState,
  reducers: {
    clearFetchProviderLicenseByIdResponse: (state) => {
      state.fetchProviderLicenseByIdLoading = false;
      state.fetchProviderLicenseByIdData = {};
      state.fetchProviderLicenseByIdStatus = "IDLE";
      state.fetchProviderLicenseByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderLicenseByIdRequest.pending, (state, action) => {
        state.fetchProviderLicenseByIdLoading = true;
        state.fetchProviderLicenseByIdStatus = "PENDING";
      })
      .addCase(getProviderLicenseByIdRequest.fulfilled, (state, action) => {
        state.fetchProviderLicenseByIdLoading = false;
        state.fetchProviderLicenseByIdStatus = "SUCCESS";
        state.fetchProviderLicenseByIdData = action.payload;
      })
      .addCase(getProviderLicenseByIdRequest.rejected, (state, action) => {
        state.fetchProviderLicenseByIdLoading = false;
        state.fetchProviderLicenseByIdError = action.payload;
        state.fetchProviderLicenseByIdStatus = "FAILED";
      });
  },
});

export const { clearFetchProviderLicenseByIdResponse } =
  fetchProviderLicenseByIdSlice.actions;

export const getProviderLicenseByIdSelector = (state: RootState) =>
  state.FetchProviderLicenseById;

export default fetchProviderLicenseByIdSlice.reducer;
