import { useState } from 'react';
import { IconButton } from '../atoms/IconButton';
import { InputField } from '../atoms/InputField';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { SelectInput } from '../atoms/SelectInput';
import { DatePickerField } from '../atoms/DatePickerField';
import { formatDatePickerDate } from '../../utils/commonFunctions';
import './tableSearch.css';
import { FaFileExcel } from 'react-icons/fa';
import { useAppSelector } from '../../hooks/storeHooks/hooks';
import { getSystemFacilitiesSelector } from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { Calendar } from '../atoms/Calendar';
import { claimStatus } from '../../constants/claimConstants';
import moment from 'moment';
import { getSystemVisitStatusSelector } from '../../redux/slices/system/getSystemVisitStatusSlice';
import { convertingToDropdownArr } from '../../utils/commonFunctions';
import { CommonButton } from '../atoms/CommonButton';

export const ClaimsTableAdvanceSearch = ({ onSubmit }: any) => {
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemVisitData } = useAppSelector(getSystemVisitStatusSelector);

  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDate = (name: string, date: any) => {
    setFilterObject((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  return (
    <>
      <div className="table-content__adv-search">
        <form>
          <div className="table-content__adv-search-content">
            <div className="grid md:grid-cols-5 gap-4">
              <InputField
                name="claimId"
                label="Claim ID"
                onChange={onChangeFilter}
                value={filterObject?.claimId ?? ''}
              />
              {/* <Calendar
                label="Claim Date From"
                onSelectedDateChanged={(date) => onChangeDate('claimDateFrom', date)}
                value={filterObject?.claimDateFrom ?? ''}
              /> */}
              <DatePickerField
                label="Claim Date From"
                selectedDate={formatDatePickerDate(filterObject?.claimDateFrom)}
                onChange={(date: Date | null) => {
                  if (date) {
                    onChangeDate('claimDateFrom', date);
                  }
                }}
              />
              {/* <Calendar
                label="Claim Date To"
                onSelectedDateChanged={(date) => onChangeDate('claimDateTo', date)}
                value={filterObject?.claimDateTo ?? ''}
              /> */}
              <DatePickerField
                label="Claim Date To"
                selectedDate={formatDatePickerDate(filterObject?.claimDateTo)}
                onChange={(date: Date | null) => {
                  if (date) {
                    onChangeDate('claimDateTo', date);
                  }
                }}
              />
              <InputField name="name" label="Patient Name" onChange={onChangeFilter} value={filterObject?.name ?? ''} />
              <InputField
                name="visitId"
                label="Visit ID/Patient ID"
                onChange={onChangeFilter}
                value={filterObject?.visitId ?? ''}
              />
            </div>
            <div className="grid md:grid-cols-6 gap-4 mb-4 mt-4 items-center">
              <SelectInput
                defaultPlaceholder="Status"
                name="claimStatusId"
                options={convertingToDropdownArr(systemVisitData)}
                onChange={onChangeFilter}
                value={filterObject?.claimStatusId ?? ''}
              />
              <SelectInput
                defaultPlaceholder="Select Reason"
                name="reason"
                onChange={onChangeFilter}
                value={filterObject?.reason ?? ''}
              />
              <SelectInput
                defaultPlaceholder="Select Carrier"
                name="carrier"
                onChange={onChangeFilter}
                value={filterObject?.carrier ?? ''}
              />
              <SelectInput
                defaultPlaceholder="Select Carrier Type"
                name="carrierType"
                onChange={onChangeFilter}
                value={filterObject?.carrierType ?? ''}
              />
              <SelectInput
                options={systemProvidersProviderData}
                defaultPlaceholder="Select Provider/PA"
                name="providerId"
                onChange={onChangeFilter}
                value={filterObject?.providerId ?? ''}
              />
              <div className="flex gap-2 justify-end">
                <PrimaryButton
                  type="button"
                  label="Submit"
                  style={{ height: '40px', maxWidth: '100px' }}
                  onClick={() => onSubmit(filterObject)}
                />
                <CommonButton
                  label="Reset"
                  buttonType="secondary"
                  // icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                  style={{ height: '40px', maxWidth: '100px' }}
                  onClick={() => {
                    setFilterObject({});
                    onSubmit({});
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
