import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { PatientStatementInterface } from '../../../interfaces/billingInterface';
import { FaFileExcel } from 'react-icons/fa';

export const PatientStatementsHeader: React.FC<PatientStatementInterface> = ({
  onSubmit,
  onExport,
  onChange,
  statusList,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-5 gap-4 mt-6 items-baseline">
        <InputField label="" name="patientId" placeholder="Patient ID" onChange={onChange} />
        <SelectInput
          label=""
          name="status"
          options={statusList}
          defaultPlaceholder="Select Status"
          onChange={onChange}
        />
        <div className="flex gap-2">
          <PrimaryButton type="button" label="Submit" onClick={onSubmit} style={{ width: '160px' }} />
          <PrimaryButton
            label="Export to Excel"
            icon={<FaFileExcel style={{ fontSize: '20px' }} />}
            style={{ width: '160px' }}
            onClick={onExport}
          />
        </div>
        <div className="col-span-2 flex items-center justify-end">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Quick Search:
          </label>
          <input type="text" className="border border-gray-300 rounded px-2 py-1" name="search" title="Search" />
        </div>
      </div>
    </>
  );
};
