import { Button, Modal } from 'flowbite-react';
import { PatientClaimPrintViewProps } from '../../../../interfaces/patientInterface';
import { SelectInput } from '../../../atoms/SelectInput';
import { claimType, printType } from '../../../../constants/patientConstants';
import { PrimaryButton } from '../../../atoms/PrimaryButton';
import { Checkbox } from '../../../atoms/Checkbox';

export const PrintModal: React.FC<PatientClaimPrintViewProps> = ({ openModal, setOpenModal, onChange, printView }) => {
  return (
    <Modal show={openModal} onClose={() => setOpenModal(false)}>
      <Modal.Header>Print Claim</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <div className="grid grid-cols-3 gap-4">
              <div className="">
                <SelectInput
                  label="Claim Type"
                  options={claimType}
                  enableDefaultPlaceholder={true}
                  name="claimType"
                  onChange={onChange}
                />
              </div>
              <div className="">
                <SelectInput
                  label="Print Type"
                  options={printType}
                  enableDefaultPlaceholder={true}
                  name="claimPrintType"
                  onChange={onChange}
                />
              </div>
              <div className="">
                <Checkbox label="Print Date" onChange={onChange} name="isNeedPrintDate" />
              </div>
            </div>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton label="Print" onClick={printView} style={{ width: '81px' }} />
        <Button color="gray" onClick={() => setOpenModal(false)}>
          Decline
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
