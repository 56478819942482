import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import {
  ProviderFacilitiesUpdateInterface,
  ProviderFacilitiesEditPropsInterface,
} from '../../../interfaces/providersInterface';
import { editProviderFacilitiesAPIRequest } from '../../../api/provider/providerFacilities';

const initialState: ProviderFacilitiesEditPropsInterface = {
  editProviderFacilitiesLoading: false,
  editProviderFacilitiesData: {},
  editProviderFacilitiesStatus: 'IDLE',
  editProviderFacilitiesError: null,
};

export const editProviderFacilitiesRequest: any = createAsyncThunk(
  'providers/facilities/edit',
  async (provider: ProviderFacilitiesUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await editProviderFacilitiesAPIRequest(provider);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderFacilitiesSlice = createSlice({
  name: 'editProviderFacilities',
  initialState,
  reducers: {
    clearEditProviderFacilities: (state) => {
      state.editProviderFacilitiesLoading = false;
      state.editProviderFacilitiesStatus = 'IDLE';
      state.editProviderFacilitiesData = {};
      state.editProviderFacilitiesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProviderFacilitiesRequest.pending, (state, action) => {
        state.editProviderFacilitiesLoading = true;
        state.editProviderFacilitiesStatus = 'PENDING';
      })
      .addCase(editProviderFacilitiesRequest.fulfilled, (state, action) => {
        state.editProviderFacilitiesData = action.payload;
        state.editProviderFacilitiesLoading = false;
        state.editProviderFacilitiesStatus = 'SUCCESS';
      })
      .addCase(editProviderFacilitiesRequest.rejected, (state, action) => {
        state.editProviderFacilitiesLoading = false;
        state.editProviderFacilitiesStatus = 'FAILED';
        state.editProviderFacilitiesError = action.payload;
      });
  },
});

export const { clearEditProviderFacilities } = editProviderFacilitiesSlice.actions;

export const editProviderFacilitiesSelector = (state: RootState) => state.EditProviderFacilities;

export default editProviderFacilitiesSlice.reducer;
