import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PatientVisitAddInterface, PatientVisitAddPropsInterface } from '../../../interfaces/patientInterface';
import { addPatientVisitAPIRequest } from '../../../api/patient/patientVisit';

const initialState: PatientVisitAddPropsInterface = {
  addPatientVisitLoading: false,
  addPatientVisitData: {},
  addPatientVisitError: null,
  addPatientVisitStatus: 'IDLE',
};

export const addPatientVisitRequest: any = createAsyncThunk(
  'patient/visit/add',
  async (patient: PatientVisitAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addPatientVisitAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPatientVisitSlice = createSlice({
  name: 'addPatientVisit',
  initialState,
  reducers: {
    clearAddPatientVisitResponse: (state) => {
      state.addPatientVisitLoading = false;
      state.addPatientVisitStatus = 'IDLE';
      state.addPatientVisitData = {};
      state.addPatientVisitError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPatientVisitRequest.pending, (state, action) => {
        state.addPatientVisitLoading = true;
        state.addPatientVisitStatus = 'PENDING';
      })
      .addCase(addPatientVisitRequest.fulfilled, (state, action) => {
        state.addPatientVisitData = action.payload;
        state.addPatientVisitStatus = 'SUCCESS';
        state.addPatientVisitLoading = false;
      })
      .addCase(addPatientVisitRequest.rejected, (state, action) => {
        state.addPatientVisitLoading = false;
        state.addPatientVisitError = action.payload;
        state.addPatientVisitStatus = 'FAILED';
      });
  },
});

export const { clearAddPatientVisitResponse } = addPatientVisitSlice.actions;

export const addPatientVisitSelector = (state: RootState) => state.AddPatientVisit;

export default addPatientVisitSlice.reducer;
