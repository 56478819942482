import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientCaseOptionsInterface,
  PatientFetchCaseOptionsInitialInterface,
} from '../../../interfaces/patientInterface';
import { getPatientCaseOptionsAPIRequest } from '../../../api/patient/patientCase';

const initialState: PatientFetchCaseOptionsInitialInterface = {
  caseOptionLoading: false,
  caseOptionPatientData: [],
  caseOptionError: null,
  caseOptionStatus: 'IDLE',
};

export const getPatientCaseOptionsRequest: any = createAsyncThunk(
  'patients/caseOptions/get',
  async (searchParams: PatientCaseOptionsInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientCaseOptionsAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPatientCaseOptionsSlice = createSlice({
  name: 'fetchPatientCaseOptions',
  initialState,
  reducers: {
    clearFetchPatientCaseOptionsResponse: (state) => {
      state.caseOptionLoading = false;
      state.caseOptionStatus = 'IDLE';
      state.caseOptionPatientData = [];
      state.caseOptionError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientCaseOptionsRequest.pending, (state, action) => {
        state.caseOptionLoading = true;
        state.caseOptionStatus = 'PENDING';
      })
      .addCase(getPatientCaseOptionsRequest.fulfilled, (state, action) => {
        state.caseOptionLoading = false;
        state.caseOptionStatus = 'SUCCESS';
        state.caseOptionPatientData = action.payload;
      })
      .addCase(getPatientCaseOptionsRequest.rejected, (state, action) => {
        state.caseOptionLoading = false;
        state.caseOptionError = action.payload;
        state.caseOptionStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientCaseOptionsResponse } = getPatientCaseOptionsSlice.actions;

export const getPatientCaseOptionsSelector = (state: RootState) => state.GetPatientCaseOptions;

export default getPatientCaseOptionsSlice.reducer;
