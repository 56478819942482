import { RightSheetLargeInterface } from '../../interfaces/commonInterfaces';
import { CommonAlert } from '../atoms/Alert';
import { CommonButton } from '../atoms/CommonButton';
import { Stepper } from '../stepper/Stepper';
import './rightSheet.css';

export const RightSheetLarge: React.FC<RightSheetLargeInterface> = ({
  onClose,
  children,
  title,
  submitButtonTitle,
  cancelButtonTitle,
  onSubmit,
  stepperData,
  clickStepper,
  enableFooterButtons = true,
  enableStepper = true,
  loading = false,
  enableAlert = false,
  alertDetails,
  alertOnClose,
  enableCancelButton,
  enableSubmitButton,
  enableNotes = false,
  onClickNotes,
  enableTitleAlert = false,
}) => {
  return (
    <>
      <div className="right-sheet rightsheet-lg">
        <div className="right-sheet__header">
          <h5 className="heading">
            {title} {'  '}{' '}
            <div>
              {enableTitleAlert && (
                <CommonAlert
                  icon={alertDetails?.icon}
                  message={alertDetails?.message}
                  color={alertDetails?.color}
                  onClose={alertOnClose}
                  divClassName="flex justify-center items-center mb-4"
                  alertClassName={'w-3/3'}
                />
              )}
            </div>
          </h5>
          <div className="right-sheet__header-close-btn" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div className="right-sheet__body">
          {enableAlert && (
            <CommonAlert
              icon={alertDetails?.icon}
              message={alertDetails?.message}
              color={alertDetails?.color}
              onClose={alertOnClose}
              divClassName="flex justify-center items-center mb-4"
            />
          )}
          {enableStepper && (
            <Stepper
              dataSource={stepperData}
              clickStepper={clickStepper}
              enableNotes={enableNotes}
              onClickNotes={onClickNotes}
            />
          )}
          {children}
        </div>
        <div className="right-sheet__footer">
          {enableFooterButtons && (
            <div className="flex justify-end">
              <div className="mr-2">
                <CommonButton type="reset" label={cancelButtonTitle} buttonType="secondary" onClick={onClose} />
              </div>
              <div className="">
                <CommonButton
                  type="button"
                  label={submitButtonTitle}
                  buttonType="primary"
                  onClick={onSubmit}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
