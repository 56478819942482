import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { LicenseForm } from './LicenseForm';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { getProviderByIdSelector } from '../../../../redux/slices/provider/getProviderByIdSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import {
  addProviderLicenseRequest,
  addProviderLicenseSelector,
  clearAddProviderLicenseResponse,
} from '../../../../redux/slices/provider/addProviderLicenseSlice';
import {
  clearFetchProviderLicenseByIdResponse,
  getProviderLicenseByIdRequest,
  getProviderLicenseByIdSelector,
} from '../../../../redux/slices/provider/getProviderLicenseByIdSlice';
import {
  clearEditProviderLicenseResponse,
  editProviderLicenseRequest,
  editProviderLicenseSelector,
} from '../../../../redux/slices/provider/editProviderLicenseSlice';
import {
  deleteProviderLicenseByIdRequest,
  deleteProviderLicenseByIdSelector,
} from '../../../../redux/slices/provider/deleteProviderLicenseSlice';
import {
  updateProviderLicensePrimaryRequest,
  updateProviderLicensePrimarySelector,
  clearUpdateProviderLicensePrimary,
} from '../../../../redux/slices/provider/editProviderLicensePrimaryLicenseSlice';
import { ProviderLicenseInterface } from '../../../../interfaces/providersInterface';
import { createProviderLicenseValidation } from '../../../../utils/providerValidation';
import moment from 'moment';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { FaStar } from 'react-icons/fa6';

const columns = (mode: any, handlePrimaryProviderLicense: any) => [
  {
    title: 'Primary',
    dataIndex: 'primary',
    key: 'primary',
    render: (data: any, row: any) => {
      return (
        <>
          {data ? (
            <FaStar
              color="#FFBF00"
              size={30}
              style={{ borderColor: 'black', cursor: mode === 'VIEW' ? 'default' : 'pointer' }}
              onClick={mode !== 'VIEW' ? () => handlePrimaryProviderLicense(row) : undefined}
            />
          ) : (
            <FaStar
              size={20}
              style={{ cursor: mode === 'VIEW' ? 'default' : 'pointer' }}
              onClick={mode !== 'VIEW' ? () => handlePrimaryProviderLicense(row) : undefined}
            />
          )}
        </>
      );
    },
  },
  {
    title: 'License Number',
    dataIndex: 'licenseNum',
    key: 'licenseNum',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
];

const dataSourceTest = [
  {
    primary: false,
    licenseNum: '111212',
    state: 'JI',
  },
  {
    primary: true,
    licenseNum: '43121',
    state: 'HA',
  },
];

export const ProviderLicense: React.FC<ProviderLicenseInterface> = ({ selectedId, fetchProviderById, mode }) => {
  const dispatch = useAppDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [providerLicenseFormData, setProviderLicenseFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);

  const [errorObj, setErrorObj] = useState<any>({});
  const [licenseMode, setLicenseMode] = useState<any>('CREATE');
  const [selectedLicenseId, setSelectedLicenseId] = useState<number>();
  const { fetchProviderByIdStatus, fetchProviderByIdData } = useAppSelector(getProviderByIdSelector);
  const { addProviderLicenseStatus, addProviderLicenseLoading } = useAppSelector(addProviderLicenseSelector);

  const { deleteProviderLicenseByIdLoading, deleteProviderLicenseByIdStatus } = useAppSelector(
    deleteProviderLicenseByIdSelector
  );

  const { fetchProviderLicenseByIdData, fetchProviderLicenseByIdStatus } =
    useAppSelector(getProviderLicenseByIdSelector);

  const { editProviderLicenseLoading, editProviderLicenseStatus } = useAppSelector(editProviderLicenseSelector);
  const { updateProviderLicensePrimaryStatus } = useAppSelector(updateProviderLicensePrimarySelector);

  useEffect(() => {
    if (fetchProviderByIdStatus === 'SUCCESS') {
      setDataSource(fetchProviderByIdData);
      setVisibleModal(false);
    }
  }, [fetchProviderByIdStatus]);

  useEffect(() => {
    if (addProviderLicenseStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'license');
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setProviderLicenseFormData({});
        setErrorObj({});
        setLicenseMode('CREATE');
      }, 3000);
      dispatch(clearAddProviderLicenseResponse());
    } else if (addProviderLicenseStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddProviderLicenseResponse());
    }
  }, [addProviderLicenseStatus]);

  useEffect(() => {
    if (fetchProviderLicenseByIdStatus === 'SUCCESS') {
      let obj = {
        id: fetchProviderLicenseByIdData.id,
        primary: fetchProviderLicenseByIdData.primary,
        licenseNum: fetchProviderLicenseByIdData.licenseNum,
        state: fetchProviderLicenseByIdData.state,
        effectiveDate: fetchProviderLicenseByIdData.effectiveDate,
        expDate: fetchProviderLicenseByIdData.expDate,
      };
      setProviderLicenseFormData(obj);
      dispatch(clearFetchProviderLicenseByIdResponse());
      setVisibleModal(true);
    } else if (fetchProviderLicenseByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [fetchProviderLicenseByIdStatus]);

  useEffect(() => {
    if (deleteProviderLicenseByIdStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'license');
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    } else if (deleteProviderLicenseByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
  }, [deleteProviderLicenseByIdStatus]);

  useEffect(() => {
    if (editProviderLicenseStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'license');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setErrorObj({});
        setProviderLicenseFormData({});
        setLicenseMode('CREATE');
      }, 3000);
      dispatch(clearEditProviderLicenseResponse());
    } else if (editProviderLicenseStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditProviderLicenseResponse());
    }
  }, [editProviderLicenseStatus]);

  useEffect(() => {
    if (updateProviderLicensePrimaryStatus === 'SUCCESS') {
      fetchProviderById(selectedId, 'license');
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearUpdateProviderLicensePrimary());
    } else if (updateProviderLicensePrimaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearUpdateProviderLicensePrimary());
    }
  }, [updateProviderLicensePrimaryStatus]);

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setLicenseMode('EDIT');
    setSelectedLicenseId(dataSet?.id);
    getLicenseDetailsGetById(dataSet?.id);
    // setSelectedToEdit(dataSet);
    // setVisibleEditForm(true);
  };

  const getLicenseDetailsGetById = (id: number) => {
    dispatch(getProviderLicenseByIdRequest({ providerId: selectedId, licenseId: id }));
  };

  const handleLicenseFormChange = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setProviderLicenseFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onProviderLicenseDateChange = (name: string, date: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setProviderLicenseFormData((prev: any) => ({
      ...prev,
      [name]: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const handlePrimaryProviderLicense = (row: any) => {
    const obj = {
      providerId: selectedId,
      licenseId: row?.id,
      licenseData: { primary: row?.primary ? false : true },
    };

    dispatch(updateProviderLicensePrimaryRequest(obj));
  };

  const onDelete = (event: any, rowIndex: any) => {
    const dataSet = dataSource[rowIndex];
    setSelectedLicenseId(dataSet?.id);
    dispatch(
      deleteProviderLicenseByIdRequest({
        providerId: selectedId,
        licenseId: dataSet?.id,
      })
    );
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleAddNewModal = () => {
    setProviderLicenseFormData({});
    setLicenseMode('CREATE');
    setVisibleModal(true);
    setErrorObj({});
  };

  const onCloseModal = () => {
    setProviderLicenseFormData({});
    setLicenseMode('CREATE');
    setVisibleModal(false);
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const onSubmit = () => {
    if (licenseMode === 'CREATE') {
      let obj = {
        providerId: selectedId,
        licenseData: providerLicenseFormData,
      };

      const validationDetails = createProviderLicenseValidation(obj?.licenseData);
      // console.log('==== licenseMode - validationDetails ====', licenseMode, validationDetails);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addProviderLicenseRequest(obj));
      }
    } else if (licenseMode === 'EDIT') {
      let obj = {
        providerId: selectedId,
        licenseData: providerLicenseFormData,
        licenseId: selectedLicenseId,
      };

      const validationDetails = createProviderLicenseValidation(obj?.licenseData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(editProviderLicenseRequest(obj));
      }
    }
  };

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      {mode !== 'VIEW' && (
        <div className="mt-6 mb-6">
          Please add your State License Information. A separate entry is required for each state for which you are
          licensed.
        </div>
      )}

      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>

      <TableContent
        enableActions={mode === 'VIEW' ? false : true}
        columns={columns(mode, handlePrimaryProviderLicense)}
        dataSource={dataSource}
        enableDelete={true}
        enableEdit={true}
        enableView={false}
        onDelete={onDelete}
        onEdit={onEdit}
      />

      {visibleModal && (
        <RightSheet
          title="Add / Edit License"
          submitButtonTitle="Submit"
          cancelButtonTitle="Cancel"
          children={
            <LicenseForm
              licenseFormEditData={providerLicenseFormData}
              onChange={handleLicenseFormChange}
              licenseMode={licenseMode}
              errorObject={errorObj}
              onDateChange={onProviderLicenseDateChange}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      )}
    </>
  );
};
