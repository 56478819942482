import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderAddressPrimaryUpdateInterface,
  ProviderAddressPrimaryUpdatePropsInterface,
} from '../../../interfaces/providersInterface';
import { updateProviderAddressPrimaryAPIRequest } from '../../../api/provider/providerAddress';

const initialState: ProviderAddressPrimaryUpdatePropsInterface = {
  updateProviderAddressPrimaryLoading: false,
  updateProviderAddresPrimaryData: {},
  updateProviderAddressPrimaryStatus: 'IDLE',
  updateProviderAddressPrimaryError: null,
};

export const updateProviderAddressPrimaryRequest: any = createAsyncThunk(
  'provider/address/changePrimary',
  async (address: ProviderAddressPrimaryUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateProviderAddressPrimaryAPIRequest(address);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateProviderAddressPrimarySlice = createSlice({
  name: 'updateProviderAddressPrimary',
  initialState,
  reducers: {
    clearUpdateProviderAddressPrimary: (state) => {
      state.updateProviderAddressPrimaryLoading = false;
      state.updateProviderAddressPrimaryStatus = 'IDLE';
      state.updateProviderAddresPrimaryData = {};
      state.updateProviderAddressPrimaryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProviderAddressPrimaryRequest.pending, (state, action) => {
        state.updateProviderAddressPrimaryLoading = true;
        state.updateProviderAddressPrimaryStatus = 'PENDING';
      })
      .addCase(updateProviderAddressPrimaryRequest.fulfilled, (state, action) => {
        state.updateProviderAddresPrimaryData = action.payload;
        state.updateProviderAddressPrimaryLoading = false;
        state.updateProviderAddressPrimaryStatus = 'SUCCESS';
      })
      .addCase(updateProviderAddressPrimaryRequest.rejected, (state, action) => {
        state.updateProviderAddressPrimaryLoading = false;
        state.updateProviderAddressPrimaryStatus = 'FAILED';
        state.updateProviderAddressPrimaryError = action.payload;
      });
  },
});

export const { clearUpdateProviderAddressPrimary } = updateProviderAddressPrimarySlice.actions;

export const updateProviderAddressPrimarySelector = (state: RootState) => state.UpdateProviderAddressPrimary;

export default updateProviderAddressPrimarySlice.reducer;
