import {
  ProviderCarriersAddInterface,
  ProviderCarriersGetByIdInterface,
  ProviderCarriersUpdateInterface,
  ProviderCarrierApplicationStatusUpdateInterface,
  ProviderCarrierPendingInfoUpdateInferface,
  ProviderCarrierFollowUpAddInterface,
} from '../../interfaces/providersInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addProviderCarriersAPIRequest = async (carrierData: ProviderCarriersAddInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${carrierData?.providerId}/carrier`,
    'post',
    {
      data: { ...carrierData?.carriersData },
    }
  );
};

export const updateProviderCarriersAPIRequest = async (carrierData: ProviderCarriersUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${carrierData?.providerId}/carrier/${carrierData?.carriersId}`,
    'put',
    {
      data: { ...carrierData?.carriersData },
    }
  );
};

export const getProviderCarriersByIdAPIRequest = async (carrierData: ProviderCarriersGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${carrierData?.providerId}/carrier/${carrierData?.carriersId}`,
    'get',
    {}
  );
};

export const deleteProviderCarriersByIdAPIRequest = async (carrierData: ProviderCarriersGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${carrierData?.providerId}/carrier/${carrierData?.carriersId}`,
    'delete',
    {}
  );
};

export const editProviderCarrierApplicationStatusAPIRequest = async (
  carrierData: ProviderCarrierApplicationStatusUpdateInterface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${carrierData.providerId}/carrier/${carrierData.carriersId}/update-appl-status`,
    'put',
    {
      data: { appStatusId: carrierData.appStatusId },
    }
  );
};

export const editProviderCarrierPendingInfoAPIRequest = async (
  carrierData: ProviderCarrierPendingInfoUpdateInferface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${carrierData.providerId}/carrier/${carrierData.carriersId}/update-pending-info`,
    'put',
    {
      data: { infoId: carrierData.infoId, checked: carrierData.checked },
    }
  );
};

export const addProviderCarrierFollowUpAPIRequest = async (carrierData: ProviderCarrierFollowUpAddInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${carrierData.providerId}/carrier/${carrierData.carriersId}/add-follow-up`,
    'post',
    {
      data: { ...carrierData?.carrierFollowUpData },
    }
  );
};
