import { useEffect, useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { RightSheet } from '../../../components/rightSheet/RightSheet';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { CommonAlert } from '../../../components/atoms/Alert';
import { RightSheetLarge } from '../../../components/rightSheet/RightSheetLarge';
import { AddressList } from '../../../components/account/users/AddressList';
import {
  getCPTCodesRequest,
  getCPTCodesSelector,
  clearFetchCPTCodesResponse,
} from '../../../redux/slices/system/getCPTCodesSlice';
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { userValidation } from '../../../utils/userValidation';
import { createAccountCPTCodeValidation } from '../../../utils/accountValidation';
import { RightSheetForm } from '../../../components/account/codes/RightSheetForm';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {
  addCPTCodeRequest,
  addCPTCodeSelector,
  clearAddCPTCodeResponse,
} from '../../../redux/slices/codes/addCPTCodeSlice';
import { cptValidation } from '../../../utils/codeValidation';
import {
  clearFetchModifierListResponse,
  getModifierListRequest,
  getModifierListSelector,
} from '../../../redux/slices/codes/modifierListSlices';
import {
  clearGetCPTCodeByIdResponse,
  getCPTCodeByIdRequest,
  getCPTCodeByIdSelector,
} from '../../../redux/slices/codes/viewCPTCodeSlice';
import { CPTEditSheet } from '../../../components/account/codes/CPTEditSheet';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
  },
  // {
  //   title: 'POS',
  //   dataIndex: 'pos',
  //   key: 'pos',
  // },
  // {
  //   title: 'Modifier',
  //   dataIndex: 'modifier',
  //   key: 'modifier',
  // },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: string) => `$${text}`,
  },
];

const dataSourceDummy = [
  {
    id: '1',
    cptCode: '99304',
    description: 'SNF Initial Visit - High',
    descriptionLong: 'Skilled Nursing Facility - Initial Visit - Low Complexity',
    pos: '31',
    modifier: '34',
    amount: '147.00',
    rvu: '233',
    neverBill: false,
    disableCPT: true,
    tos: '20',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'cptCodes', label: 'CPT Codes', status: 'active', link: 'cpt-codes' },
];

export const CPTCodesPage = () => {
  const dispatch = useAppDispatch();
  const { cptCodesData, cptCodesStatus } = useAppSelector(getCPTCodesSelector);
  const { addCPTCodeStatus, addCPTCodeError } = useAppSelector(addCPTCodeSelector);
  const { modifierListData, modifierListError, modifierListStatus } = useAppSelector(getModifierListSelector);
  const { cptCodeByIdData, cptCodeByIdStatus, cptCodeByIdError } = useAppSelector(getCPTCodeByIdSelector);

  const [visibleModal, setVisibleModal] = useState(false);
  const [onFormChange, setOnFormChange] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(1);
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});
  const [modifierList, setModifierList] = useState([]);

  useEffect(() => {
    fetchCPTCodes();
    fetchModifierList();
  }, []);

  useEffect(() => {
    if (cptCodesStatus === 'SUCCESS') {
      setDataSource(cptCodesData?.items);
      setCurrentPage(cptCodesData?.currentPage);
      setTotalPages(cptCodesData?.totalPages);
    }
    dispatch(clearFetchCPTCodesResponse());
  }, [cptCodesStatus]);

  useEffect(() => {
    if (addCPTCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      fetchCPTCodes();
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addCPTCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addCPTCodeError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }

    dispatch(clearAddCPTCodeResponse());
  }, [addCPTCodeStatus]);

  useEffect(() => {
    if (cptCodeByIdStatus === 'SUCCESS') {
      setOnFormChange(cptCodeByIdData);
    } else if (cptCodeByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: cptCodeByIdError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearGetCPTCodeByIdResponse());
  }, [cptCodeByIdStatus]);

  useEffect(() => {
    if (modifierListStatus === 'SUCCESS') {
      setModifierList(modifierListData);
    } else if (modifierListStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: modifierListError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchModifierListResponse());
  }, [modifierListStatus]);

  const fetchModifierList = async () => {
    dispatch(getModifierListRequest());
  };

  const fetchCPTCodes = async (pageSize = PAGE_SIZE, pageNumber = currentPage) => {
    dispatch(getCPTCodesRequest({ page: pageNumber, size: pageSize, code: '', desc: '' }));
  };

  const fetchCPTById = async (id: number) => {
    dispatch(getCPTCodeByIdRequest({ id }));
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchCPTCodes(PAGE_SIZE, page - 1);
  };

  const handleModalVisible = () => {
    errorAlertOnClose();
    setOnFormChange({});
    setErrorObj({});
    setMode('CREATE');
    setVisibleModal(true);
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  const handleOnClose = () => {
    setVisibleModal(false);
    setVisibleEditForm(false);
    setOnFormChange({});
    setErrorObj({});
    setMode('CREATE');
    errorAlertOnClose();
  };

  const handleFormChanges = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }

    const convertedValue = type === 'checkbox' ? checked : value;

    setOnFormChange((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const onSubmit = () => {
    // console.log(onFormChange);

    let obj = {
      code: onFormChange?.code ?? '', // maximum 5 Characters
      shortDesc: onFormChange?.shortDesc ?? '',
      longDesc: onFormChange?.longDesc ?? '',
      modifierCodeId: onFormChange?.modifierCodeId ? parseInt(onFormChange?.modifierCodeId) : null,
      isNeverBill: onFormChange?.isNeverBill ?? false,
      isDisableCptInApp: onFormChange?.isDisableCptInApp ?? false,
      rvuWeight: onFormChange?.rvuWeight ? parseFloat(onFormChange?.rvuWeight) : null,
      amount: onFormChange?.amount ? parseFloat(onFormChange?.amount) : null,
      pos: onFormChange?.pos ?? '', // maximum 2 characters
      tos: onFormChange?.tos ?? '', // maximum 2 characters
    };
    // console.log('=== onFormChange ===', obj);
    if (mode === 'CREATE') {
      const validationDetails = cptValidation(obj);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails?.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addCPTCodeRequest(obj));
      }
    } else if (mode === 'EDIT') {
      let obj = {
        cptCodeID: selectedToEdit?.id,
        cptCodeData: onFormChange,
      };

      const validationDetails = cptValidation(obj?.cptCodeData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails?.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
      }
    }
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setMode('EDIT');
    setSelectedToEdit(dataSet);
    setVisibleEditForm(true);
    fetchCPTById(dataSet?.id);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setMode('VIEW');
    const dataSet = dataSource[rowIndex];
    // console.log('=== view code ===', dataSet);
    setSelectedToEdit(dataSet);
    //fetchCodeById(dataSet?.id);
    setVisibleEditForm(true);
    setSelectedChild(1);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleClickStepper = (id: any) => {
    setSelectedChild(id);
  };

  const renderTab = () => {
    switch (selectedChild) {
      case 1:
        return (
          <RightSheetForm
            codeData={selectedToEdit}
            mode={mode}
            selectedId={selectedToEdit?.id}
            onChange={handleFormChanges}
            errorObject={errorObj}
            modifiers={modifierList}
          />
        );
      case 2:
        return <AddressList selectedId={selectedToEdit?.id} mode={mode} />;
      default:
        break;
    }
  };

  // console.log('=== addCPTCodeLoading ===', addCPTCodeLoading);
  // console.log('onFormChange', onFormChange);

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="CPT Codes" breadCrumbArr={breadCrumbArr} />
      <TableSearch />
      {
        //loading
        false ? (
          <Spinner />
        ) : (
          <>
            {dataSource?.length > 0 ? (
              <>
                <TableContent
                  enableActions={true}
                  columns={columns}
                  dataSource={dataSource}
                  enableEdit={true}
                  onEdit={onEdit}
                />
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              </>
            ) : (
              <EmptyContent />
            )}
          </>
        )
      }

      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title="Create CPT Code"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          onSubmit={onSubmit}
          children={
            <RightSheetForm onChange={handleFormChanges} mode={mode} errorObject={errorObj} modifiers={modifierList} />
          }
          // loading={addCPTCodeLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}
      {visibleEditForm ? (
        <RightSheetLarge
          title={mode === 'EDIT' ? 'Update CPT Code' : 'View CPT Code'}
          onSubmit={onSubmit}
          onClose={handleOnClose}
          submitButtonTitle="Update"
          cancelButtonTitle="Cancel"
          enableStepper={false}
          enableFooterButtons={selectedChild == 1 ? true : false}
          children={<CPTEditSheet />}
          // loading={editCPTCodeLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}
      {/* <RightSheet /> */}
    </div>
  );
};
