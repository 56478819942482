import { CodeFormPropsInterface } from '../../../interfaces/userInterface';
import { InputField } from '../../atoms/InputField';

export const ModifierRightSheetForm: React.FC<CodeFormPropsInterface> = ({
  onChange,
  codeData,
  mode,
  selectedId,
  errorObject,
}) => {
  // console.log('=== personalData ===', personalData);

  return (
    <>
      <>
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <InputField
            label="Code"
            name="code"
            onChange={onChange}
            defaultValue={codeData?.code ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={true}
            error={errorObject && errorObject?.code}
            errorMessage={errorObject && errorObject?.code}
          />
          <InputField
            label="Description"
            name="desc"
            onChange={onChange}
            defaultValue={codeData?.desc ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={true}
            error={errorObject && errorObject?.desc}
            errorMessage={errorObject && errorObject?.desc}
          />
        </div>
      </>
    </>
  );
};
