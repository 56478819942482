import { PatientInsuranceFormPropsInterface } from '../../../../interfaces/patientInterface';
import { useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { SelectInput } from '../../../atoms/SelectInput';
import { insuranceType } from '../../../../constants/systemConstants';
import { InputField } from '../../../atoms/InputField';
import { patientRelationship } from '../../../../constants/patientConstants';
import { Calendar } from '../../../atoms/Calendar';
import { TextArea } from '../../../atoms/TextArea';
import { getSystemInsuranceSelector } from '../../../../redux/slices/system/getSystemInsuranceSlice';
import { useState } from 'react';
import { RelationshipModal } from './RelationshipModal';
import { error } from 'console';
import { formatDate } from '../../../../utils/commonFunctions';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const InsuranceForm: React.FC<PatientInsuranceFormPropsInterface> = ({
  onChange,
  insuranceFormEditData,
  onChangeEndDate,
  onChangeStartData,
  errorObject,
  mode,
  selectedPatientName,
  handleSubscriber,
}) => {
  // console.log('insuranceFormEditData', insuranceFormEditData);
  const { systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);
  // console.log(
  //   '=== insuranceFormEditData ===',
  //   insuranceFormEditData,
  //   Object.keys(insuranceFormEditData)?.length > 0 && insuranceFormEditData?.invoiceRelationship !== 'SELF'
  // );

  // console.log('=== insuranceFormEditData ===', insuranceFormEditData);

  const [visibleRelationshipModal, setVisibleRelationshipModal] = useState<boolean>(false);

  const handleVisibileRealtionshipModal = (visible: boolean) => {
    setVisibleRelationshipModal(visible);
  };

  const handleAddrow = (data: any) => {
    handleSubscriber && handleSubscriber(data);
  };

  return (
    <>
      {/* <h6 className="sub-heading mt-6">Patient Insurance</h6>
      <hr /> */}
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        <SelectInput
          label={'Type'}
          name="insuranceType"
          onChange={onChange}
          options={insuranceType}
          defaultValue={
            insuranceFormEditData?.insuranceType ? insuranceFormEditData?.insuranceType.toString() : undefined
          }
          error={errorObject && errorObject?.insuranceType}
          errorMessage={errorObject && errorObject?.insuranceType}
        />
        <SelectInput
          label={'Insurance'}
          name="insuranceId"
          onChange={onChange}
          options={systemInsuranceInsuranceData}
          defaultValue={insuranceFormEditData?.insuranceId ? insuranceFormEditData?.insuranceId?.toString() : undefined}
          error={errorObject && errorObject?.insuranceId}
          errorMessage={errorObject && errorObject?.insuranceId}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        <InputField
          label="Policy ID"
          name="policyId"
          onChange={onChange}
          defaultValue={insuranceFormEditData?.policyId}
          error={errorObject && errorObject?.policyId}
          errorMessage={errorObject && errorObject?.policyId}
          required={true}
        />
        <InputField
          label="Group Number"
          name="group"
          onChange={onChange}
          defaultValue={insuranceFormEditData?.group ?? undefined}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-5 mt-6">
        <SelectInput
          label={'Relationship'}
          name="relationship"
          onChange={onChange}
          options={patientRelationship}
          defaultValue={
            mode === 'CREATE'
              ? 'SELF'
              : insuranceFormEditData?.relationship
              ? insuranceFormEditData?.relationship?.toString()
              : undefined
          }
          error={errorObject && errorObject?.relationship}
          errorMessage={errorObject && errorObject?.relationship}
          required={true}
        />
        {/* {Object.keys(insuranceFormEditData)?.length > 0 && insuranceFormEditData?.relationship !== 'SELF' ? (
          <div className="flex" style={{ alignItems: 'end' }}>
            <InputField
              label="Subscriber Name"
              name="subscriberId"
              onChange={onChange}
              defaultValue={insuranceFormEditData?.subscriberName ?? undefined}
              error={errorObject && errorObject?.subscriberId}
              errorMessage={errorObject && errorObject?.subscriberId}
              value={insuranceFormEditData?.subscriberName}
              // disabled={mode === 'CREATE' || insuranceFormEditData?.invoiceRelationship === 'SELF'}
            />
            <CustomSearchButton onClick={() => setVisibleRelationshipModal(true)} />
          </div>
        ) : (
          <InputField
            label="Subscriber Name"
            name="subscriberId"
            onChange={onChange}
            defaultValue={
              mode === 'CREATE' || !insuranceFormEditData?.subscriberName
                ? selectedPatientName
                : insuranceFormEditData?.subscriberName
            }
            error={errorObject && errorObject?.subscriberId}
            errorMessage={errorObject && errorObject?.subscriberId}
            disabled={mode === 'CREATE' || insuranceFormEditData?.relationship === 'SELF'}
          />
        )} */}
        {renderRelationship({
          onChange,
          mode,
          selectedPatientName,
          error: errorObject?.subscriberId,
          errorMessage: errorObject?.subscriberId,
          subscriberName: insuranceFormEditData?.subscriberName,
          setVisibleRelationshipModal,
          relationship: insuranceFormEditData?.relationship,
        })}
      </div>

      <div className="grid md:grid-cols-3 gap-5 mt-6">
        {/* <Calendar
          onSelectedDateChanged={onChangeStartData}
          name="policyStartDate"
          label="Policy Start Date"
          defaultDate={
            insuranceFormEditData?.policyStartDate ? new Date(insuranceFormEditData?.policyStartDate) : undefined
          }
          required={true}
        /> */}
        <DatePickerField
          label="Policy Start Date"
          name="policyStartDate"
          selectedDate={formatDatePickerDate(insuranceFormEditData?.policyStartDate)}
          onChange={onChangeStartData}
          required={true}
        />
        {/* <Calendar
          onSelectedDateChanged={onChangeEndDate}
          name="policyEndDate"
          label="Policy End Date"
          defaultDate={
            insuranceFormEditData?.policyEndDate ? new Date(insuranceFormEditData?.policyEndDate) : undefined
          }
          required={true}
        /> */}
        <DatePickerField
          label="Policy End Date"
          name="policyEndDate"
          selectedDate={formatDatePickerDate(insuranceFormEditData?.policyEndDate)}
          onChange={onChangeEndDate}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-1 mt-6">
        <TextArea
          onChange={onChange}
          name="note"
          label="Notes"
          defaultValue={insuranceFormEditData?.note ?? undefined}
        />
      </div>

      {visibleRelationshipModal && (
        <RelationshipModal
          visibleRelationshipModal={visibleRelationshipModal}
          setVisibleRelationshipModal={handleVisibileRealtionshipModal}
          addRow={handleAddrow}
        />
      )}
    </>
  );
};

const CustomSearchButton = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      style={{
        borderRadius: '5px',
        background: '#11C8DE',
        height: '42px',
        marginLeft: '5px',
        width: '35px !important',
      }}
    >
      <svg
        width="35px"
        height="35px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        //   className="w-4 h-4"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
            stroke="#6b7280"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>{' '}
        </g>
      </svg>
    </button>
  );
};

const renderRelationship = ({
  mode,
  onChange,
  error,
  defaultValue,
  errorMessage,
  setVisibleRelationshipModal,
  selectedPatientName,
  subscriberName,
  relationship,
}: any) => {
  // console.log('=== relationship ===', relationship, selectedPatientName, mode);

  if (mode === 'CREATE') {
    if (relationship === 'SELF' || relationship === undefined) {
      return (
        <InputField
          label="Subscriber Name"
          name="subscriberId"
          onChange={onChange}
          defaultValue={mode === 'CREATE' || !subscriberName ? selectedPatientName : subscriberName}
          error={error}
          errorMessage={errorMessage}
          disabled={mode === 'CREATE' || relationship === 'SELF'}
        />
      );
    } else {
      return (
        <div className="flex" style={{ alignItems: 'end' }}>
          <InputField
            label="Subscriber Name"
            name="subscriberId"
            onChange={onChange}
            defaultValue={subscriberName ?? undefined}
            error={error}
            errorMessage={errorMessage}
            value={subscriberName}
          />
          <CustomSearchButton onClick={() => setVisibleRelationshipModal(true)} />
        </div>
      );
    }
  } else {
    if (relationship === 'SELF') {
      return (
        <InputField
          label="Subscriber Name"
          name="subscriberId"
          onChange={onChange}
          defaultValue={subscriberName ?? selectedPatientName}
          error={error}
          errorMessage={errorMessage}
          disabled={relationship === 'SELF'}
        />
      );
    } else {
      return (
        <div className="flex" style={{ alignItems: 'end' }}>
          <InputField
            label="Subscriber Name"
            name="subscriberId"
            onChange={onChange}
            defaultValue={subscriberName ?? undefined}
            error={error}
            errorMessage={errorMessage}
            value={subscriberName}
          />
          <CustomSearchButton onClick={() => setVisibleRelationshipModal(true)} />
        </div>
      );
    }
  }
};
