import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  ProviderLicenseAddInterface,
  ProviderLicenseAddPropsInterface,
} from "../../../interfaces/providersInterface";
import { addProviderLicenseAPIRequest } from "../../../api/provider/providerLicense";

const initialState: ProviderLicenseAddPropsInterface = {
  addProviderLicenseLoading: false,
  addProviderLicenseData: {},
  addProviderLicenseError: null,
  addProviderLicenseStatus: "IDLE",
};

export const addProviderLicenseRequest: any = createAsyncThunk(
  "provider/license/add",
  async (patient: ProviderLicenseAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addProviderLicenseAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addProviderLicenseSlice = createSlice({
  name: "addProviderLicense",
  initialState,
  reducers: {
    clearAddProviderLicenseResponse: (state) => {
      state.addProviderLicenseLoading = false;
      state.addProviderLicenseStatus = "IDLE";
      state.addProviderLicenseData = {};
      state.addProviderLicenseError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProviderLicenseRequest.pending, (state, action) => {
        state.addProviderLicenseLoading = true;
        state.addProviderLicenseStatus = "PENDING";
      })
      .addCase(addProviderLicenseRequest.fulfilled, (state, action) => {
        state.addProviderLicenseData = action.payload;
        state.addProviderLicenseStatus = "SUCCESS";
        state.addProviderLicenseLoading = false;
      })
      .addCase(addProviderLicenseRequest.rejected, (state, action) => {
        state.addProviderLicenseLoading = false;
        state.addProviderLicenseError = action.payload;
        state.addProviderLicenseStatus = "FAILED";
      });
  },
});

export const { clearAddProviderLicenseResponse } =
  addProviderLicenseSlice.actions;

export const addProviderLicenseSelector = (state: RootState) =>
  state.AddProviderLicense;

export default addProviderLicenseSlice.reducer;
