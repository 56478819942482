import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store"; 
import {
  ProviderAddressEditPropsInterface,
  ProviderAddressUpdateInterface,
} from "../../../interfaces/providersInterface";
import { updateProviderAddressAPIRequest } from "../../../api/provider/providerAddress";

const initialState: ProviderAddressEditPropsInterface = {
  editProviderAddressLoading: false,
  editProviderAddressData: {},
  editProviderAddressError: null,
  editProviderAddressStatus: "IDLE",
};

export const editProviderAddressRequest: any = createAsyncThunk(
  "provider/address/edit",
  async (patient: ProviderAddressUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateProviderAddressAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editProviderAddressSlice = createSlice({
  name: "editProviderAddress",
  initialState,
  reducers: {
    clearEditProviderAddressResponse: (state) => {
      state.editProviderAddressLoading = false;
      state.editProviderAddressStatus = "IDLE";
      state.editProviderAddressData = {};
      state.editProviderAddressError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProviderAddressRequest.pending, (state, action) => {
        state.editProviderAddressLoading = true;
        state.editProviderAddressStatus = "PENDING";
      })
      .addCase(editProviderAddressRequest.fulfilled, (state, action) => {
        state.editProviderAddressData = action.payload;
        state.editProviderAddressStatus = "SUCCESS";
        state.editProviderAddressLoading = false;
      })
      .addCase(editProviderAddressRequest.rejected, (state, action) => {
        state.editProviderAddressLoading = false;
        state.editProviderAddressError = action.payload;
        state.editProviderAddressStatus = "FAILED";
      });
  },
});

export const { clearEditProviderAddressResponse } =
  editProviderAddressSlice.actions;

export const editProviderAddressSelector = (state: RootState) =>
  state.EditProviderAddress;

export default editProviderAddressSlice.reducer;
