import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddPatientCaseInterface,
  PatientCaseAddPropsInterface,
  PatientVisitAddInterface,
  PatientVisitAddPropsInterface,
} from '../../../interfaces/patientInterface';
import { addPatientVisitAPIRequest } from '../../../api/patient/patientVisit';
import { addPatientCaseAPIRequest } from '../../../api/patient/patientCase';

const initialState: PatientCaseAddPropsInterface = {
  addPatientCaseLoading: false,
  addPatientCaseData: {},
  addPatientCaseError: null,
  addPatientCaseStatus: 'IDLE',
};

export const addPatientCaseRequest: any = createAsyncThunk(
  'patient/case/add',
  async (patient: AddPatientCaseInterface, thunkAPI: any) => {
    try {
      const response: any = await addPatientCaseAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPatientCaseSlice = createSlice({
  name: 'addPatientCase',
  initialState,
  reducers: {
    clearAddPatientCaseResponse: (state) => {
      state.addPatientCaseLoading = false;
      state.addPatientCaseStatus = 'IDLE';
      state.addPatientCaseData = {};
      state.addPatientCaseError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPatientCaseRequest.pending, (state, action) => {
        state.addPatientCaseLoading = true;
        state.addPatientCaseStatus = 'PENDING';
      })
      .addCase(addPatientCaseRequest.fulfilled, (state, action) => {
        state.addPatientCaseData = action.payload;
        state.addPatientCaseStatus = 'SUCCESS';
        state.addPatientCaseLoading = false;
      })
      .addCase(addPatientCaseRequest.rejected, (state, action) => {
        state.addPatientCaseLoading = false;
        state.addPatientCaseError = action.payload;
        state.addPatientCaseStatus = 'FAILED';
      });
  },
});

export const { clearAddPatientCaseResponse } = addPatientCaseSlice.actions;

export const addPatientCaseSelector = (state: RootState) => state.AddPatientCase;

export default addPatientCaseSlice.reducer;
