import { BadgePropsInterface } from "../../interfaces/atomInterfaces";

export const Badge: React.FC<BadgePropsInterface> = ({ value }) => {
  return (
    <>
      <span
        className="
        badge
        bg-gray2
        dark:bg-gray8
        text-gray-900
        text-xs
        font-medium
        me-2
        px-2.5
        py-0.5
        rounded
        flex
        gap-1
        items-center;"
      >
        {value}
      </span>
    </>
  );
};
