import { useState } from 'react';
import { Calendar } from '../atoms/Calendar';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { FaFileExcel } from 'react-icons/fa';
import { InputField } from '../atoms/InputField';
import { DatePickerField } from '../atoms/DatePickerField';
import { formatDatePickerDate } from '../../utils/commonFunctions';
import './tableSearch.css';

export const AdvancedBatchSearch = ({ onSubmit }: any) => {
  const [filterObject, setFilterObject] = useState<any>({});

  const onChangeFilter = (e: any) => {
    e.preventDefault();
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onProviderCarriersDateChange = (name: string, value: Date) => {
    setFilterObject((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-4 gap-4">
            {/* <Calendar label="Date From" /> */}
            {/* <Calendar label="Date To" /> */}
            <DatePickerField
              label="Date To"
              selectedDate={formatDatePickerDate(filterObject?.dateTo)}
              onChange={(date: Date | null) => {
                if (date) {
                  onProviderCarriersDateChange('dateTo', date);
                }
              }}
            />
            <DatePickerField
              label="Date From"
              selectedDate={formatDatePickerDate(filterObject?.dateFrom)}
              onChange={(date: Date | null) => {
                if (date) {
                  onProviderCarriersDateChange('dateFrom', date);
                }
              }}
            />
            <InputField name="checkNumber" label="Check Number" onChange={onChangeFilter} />
            <div className="flex gap-2 justify-end pt-2">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '120px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <PrimaryButton
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
