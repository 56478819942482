import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchCPTCodesInitialInterface,
  FetchModifiersInitialInterface,
  GetICDCodesInterface,
} from '../../../interfaces/systemInterfaces';
import { getCPTCodesAPIRequest, getModifiersAPIRequest } from '../../../api/system/code';

const initialState: FetchModifiersInitialInterface = {
  modifiersLoading: false,
  modifiersData: [],
  modifiersError: null,
  modifiersStatus: 'IDLE',
};

export const getModifiersRequest: any = createAsyncThunk(
  'codes/modifiers/get',
  async (code: GetICDCodesInterface, thunkAPI: any) => {
    try {
      const response: any = await getModifiersAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getModifiersSlice = createSlice({
  name: 'fetchModifier',
  initialState,
  reducers: {
    clearFetchModifiersResponse: (state) => {
      state.modifiersLoading = false;
      state.modifiersStatus = 'IDLE';
      state.modifiersData = [];
      state.modifiersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModifiersRequest.pending, (state, action) => {
        state.modifiersLoading = true;
        state.modifiersStatus = 'PENDING';
      })
      .addCase(getModifiersRequest.fulfilled, (state, action) => {
        state.modifiersLoading = false;
        state.modifiersData = action.payload;
        state.modifiersStatus = 'SUCCESS';
      })
      .addCase(getModifiersRequest.rejected, (state, action) => {
        state.modifiersLoading = false;
        state.modifiersError = action.payload;
        state.modifiersStatus = 'FAILED';
      });
  },
});

export const { clearFetchModifiersResponse } = getModifiersSlice.actions;

export const getModifiersSelector = (state: RootState) => state.GetModifiers;

export default getModifiersSlice.reducer;
