import { InputField } from '../../../components/atoms/InputField';
import { PrimaryButton } from '../../../components/atoms/PrimaryButton';
import { CustomSearchButton } from '../../../components/list/patients/visit/AddCPTModal';

export const BulkPayments: React.FC = () => {
  return (
    <>
      <div className="grid md:grid-cols-5 gap-4 mt-6">
        <InputField label="Batch ID" name="id" disabled />
        <InputField label="Payment Date" name="paymentDate" disabled />
        <InputField label="Check Number" name="checkNumber" disabled />
        <InputField label="Description" name="description" disabled />
        <InputField label="UnApplied" name="unapplied" disabled />
      </div>
      <div className="grid md:grid-cols-5 gap-4 mt-6 items-end">
        <div className="flex items-end">
          {' '}
          <InputField label="" name="patientName" placeholder="Patient Name" disabled />
          <CustomSearchButton />
        </div>

        <InputField label="" name="patientId" placeholder="Patient ID" />
        <PrimaryButton type="button" label="Submit" style={{ height: '40px', maxWidth: '100px' }} />
      </div>
    </>
  );
};
