import { useEffect, useRef, useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import { CommonAlert } from '../../components/atoms/Alert';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Button, Tabs, TabsRef } from 'flowbite-react';
import { OpenBatches } from '../../components/billing/batchPayment/OpenBatches';
import { ClosedBatches } from '../../components/billing/batchPayment/ClosedBatches';
import { AllBatches } from '../../components/billing/batchPayment/AllBatches';
import { CreateBatchPayment } from '../../components/billing/batchPayment/CreateBatchPaymentForm';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { AdvancedBatchSearch } from '../../components/tableSearch/AdvanceBatchSearch';
import { BatchView } from '../../components/billing/batchPayment/BatchView';
import { RightSheetLarge } from '../../components/rightSheet/RightSheetLarge';
import { BulkPayments } from './bulkPayments/BulkPayments';

import {
  getBatchPaymentsRequest,
  getBatchPaymentsSelector,
  clearFetchBatchPaymentsResponse,
} from '../../redux/slices/payments/batchPayments/getBatchPaymentSlice';
import {
  addBatchPaymentRequest,
  addBatchPaymentSelector,
  clearAddBatchPaymentResponse,
} from '../../redux/slices/payments/batchPayments/addBatchPaymentSlice';
import {
  updateBatchPaymentSelector,
  clearUpdateBatchPaymentResponse,
  updatebatchPaymentRequest,
} from '../../redux/slices/payments/batchPayments/editBatchPaymentSlice';
import {
  getBatchPaymentDetailsByIdRequest,
  getBatchPaymentDetailsByIdSelector,
  clearFetchBatchPaymentDetailsByIdResponse,
} from '../../redux/slices/payments/batchPayments/getBatchPaymentDetailsByIdSlice';
import { convertDate } from '../../utils/commonFunctions';

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'billingBatchPayment', label: 'Batch Payment', status: 'active', link: 'billing-batch-payment' },
];

const PAGE_SIZE = 10;

export const BatchPaymentPage = () => {
  const dispatch = useAppDispatch();

  const { addBatchPaymentStatus } = useAppSelector(addBatchPaymentSelector);
  const { fetchBatchPaymentsData, fetchBatchPaymentsStatus } = useAppSelector(getBatchPaymentsSelector);
  const { editBatchPaymentStatus, editBatchPaymentData } = useAppSelector(updateBatchPaymentSelector);
  const { fetchBatchPaymentDetailsByIdStatus, fetchBatchPaymentDetailsByIdData } = useAppSelector(
    getBatchPaymentDetailsByIdSelector
  );

  const tabsRef = useRef<TabsRef>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleViewModal, setVisibleViewModal] = useState(false);
  const [visibleBulkPaymentModal, setVisibleBulkPaymentModal] = useState(false);
  const [createFormData, setCreateFormData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [openBatchesData, setOpenBatchesData] = useState<any>([]);
  const [closedBatchesData, setClosedBatchesData] = useState<any>([]);
  const [allBatchesData, setAllBatchesData] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({ color: '', message: '', error: false });
  const [mode, setMode] = useState('CREATE');
  const [batchViewDataSource, setBatchViewDataSource] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<any>();

  // useEffect(() => {
  //   fetchBatchPayments('open');
  // }, []);

  // useEffect(() => {
  //   if (fetchBatchPaymentsStatus === 'SUCCESS') {
  //     if (activeTab === 0) {
  //       console.log('OPEN TRIGGERED');
  //       setOpenBatchesData(fetchBatchPaymentsData);
  //     } else if (activeTab === 1) {
  //       console.log('CLOSED TRIGGERED');
  //       setClosedBatchesData(fetchBatchPaymentsData);
  //     } else if (activeTab === 2) {
  //       console.log('ALL TRIGGERED');
  //       setAllBatchesData(fetchBatchPaymentsData);
  //     }
  //   } else if (fetchBatchPaymentsStatus === 'FAILED') {
  //   }
  //   dispatch(clearFetchBatchPaymentsResponse());
  // }, [fetchBatchPaymentsStatus]);

  // useEffect(() => {
  //   if (editBatchPaymentStatus === 'SUCCESS') {
  //   } else if (editBatchPaymentStatus === 'FAILED') {
  //   }
  //   dispatch(clearUpdateBatchPaymentResponse());
  // }, [editBatchPaymentStatus]);

  useEffect(() => {
    if (fetchBatchPaymentDetailsByIdStatus === 'SUCCESS') {
      setBatchViewDataSource(fetchBatchPaymentDetailsByIdData);
      setVisibleViewModal(true);
    }
    if (fetchBatchPaymentDetailsByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchBatchPaymentDetailsByIdResponse());
  }, [fetchBatchPaymentDetailsByIdStatus]);

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const visibleCreateBatch = () => {
    setVisibleModal(true);
  };

  const convertBeforeSubmit = (formData: any) => {
    const obj = {
      ...formData,
      checkDate: convertDate(formData?.checkDate),
      postingDate: convertDate(formData?.postingDate),
    };

    return obj;
  };

  const onSubmitCreateBatchPayment = () => {
    if (mode === 'CREATE') {
      dispatch(addBatchPaymentRequest(convertBeforeSubmit(createFormData)));
    } else if (mode === 'EDIT') {
      console.log('DAta to EDIT', convertBeforeSubmit(createFormData));
      let obj = {
        paymentId: selectedId,
        paymentData: convertBeforeSubmit(createFormData),
      };
      dispatch(updatebatchPaymentRequest(obj));
    }
  };

  const errorAlertOnClose = () => {};

  const onChangeCreateForm = (event: any) => {
    setCreateFormData((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onChangeCreateFormDate = (date: any, name: string) => {
    setCreateFormData((prev: any) => ({ ...prev, [name]: date }));
  };

  const handleTabChange = (tab: number) => {
    setActiveTab(tab);
    if (tab === 0) {
      fetchBatchPayments('open');
    } else if (tab === 1) {
      fetchBatchPayments('closed');
    } else if (tab === 2) {
      fetchBatchPayments('all');
    }
  };

  const fetchBatchPayments = (section: string, page = 0) => {
    // dispatch(getBatchPaymentsRequest({ section: section, page: page, size: PAGE_SIZE }));
  };

  const fetchBatchPaymentDetails = (id: number) => {
    // dispatch(getBatchPaymentDetailsByIdRequest({ paymentId: id }));
  };

  const onEdit = (event: any, rowIndex: any, dataSource: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setCreateFormData(dataSet);
    setMode('EDIT');
    setVisibleModal(true);
  };

  const onView = (event: any, rowIndex: any, dataSource: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setMode('VIEW');
    fetchBatchPaymentDetails(dataSet?.id);
    setVisibleViewModal(true);
  };

  const onApply = (event: any, rowIndex: any, dataSource: any) => {
    console.log('CLICKED');
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setMode('APPLY');
    setVisibleBulkPaymentModal(true);
  };

  const onLock = (event: any, rowIndex: any, dataSource: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setMode('LOCK');
  };

  const onCloseBatchModal = (close: boolean) => {
    setVisibleViewModal(close);
  };

  const onCloseModal = () => {
    setCreateFormData({});
    setVisibleModal(false);
  };

  const handleOnClose = () => {
    setVisibleBulkPaymentModal(false);
  };

  console.log('createFormData', createFormData);

  const handleSearch = () => {};

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic
        mainTitle="Batch Payment"
        enablePrimaryButton={true}
        breadCrumbArr={breadCrumbArr}
        onClick={visibleCreateBatch}
      />
      <AdvancedBatchSearch onSubmit={handleSearch} />
      <Tabs aria-label="Default tabs" ref={tabsRef} onActiveTabChange={(tab) => handleTabChange(tab)}>
        <Tabs.Item active title={`Open Batches (${10})`}>
          <OpenBatches
            // onEdit={onEdit}
            // onView={onView}
            // onApply={onApply}
            // onLock={onLock}
            onEdit={(event: any, rowIndex: any) => onEdit(event, rowIndex, openBatchesData)}
            onView={(event: any, rowIndex: any) => onView(event, rowIndex, openBatchesData)}
            onApply={(event: any, rowIndex: any) => onApply(event, rowIndex, openBatchesData)}
            onLock={(event: any, rowIndex: any) => onLock(event, rowIndex, openBatchesData)}
            openBatchesData={openBatchesData}
            fetchBatchPayments={fetchBatchPayments}
          />
        </Tabs.Item>
        <Tabs.Item title={`Closed Batches (${10})`}>
          <ClosedBatches
            // onEdit={onEdit}
            // onView={onView}
            // onApply={onApply}
            // onLock={onLock}
            onEdit={(event: any, rowIndex: any) => onEdit(event, rowIndex, closedBatchesData)}
            onView={(event: any, rowIndex: any) => onView(event, rowIndex, closedBatchesData)}
            onApply={(event: any, rowIndex: any) => onApply(event, rowIndex, closedBatchesData)}
            onLock={(event: any, rowIndex: any) => onLock(event, rowIndex, closedBatchesData)}
            closedBatchesData={closedBatchesData}
            fetchBatchPayments={fetchBatchPayments}
          />
        </Tabs.Item>
        <Tabs.Item title={`All Batches (${20})`}>
          <AllBatches
            // onEdit={onEdit}
            // onView={onView}
            // onApply={onApply}
            // onLock={onLock}
            onEdit={(event: any, rowIndex: any) => onEdit(event, rowIndex, allBatchesData)}
            onView={(event: any, rowIndex: any) => onView(event, rowIndex, allBatchesData)}
            onApply={(event: any, rowIndex: any) => onApply(event, rowIndex, allBatchesData)}
            onLock={(event: any, rowIndex: any) => onLock(event, rowIndex, allBatchesData)}
            allBatchesData={allBatchesData}
            fetchBatchPayments={fetchBatchPayments}
          />
        </Tabs.Item>
      </Tabs>

      {visibleModal && (
        <RightSheet
          onClose={onCloseModal}
          title={mode === 'EDIT' ? 'Edit Batch Payment' : 'Create Batch Payment'}
          submitButtonTitle="Save"
          cancelButtonTitle="Cancel"
          children={
            <CreateBatchPayment
              onChange={onChangeCreateForm}
              onChangeDate={onChangeCreateFormDate}
              createFormData={createFormData}
            />
          }
          onSubmit={onSubmitCreateBatchPayment}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
        />
      )}

      {visibleViewModal && (
        <BatchView
          openBatchView={visibleViewModal}
          setOpenBatchView={onCloseBatchModal}
          dataSource={batchViewDataSource}
        />
      )}

      {visibleBulkPaymentModal && (
        <RightSheetLarge
          title="Bulk Payments"
          enableStepper={false}
          enableFooterButtons={false}
          onClose={handleOnClose}
          children={<BulkPayments />}
        />
      )}
    </div>
  );
};
