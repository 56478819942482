import { Modal } from 'flowbite-react';
import { SelectInput } from '../../../atoms/SelectInput';
import { CommonButton } from '../../../atoms/CommonButton';
import { InputField } from '../../../atoms/InputField';
import { useEffect, useState } from 'react';
import { claimStatus, statusReason } from '../../../../constants/patientConstants';
import { TextArea } from '../../../atoms/TextArea';
import { TableContent } from '../../../table/TableContent';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import {
  clearUpdateVisitStatusResponse,
  updateVisitStatusRequest,
  updateVisitStatusSelector,
} from '../../../../redux/slices/patient/updateVisitStatusSlice';
import { CommonAlert } from '../../../atoms/Alert';
import { getSystemVisitStatusSelector } from '../../../../redux/slices/system/getSystemVisitStatusSlice';
import moment from 'moment';
import { validDateFormat } from '../../../../utils/commonFunctions';
import { getIpDataSelector } from '../../../../redux/slices/system/getIPDataSlice';

const columns = [
  {
    title: 'Date',
    dataIndex: 'created',
    key: 'created',
    render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'Comment',
    dataIndex: 'note',
    key: 'note',
    render: (text: string, record: any) => {
      return (
        <>
          <b>{record?.responsible}</b>: {record?.note}
        </>
      );
    },
  },
];

const dataSource = [
  {
    comment: 'Ahmed Mohideen: Claim is created!',
    date: '12/19/2023',
  },
  {
    comment: 'Ahmed Mohideen: Claim is created!',
    date: '12/19/2023',
  },
];

export const EditVisitStatus = ({
  editVisitStatusOpen,
  setOpenEditVisitStatus,
  visitId,
  getStatusHistory,
  statusHistoryDataSource,
  patientId,
  fetchPatientById,
  fetchVisits,
}: any) => {
  // console.log('=== statusHistoryDataSource ====', statusHistoryDataSource);

  const dispatch = useAppDispatch();
  const { updateVisitStatusError, updateVisitStatus, updateVisitStatusData } =
    useAppSelector(updateVisitStatusSelector);
  const { systemVisitData } = useAppSelector(getSystemVisitStatusSelector);
  const { ipDataPayload } = useAppSelector(getIpDataSelector);
  const [formData, setFormData] = useState<any>({});
  const [errorObject, setErrorObject] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (updateVisitStatus === 'SUCCESS') {
      fetchPatientById && fetchPatientById(patientId, 'visit');
      fetchVisits && fetchVisits();
      getStatusHistory(visitId);
      setFormData({});
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setOpenEditVisitStatus(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (updateVisitStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: updateVisitStatusError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearUpdateVisitStatusResponse());
  }, [updateVisitStatus]);

  const handleFieldValueChange = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const validation = (data: any) => {
    let err: any = {};

    if (!data?.status) {
      err.status = 'Required !';
    } else if (!data?.reason) {
      err.reason = 'Required !';
    }
    // else if (!data?.description) {
    //   err.description = 'Required !';
    // }
    setErrorObject(err);
    return err;
  };

  const onSubmit = () => {
    const validationData = validation(formData);
    if (Object.keys(validationData)?.length > 0) {
      return;
    } else {
      let beforeSubmit = {
        status: formData?.status ? parseInt(formData?.status) : null,
        reason: formData?.reason ? parseInt(formData?.reason) : null,
        note: formData?.note ?? '',
        ip: ipDataPayload?.ip,
      };
      let obj = {
        visitId: visitId,
        visitData: beforeSubmit,
      };
      dispatch(updateVisitStatusRequest(obj));
    }
  };

  const transformVisitStatus = (data: any) => {
    return [
      ...data?.map((el: any) => ({
        id: el?.value,
        name: el?.label,
      })),
    ];
  };

  return (
    <>
      <Modal show={editVisitStatusOpen} onClose={() => setOpenEditVisitStatus(false)}>
        <Modal.Header>Edit Visit Status</Modal.Header>
        <Modal.Body>
          {visibleAlert && !alertObj?.error && (
            <CommonAlert
              alertClassName={'w-3/3'}
              color={alertObj?.color}
              message={alertObj?.message}
              onClose={() => setVisibleAlert(false)}
            />
          )}
          <div className="grid md:grid-cols-2 gap-5 mt-2">
            <SelectInput
              label={'Status'}
              name="status"
              onChange={handleFieldValueChange}
              options={transformVisitStatus(systemVisitData)}
              // defaultValue={visitFormEditData?.status ? visitFormEditData?.status.toString() : undefined}
              error={errorObject && errorObject?.status}
              errorMessage={errorObject && errorObject?.status}
            />
            <SelectInput
              label={'Reason'}
              name="reason"
              onChange={handleFieldValueChange}
              options={statusReason}
              // defaultValue={visitFormEditData?.status ? visitFormEditData?.status.toString() : undefined}
              error={errorObject && errorObject?.reason}
              errorMessage={errorObject && errorObject?.reason}
            />
          </div>
          <div className="grid md:grid-cols-1 gap-5 mt-2">
            <TextArea
              onChange={handleFieldValueChange}
              name="note"
              label="Note"
              //   error={errorObject && errorObject?.patientVisitStatus}
              //   errorMessage={errorObject && errorObject?.patientVisitStatus}
            />
          </div>
          <div className="flex gap-5 mt-5">
            <CommonButton
              type="button"
              label={'Close'}
              buttonType="secondary"
              onClick={() => setOpenEditVisitStatus(false)}
            />
            <CommonButton type="button" label={'Save'} buttonType="primary" onClick={onSubmit} />
          </div>
          {statusHistoryDataSource?.length > 0 && (
            <div>
              <TableContent columns={columns} dataSource={statusHistoryDataSource} />
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};
function fetchPatientById(selectedId: any, arg1: string) {
  throw new Error('Function not implemented.');
}
