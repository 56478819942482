import { useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { TableSearch } from '../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';

const columns = [
  {
    title: 'Doctor',
    dataIndex: 'doctor',
    key: 'doctor',
  },
  {
    title: '# of Visits',
    dataIndex: 'noOfVisits',
    key: 'noOfVisits',
  },
  {
    title: 'Charges',
    dataIndex: 'charges',
    key: 'charges',
  },
  {
    title: 'Allowed Amount',
    dataIndex: 'allowedAmount',
    key: 'allowedAmount',
  },
  {
    title: 'Charge Adjust',
    dataIndex: 'chargeAdjust',
    key: 'chargeAdjust',
  },
  {
    title: 'Insurance Write Off',
    dataIndex: 'insuranceWriteOff',
    key: 'insuranceWriteOff',
  },
  {
    title: 'Net Charges',
    dataIndex: 'netCharges',
    key: 'netCharges',
  },
  {
    title: 'Personal Receipts',
    dataIndex: 'personalReceipts',
    key: 'personalReceipts',
  },
  {
    title: 'Insurance Receipts',
    dataIndex: 'insuranceReceipts',
    key: 'insuranceReceipts',
  },
  {
    title: 'Receipt Adjust',
    dataIndex: 'receiptAdjust',
    key: 'receiptAdjust',
  },
  {
    title: 'Net Receipts',
    dataIndex: 'netReceipts',
    key: 'netReceipts',
  },
  {
    title: 'Charge In A/R',
    dataIndex: 'chargeInAR',
    key: 'chargeInAR',
  },
];

const dataSource = [
  {
    doctor: 'John',
    noOfVisits: '1',
    charges: '$109.00',
    allowedAmount: '$0.00	',
    chargeAdjust: '$0.00',
    insuranceWriteOff: '$0.00	',
    netCharges: '$109.00',
    personalReceipts: '$0.00',
    insuranceReceipts: '$0.00',
    receiptAdjust: '$0.00',
    netReceipts: '$0.00',
    chargeInAR: '$109.00',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'doctor-financial', label: 'Doctor Financial Report', status: 'active', link: 'doctor-financial' },
];

export const DoctorFinancialReportPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [dataSource, setDataSource] = useState<any>([]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Doctor Financial Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      {/* <TableSearch /> */}
      {dataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
        </div>
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
