import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientFectchByIdInitialPropsInterface,
  PatientFetchByIdInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import { getPatientByIdAPIRequest, getPatientsAPIRequest } from '../../../api/patient/patient';

const initialState: PatientFectchByIdInitialPropsInterface = {
  fetchPatientByIdLoading: false,
  fetchPatientByIdData: {},
  fetchPatientByIdStatus: 'IDLE',
  fetchPatientByIdError: null,
};

export const getPatientByIdRequest: any = createAsyncThunk(
  'patients/get/id',
  async (searchParams: PatientFetchByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientByIdSlice = createSlice({
  name: 'fetchPatientById',
  initialState,
  reducers: {
    clearFetchPatientByIdResponse: (state) => {
      state.fetchPatientByIdLoading = false;
      state.fetchPatientByIdData = {};
      state.fetchPatientByIdStatus = 'IDLE';
      state.fetchPatientByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientByIdRequest.pending, (state, action) => {
        state.fetchPatientByIdLoading = true;
        state.fetchPatientByIdStatus = 'PENDING';
      })
      .addCase(getPatientByIdRequest.fulfilled, (state, action) => {
        state.fetchPatientByIdLoading = false;
        state.fetchPatientByIdStatus = 'SUCCESS';
        state.fetchPatientByIdData = action.payload;
      })
      .addCase(getPatientByIdRequest.rejected, (state, action) => {
        state.fetchPatientByIdLoading = false;
        state.fetchPatientByIdError = action.payload;
        state.fetchPatientByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientByIdResponse } = fetchPatientByIdSlice.actions;

export const getPatientsByIdSelector = (state: RootState) => state.GetPatientById;

export default fetchPatientByIdSlice.reducer;
