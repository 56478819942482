import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { CPTCodesAddInterface, CPTCodesAddPropsInterface } from '../../../interfaces/codesInterfaces';
import { addCPTCodeAPIRequest } from '../../../api/system/code';
import { AddCPTCodeInterface } from '../../../interfaces/systemInterfaces';

const initialState: CPTCodesAddPropsInterface = {
  addCPTCodeLoading: false,
  addCPTCodeData: {},
  addCPTCodeStatus: 'IDLE',
  addCPTCodeError: null,
};

export const addCPTCodeRequest: any = createAsyncThunk(
  'codes/cpt/add',
  async (code: AddCPTCodeInterface, thunkAPI: any) => {
    try {
      const response: any = await addCPTCodeAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addCPTCodeSlice = createSlice({
  name: 'addCPTCode',
  initialState,
  reducers: {
    clearAddCPTCodeResponse: (state) => {
      state.addCPTCodeLoading = false;
      state.addCPTCodeStatus = 'IDLE';
      state.addCPTCodeData = {};
      state.addCPTCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCPTCodeRequest.pending, (state, action) => {
        state.addCPTCodeLoading = true;
        state.addCPTCodeStatus = 'PENDING';
      })
      .addCase(addCPTCodeRequest.fulfilled, (state, action) => {
        state.addCPTCodeLoading = false;
        state.addCPTCodeStatus = 'SUCCESS';
        state.addCPTCodeData = action.payload;
      })
      .addCase(addCPTCodeRequest.rejected, (state, action) => {
        state.addCPTCodeLoading = false;
        state.addCPTCodeStatus = 'FAILED';
        state.addCPTCodeError = action.payload;
      });
  },
});

export const { clearAddCPTCodeResponse } = addCPTCodeSlice.actions;

export const addCPTCodeSelector = (state: RootState) => state.AddCPTCode;

export default addCPTCodeSlice.reducer;
