import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PatientVisitEditPropsInterface, PatientVisitUpdateInterface } from '../../../interfaces/patientInterface';
import { updatePAtientVisitAPIRequest } from '../../../api/patient/patientVisit';

const initialState: PatientVisitEditPropsInterface = {
  editPatientVisitLoading: false,
  editPatientVisitData: {},
  editPatientVisitError: null,
  editPatientVisitStatus: 'IDLE',
};

export const editPatientVisitRequest: any = createAsyncThunk(
  'patient/visit/edit',
  async (patient: PatientVisitUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updatePAtientVisitAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const editPatientVisitSlice = createSlice({
  name: 'editPatientVisit',
  initialState,
  reducers: {
    clearEditPatientVisitResponse: (state) => {
      state.editPatientVisitLoading = false;
      state.editPatientVisitStatus = 'IDLE';
      state.editPatientVisitData = {};
      state.editPatientVisitError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPatientVisitRequest.pending, (state, action) => {
        state.editPatientVisitLoading = true;
        state.editPatientVisitStatus = 'PENDING';
      })
      .addCase(editPatientVisitRequest.fulfilled, (state, action) => {
        state.editPatientVisitData = action.payload;
        state.editPatientVisitStatus = 'SUCCESS';
        state.editPatientVisitLoading = false;
      })
      .addCase(editPatientVisitRequest.rejected, (state, action) => {
        state.editPatientVisitLoading = false;
        state.editPatientVisitError = action.payload;
        state.editPatientVisitStatus = 'FAILED';
      });
  },
});

export const { clearEditPatientVisitResponse } = editPatientVisitSlice.actions;

export const editPatientVisitSelector = (state: RootState) => state.EditPatientVisit;

export default editPatientVisitSlice.reducer;
