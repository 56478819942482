import { Modal } from 'flowbite-react';
import { CommonButton } from '../../atoms/CommonButton';
import { InputField } from '../../atoms/InputField';
import { useState } from 'react';
import { Calendar } from '../../atoms/Calendar';
import { DatePickerField } from '../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../utils/commonFunctions';

export const DownloadERAModal = ({ openDownloadERA, setOpenDownloadERA }: any) => {
  const [formData, setFormData] = useState<any>({});

  const onChangeFields = (event: any) => {
    setFormData((prev: any) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onChangeDate = (date: any, name: string) => {
    setFormData((prev: any) => ({ ...prev, [name]: date }));
  };

  const handleSubmit = () => {};

  return (
    <Modal show={openDownloadERA} onClose={() => setOpenDownloadERA(false)}>
      <Modal.Header>Download ERA</Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-2 gap-3 mt-6">
          <InputField label="Check Number" name="checkNumber" onChange={onChangeFields} />
          {/* <Calendar label="Received Date" onSelectedDateChanged={(date) => onChangeDate(date, 'receivedDate')} /> */}
          <DatePickerField
            label="Received Date"
            selectedDate={formatDatePickerDate(formData?.receivedDate)}
            onChange={(date: Date | null) => {
              if (date) {
                onChangeDate(date, 'receivedDate');
              }
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CommonButton type="button" label={'No'} buttonType="secondary" onClick={() => setOpenDownloadERA(false)} />
        <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
