import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchBatchPaymentDetailsByIdInterface,
  FetchBatchPaymentDetailsByIdPropsInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getBatchPaymentDetailsByIdAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: FetchBatchPaymentDetailsByIdPropsInterface = {
  fetchBatchPaymentDetailsByIdLoading: false,
  fetchBatchPaymentDetailsByIdData: [],
  fetchBatchPaymentDetailsByIdError: null,
  fetchBatchPaymentDetailsByIdStatus: 'IDLE',
};

export const getBatchPaymentDetailsByIdRequest: any = createAsyncThunk(
  'batch-payment/get/id',
  async (searchParams: FetchBatchPaymentDetailsByIdInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getBatchPaymentDetailsByIdAPIRequest(searchParams);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchBatchPaymentDetailsByIdSlice = createSlice({
  name: 'fetchBatchPaymentDetailsById',
  initialState,
  reducers: {
    clearFetchBatchPaymentDetailsByIdResponse: (state) => {
      state.fetchBatchPaymentDetailsByIdLoading = false;
      state.fetchBatchPaymentDetailsByIdData = [];
      state.fetchBatchPaymentDetailsByIdStatus = 'IDLE';
      state.fetchBatchPaymentDetailsByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBatchPaymentDetailsByIdRequest.pending, (state, action) => {
        state.fetchBatchPaymentDetailsByIdLoading = true;
        state.fetchBatchPaymentDetailsByIdStatus = 'PENDING';
      })
      .addCase(getBatchPaymentDetailsByIdRequest.fulfilled, (state, action) => {
        state.fetchBatchPaymentDetailsByIdLoading = false;
        state.fetchBatchPaymentDetailsByIdStatus = 'SUCCESS';
        state.fetchBatchPaymentDetailsByIdData = action.payload;
      })
      .addCase(getBatchPaymentDetailsByIdRequest.rejected, (state, action) => {
        state.fetchBatchPaymentDetailsByIdLoading = false;
        state.fetchBatchPaymentDetailsByIdStatus = 'FAILED';
        state.fetchBatchPaymentDetailsByIdError = action.payload;
      });
  },
});

export const { clearFetchBatchPaymentDetailsByIdResponse } = fetchBatchPaymentDetailsByIdSlice.actions;

export const getBatchPaymentDetailsByIdSelector = (state: RootState) => state.GetBatchPaymentsDetails;

export default fetchBatchPaymentDetailsByIdSlice.reducer;
