import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { CreateClaimInitialInterface, CreateClaimInterface } from '../../../interfaces/billingInterface';
import { getVisitsAPIRequest } from '../../../api/billing/visits';
import { createClaimAPIRequest } from '../../../api/billing/claims';

const initialState: CreateClaimInitialInterface = {
  claimCreateLoading: false,
  claimCreateData: {},
  claimCreateError: null,
  claimCreateStatus: 'IDLE',
};

export const createClaimRequest: any = createAsyncThunk(
  'create/claim/billing',
  async (claimData: CreateClaimInterface, thunkAPI: any) => {
    try {
      const response: any = await createClaimAPIRequest(claimData);
      console.log(response);
      return response;
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const createClaimSlice = createSlice({
  name: 'createClaim',
  initialState,
  reducers: {
    clearCreateClaimResponse: (state) => {
      state.claimCreateLoading = false;
      state.claimCreateStatus = 'IDLE';
      state.claimCreateData = {};
      state.claimCreateError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClaimRequest.pending, (state, action) => {
        state.claimCreateLoading = true;
        state.claimCreateStatus = 'PENDING';
      })
      .addCase(createClaimRequest.fulfilled, (state, action) => {
        state.claimCreateLoading = false;
        state.claimCreateData = action.payload;
        state.claimCreateStatus = 'SUCCESS';
      })
      .addCase(createClaimRequest.rejected, (state, action) => {
        state.claimCreateLoading = false;
        state.claimCreateError = action.payload;
        state.claimCreateStatus = 'FAILED';
      });
  },
});

export const { clearCreateClaimResponse } = createClaimSlice.actions;

export const createClaimSelector = (state: RootState) => state.CreateClaim;

export default createClaimSlice.reducer;
