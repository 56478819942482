import { Button, Modal } from 'flowbite-react';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

export const MessageModal = ({ openMessage, setOpenMessage, content, submitButtonTitle }: any) => {
  return (
    <Modal show={openMessage} onClose={() => setOpenMessage(false)} size="md">
      <Modal.Body>
        {/* <div className="flex item-center justify-center">{content}</div> */}
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{content}</h3>
          <div className="flex justify-center gap-4">
            <CommonButton
              type="button"
              label={submitButtonTitle}
              buttonType="primary"
              onClick={() => setOpenMessage(false)}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
