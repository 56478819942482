import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientAddressDeleteByIdInitialPropsInterface,
  PatientAddressGetByIdInterface,
  PatientFectchByIdInitialPropsInterface,
  PatientFetchByIdInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import { getPatientByIdAPIRequest, getPatientsAPIRequest } from '../../../api/patient/patient';
import { deletePatientAddressByIdAPIRequest } from '../../../api/patient/patientAddress';

const initialState: PatientAddressDeleteByIdInitialPropsInterface = {
  deletePatientAddressByIdLoading: false,
  deletePatientAddressByIdData: {},
  deletePatientAddressByIdStatus: 'IDLE',
  deletePatientAddressByIdError: null,
};

export const deletePatientAddressByIdRequest: any = createAsyncThunk(
  'patients/address/delete/id',
  async (searchParams: PatientAddressGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await deletePatientAddressByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deletePatientAddressByIdSlice = createSlice({
  name: 'deletePatientAddressById',
  initialState,
  reducers: {
    clearDeletePatientAddressByIdResponse: (state) => {
      state.deletePatientAddressByIdLoading = false;
      state.deletePatientAddressByIdData = {};
      state.deletePatientAddressByIdStatus = 'IDLE';
      state.deletePatientAddressByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePatientAddressByIdRequest.pending, (state, action) => {
        state.deletePatientAddressByIdLoading = true;
        state.deletePatientAddressByIdStatus = 'PENDING';
      })
      .addCase(deletePatientAddressByIdRequest.fulfilled, (state, action) => {
        state.deletePatientAddressByIdLoading = false;
        state.deletePatientAddressByIdStatus = 'SUCCESS';
        state.deletePatientAddressByIdData = action.payload;
      })
      .addCase(deletePatientAddressByIdRequest.rejected, (state, action) => {
        state.deletePatientAddressByIdLoading = false;
        state.deletePatientAddressByIdError = action.payload;
        state.deletePatientAddressByIdStatus = 'FAILED';
      });
  },
});

export const { clearDeletePatientAddressByIdResponse } = deletePatientAddressByIdSlice.actions;

export const deletePatientAddressByIdSelector = (state: RootState) => state.DeletePatientAddressById;

export default deletePatientAddressByIdSlice.reducer;
