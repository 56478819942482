import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { InputFieldPropsInterface } from '../../interfaces/atomInterfaces';
import { Tooltip } from 'flowbite-react';
import { BsInfoCircle } from 'react-icons/bs';

export const DateRangeCalendar: React.FC<InputFieldPropsInterface> = ({
  label = '',
  placeholder = '',
  required = false,
  onChange = () => {},
  name = '',
  value,
  defaultValue,
  disabled,
  error = false,
  tooltip = '',
  errorMessage = '',
  initialDateRange = [{ startDate: new Date(), endDate: new Date(), key: 'selection' }],
  className = 'bg-gray1 border border-gray2 text-gray-900 text-sm rounded-lg focus:ring-1 focus:ring-primaryDefault focus:border-primaryDefault outline-0 block w-full p-2.5 dark:bg-gray9 dark:border-gray8 dark:placeholder-gray4 dark:text-white dark:focus:ring-primaryDefault dark:focus:border-primaryDefault',
}) => {
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    // Default state
    {
      startDate: new Date(),
      endDate: new Date(), // Use null if you don't want to have endDate
      key: 'selection',
    },
  ]);

  // console.log('=== date - range ===', date);

  return (
    <div style={{ position: 'relative' }}>
      <label
        htmlFor="input_field"
        className="block mb-2 text-sm font-normal text-gray-900 dark:text-white flex items-center"
      >
        {label} {required && <span className="text-red-500">*</span>}{' '}
        {tooltip && (
          <span className="ml-1">
            <Tooltip content={tooltip} placement="top">
              <BsInfoCircle className="text-gray-500 cursor-pointer" />
            </Tooltip>
          </span>
        )}
      </label>
      <span className={className} onClick={() => setOpenDate(!openDate)}>{`${format(
        initialDateRange[0].startDate,
        'MM/dd/yyyy'
      )} - ${format(initialDateRange[0].endDate, 'MM/dd/yyy')}`}</span>
      {openDate && (
        <div style={{ position: 'absolute', zIndex: 10 }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item: any) => setDate([item.selection])} // default implementation (item: any) => setDate([item.selection])
            moveRangeOnFirstSelection={false}
            ranges={date}
            className="date_range"
          />
        </div>
      )}
    </div>
  );
};
