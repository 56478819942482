import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  InsuranceDeleteByIdInterface,
  InsuranceDeleteByIdPropsInterface,
} from '../../../interfaces/insuranceInterface';

const initialState: InsuranceDeleteByIdPropsInterface = {
  deleteInsuranceByIdLoading: false,
  deleteInsuranceByIdData: {},
  deleteInsuranceByIdStatus: 'IDLE',
  deleteInsuranceByIdError: null,
};

export const deleteInsuranceRequest: any = createAsyncThunk(
  'insurance/delete',
  async (searchParams: InsuranceDeleteByIdInterface, thunkAPI: any) => {
    try {
      // const response: any = await deleteInsuranceAPIRequest(searchParams)
      // return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteInsuranceSlice = createSlice({
  name: 'deleteInsurance',
  initialState,
  reducers: {
    clearDeleteInsuranceResponse: (state) => {
      state.deleteInsuranceByIdLoading = false;
      state.deleteInsuranceByIdData = {};
      state.deleteInsuranceByIdStatus = 'IDLE';
      state.deleteInsuranceByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteInsuranceRequest.pending, (state, action) => {
        state.deleteInsuranceByIdLoading = true;
        state.deleteInsuranceByIdStatus = 'PENDING';
      })
      .addCase(deleteInsuranceRequest.fulfilled, (state, action) => {
        state.deleteInsuranceByIdLoading = false;
        state.deleteInsuranceByIdStatus = 'SUCCESS';
        state.deleteInsuranceByIdData = action.payload;
      })
      .addCase(deleteInsuranceRequest.rejected, (state, action) => {
        state.deleteInsuranceByIdLoading = false;
        state.deleteInsuranceByIdStatus = 'FAILED';
        state.deleteInsuranceByIdError = action.payload;
      });
  },
});

export const { clearDeleteInsuranceResponse } = deleteInsuranceSlice.actions;

export const deleteInsuranceSelector = (state: RootState) => state.DeleteInsurance;

export default deleteInsuranceSlice.reducer;
