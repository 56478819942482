import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CPTCodeUpdateInterface,
  CopyVisitPropsInterface,
  UpdateVisitCPTCodePropsInterface,
  UpdateVisitStatusPropsInterface,
  VisitCopyCreateInterface,
  VisitStatusUpdateInterface,
} from '../../../interfaces/patientInterface';
import {
  copyVisitRecordAPIRequest,
  updateVisitCPTCodeAPIRequest,
  updateVisitStatusAPIRequest,
} from '../../../api/patient/patientVisit';

const initialState: CopyVisitPropsInterface = {
  copyVisitLoading: false,
  copyVisitData: {},
  copyVisitError: null,
  copyVisitStatus: 'IDLE',
};

export const copyVisitRecordRequest: any = createAsyncThunk(
  'copy/visit',
  async (visit: VisitCopyCreateInterface, thunkAPI: any) => {
    try {
      const response: any = await copyVisitRecordAPIRequest(visit);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const copyVisitSlice = createSlice({
  name: 'copyVisit',
  initialState,
  reducers: {
    clearCopyVisitResponse: (state) => {
      state.copyVisitLoading = false;
      state.copyVisitStatus = 'IDLE';
      state.copyVisitData = {};
      state.copyVisitError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(copyVisitRecordRequest.pending, (state, action) => {
        state.copyVisitLoading = true;
        state.copyVisitStatus = 'PENDING';
      })
      .addCase(copyVisitRecordRequest.fulfilled, (state, action) => {
        state.copyVisitData = action.payload;
        state.copyVisitStatus = 'SUCCESS';
        state.copyVisitLoading = false;
      })
      .addCase(copyVisitRecordRequest.rejected, (state, action) => {
        state.copyVisitLoading = false;
        state.copyVisitError = action.payload;
        state.copyVisitStatus = 'FAILED';
      });
  },
});

export const { clearCopyVisitResponse } = copyVisitSlice.actions;

export const copyVisitSelector = (state: RootState) => state.CopyVisit;

export default copyVisitSlice.reducer;
