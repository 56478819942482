import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientInsuranceFetchByIdInitialPropsInterface,
  PatientInsuranceFetchByTypeInitialPropsInterface,
  PatientInsuranceGetByIdInterface,
  PatientInsuranceGetByTypeInterface,
} from '../../../interfaces/patientInterface';
import {
  getPatientInsuranceByIdAPIRequest,
  getPatientInsuranceByTypeAPIRequest,
} from '../../../api/patient/patientInsurance';

const initialState: PatientInsuranceFetchByTypeInitialPropsInterface = {
  fetchPatientInsuranceByTypeLoading: false,
  fetchPatientInsuranceByTypeData: [],
  fetchPatientInsuranceByTypeStatus: 'IDLE',
  fetchPatientInsuranceByTypeError: null,
};

export const getPatientInsuranceByTypeRequest: any = createAsyncThunk(
  'patients/get/insurance/type',
  async (searchParams: PatientInsuranceGetByTypeInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientInsuranceByTypeAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientInsuranceByTypeSlice = createSlice({
  name: 'fetchPatientInsuranceByType',
  initialState,
  reducers: {
    clearFetchPatientInsuranceByTypeResponse: (state) => {
      state.fetchPatientInsuranceByTypeLoading = false;
      state.fetchPatientInsuranceByTypeData = {};
      state.fetchPatientInsuranceByTypeStatus = 'IDLE';
      state.fetchPatientInsuranceByTypeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientInsuranceByTypeRequest.pending, (state, action) => {
        state.fetchPatientInsuranceByTypeLoading = true;
        state.fetchPatientInsuranceByTypeStatus = 'PENDING';
      })
      .addCase(getPatientInsuranceByTypeRequest.fulfilled, (state, action) => {
        state.fetchPatientInsuranceByTypeLoading = false;
        state.fetchPatientInsuranceByTypeStatus = 'SUCCESS';
        state.fetchPatientInsuranceByTypeData = action.payload;
      })
      .addCase(getPatientInsuranceByTypeRequest.rejected, (state, action) => {
        state.fetchPatientInsuranceByTypeLoading = false;
        state.fetchPatientInsuranceByTypeError = action.payload;
        state.fetchPatientInsuranceByTypeStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientInsuranceByTypeResponse } = fetchPatientInsuranceByTypeSlice.actions;

export const getPatientInsuranceByTypeSelector = (state: RootState) => state.FetchPatientInsuranceByType;

export default fetchPatientInsuranceByTypeSlice.reducer;
