import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// import {
//   getProviderByIdAPIRequest,
//   getProvidersAPIRequest,
// } from "../../../api/patient/patient";
//import { getProviderFacilitiesByIdAPIRequest } from "../../../api/patient/patientFacilities";
import {
  ProviderFacilitiesFetchByIdInitialPropsInterface,
  ProviderFacilitiesGetByIdInterface,
} from "../../../interfaces/providersInterface";

const initialState: ProviderFacilitiesFetchByIdInitialPropsInterface = {
  fetchProviderFacilitiesByIdLoading: false,
  fetchProviderFacilitiesByIdData: {},
  fetchProviderFacilitiesByIdStatus: "IDLE",
  fetchProviderFacilitiesByIdError: null,
};

export const getProviderFacilitiesByIdRequest: any = createAsyncThunk(
  "providers/get/facilities/id",
  async (searchParams: ProviderFacilitiesGetByIdInterface, thunkAPI: any) => {
    try {
      // const response: any = await getProviderFacilitiesByIdAPIRequest(
      //   searchParams
      // );
      // return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchProviderFacilitiesByIdSlice = createSlice({
  name: "fetchProviderFacilitiesById",
  initialState,
  reducers: {
    clearFetchProviderFacilitiesByIdResponse: (state) => {
      state.fetchProviderFacilitiesByIdLoading = false;
      state.fetchProviderFacilitiesByIdData = {};
      state.fetchProviderFacilitiesByIdStatus = "IDLE";
      state.fetchProviderFacilitiesByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderFacilitiesByIdRequest.pending, (state, action) => {
        state.fetchProviderFacilitiesByIdLoading = true;
        state.fetchProviderFacilitiesByIdStatus = "PENDING";
      })
      .addCase(getProviderFacilitiesByIdRequest.fulfilled, (state, action) => {
        state.fetchProviderFacilitiesByIdLoading = false;
        state.fetchProviderFacilitiesByIdStatus = "SUCCESS";
        state.fetchProviderFacilitiesByIdData = action.payload;
      })
      .addCase(getProviderFacilitiesByIdRequest.rejected, (state, action) => {
        state.fetchProviderFacilitiesByIdLoading = false;
        state.fetchProviderFacilitiesByIdError = action.payload;
        state.fetchProviderFacilitiesByIdStatus = "FAILED";
      });
  },
});

export const { clearFetchProviderFacilitiesByIdResponse } =
  fetchProviderFacilitiesByIdSlice.actions;

export const getProviderFacilitiesByIdSelector = (state: RootState) =>
  state.FetchProviderFacilitiesById;

export default fetchProviderFacilitiesByIdSlice.reducer;
