import { FaFileExcel } from 'react-icons/fa';
import { Badge } from '../atoms/Badge';
import { CommonButton } from '../atoms/CommonButton';
import { IconButton } from '../atoms/IconButton';
import { PrimaryButton } from '../atoms/PrimaryButton';
import './tableActionArea.css';

export const ClaimsTableActionArea = ({ claimCount, sendAllClaims }: any) => {
  return (
    <>
      <div className="table-action-area">
        <div className="table-action-area__left-actions">
          <div className="flex gap-2">
            <div>Claim Count - {claimCount}</div>
          </div>
        </div>
        <div className="table-action-area__right-actions">
          <div className="flex gap-2">
            <CommonButton buttonType="primary" label="Send all claims" onClick={sendAllClaims} />
          </div>
          <div className="flex gap-2">
            <PrimaryButton
              label="Export to Excel"
              icon={<FaFileExcel style={{ fontSize: '18px' }} />}
              style={{ height: '40px', maxWidth: '100px' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
