import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddVisitICDCodeInitialPropsInterface,
  AddVisitICDCodePropsInterface,
} from '../../../interfaces/patientInterface';
import { addVisitICDCodeAPIRequest } from '../../../api/patient/patientVisit';

const initialState: AddVisitICDCodeInitialPropsInterface = {
  addVisitICDCodeLoading: false,
  addVisitICDCodeData: {},
  addVisitICDCodeError: null,
  addVisitICDCodeStatus: 'IDLE',
};

export const addVisitICDCodeRequest: any = createAsyncThunk(
  'add/visit/icd',
  async (patient: AddVisitICDCodePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await addVisitICDCodeAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addVisitICDCodeSlice = createSlice({
  name: 'addVisitICDCode',
  initialState,
  reducers: {
    clearAddVisitICDCodeResponse: (state) => {
      state.addVisitICDCodeLoading = false;
      state.addVisitICDCodeStatus = 'IDLE';
      state.addVisitICDCodeData = {};
      state.addVisitICDCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addVisitICDCodeRequest.pending, (state, action) => {
        state.addVisitICDCodeLoading = true;
        state.addVisitICDCodeStatus = 'PENDING';
      })
      .addCase(addVisitICDCodeRequest.fulfilled, (state, action) => {
        state.addVisitICDCodeData = action.payload;
        state.addVisitICDCodeStatus = 'SUCCESS';
        state.addVisitICDCodeLoading = false;
      })
      .addCase(addVisitICDCodeRequest.rejected, (state, action) => {
        state.addVisitICDCodeLoading = false;
        state.addVisitICDCodeError = action.payload;
        state.addVisitICDCodeStatus = 'FAILED';
      });
  },
});

export const { clearAddVisitICDCodeResponse } = addVisitICDCodeSlice.actions;

export const addVisitICDCodeSelector = (state: RootState) => state.AddVisitICDCode;

export default addVisitICDCodeSlice.reducer;
