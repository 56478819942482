import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PatientFetchInitialInterface, PatientFetchInterface } from '../../../interfaces/patientInterface';
import { getPatientsAPIRequest } from '../../../api/patient/patient';

const initialState: PatientFetchInitialInterface = {
  loading: false,
  patientData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  error: null,
  success: false,
  status: 'IDLE',
};

export const getPatientsRequest: any = createAsyncThunk(
  'patients/get',
  async (searchParams: PatientFetchInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientsAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPatientsSlice = createSlice({
  name: 'fetchPatients',
  initialState,
  reducers: {
    clearFetchPatientResponse: (state) => {
      state.loading = false;
      state.status = 'IDLE';
      state.patientData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientsRequest.pending, (state, action) => {
        state.loading = true;
        state.status = 'PENDING';
      })
      .addCase(getPatientsRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.patientData = action.payload;
      })
      .addCase(getPatientsRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'FAILED';
      });
  },
});

export const { clearFetchPatientResponse } = getPatientsSlice.actions;

export const getPatientsSelector = (state: RootState) => state.GetPatients;

export default getPatientsSlice.reducer;
