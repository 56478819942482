import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { DeleteVisitICDCodeInterface, DeleteVisitICDCodePropsInterface } from '../../../interfaces/patientInterface';
import { deleteVisitICDCodeAPIRequest } from '../../../api/patient/patientVisit';

const initialState: DeleteVisitICDCodePropsInterface = {
  deleteVisitICDCodeLoading: false,
  deleteVisitICDCodeData: {},
  deleteVisitICDCodeStatus: 'IDLE',
  deleteVisitICDCodeError: null,
};

export const deleteVisitICDCodeRequest: any = createAsyncThunk(
  'delete/vists/icd',
  async (searchParams: DeleteVisitICDCodeInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteVisitICDCodeAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteVisitICDCodeSlice = createSlice({
  name: 'deleteVisitICDCode',
  initialState,
  reducers: {
    clearDeleteVisitICDCodeResponse: (state) => {
      state.deleteVisitICDCodeLoading = false;
      state.deleteVisitICDCodeData = {};
      state.deleteVisitICDCodeStatus = 'IDLE';
      state.deleteVisitICDCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteVisitICDCodeRequest.pending, (state, action) => {
        state.deleteVisitICDCodeLoading = true;
        state.deleteVisitICDCodeStatus = 'PENDING';
      })
      .addCase(deleteVisitICDCodeRequest.fulfilled, (state, action) => {
        state.deleteVisitICDCodeLoading = false;
        state.deleteVisitICDCodeStatus = 'SUCCESS';
        state.deleteVisitICDCodeData = action.payload;
      })
      .addCase(deleteVisitICDCodeRequest.rejected, (state, action) => {
        state.deleteVisitICDCodeLoading = false;
        state.deleteVisitICDCodeError = action.payload;
        state.deleteVisitICDCodeStatus = 'FAILED';
      });
  },
});

export const { clearDeleteVisitICDCodeResponse } = deleteVisitICDCodeSlice.actions;

export const deleteVisitCPTCodeSelector = (state: RootState) => state.DeleteVisitICDCode;

export default deleteVisitICDCodeSlice.reducer;
