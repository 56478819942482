import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// import {
//   getProviderByIdAPIRequest,
//   getProvidersAPIRequest,
// } from "../../../api/patient/patient";
//import { getProviderAddressByIdAPIRequest } from "../../../api/patient/patientAddress";
import {
  ProviderAddressFetchByIdInitialPropsInterface,
  ProviderAddressGetByIdInterface,
} from "../../../interfaces/providersInterface";
import { getProviderAddressByIdAPIRequest } from "../../../api/provider/providerAddress";

const initialState: ProviderAddressFetchByIdInitialPropsInterface = {
  fetchProviderAddressByIdLoading: false,
  fetchProviderAddressByIdData: {},
  fetchProviderAddressByIdStatus: "IDLE",
  fetchProviderAddressByIdError: null,
};

export const getProviderAddressByIdRequest: any = createAsyncThunk(
  "patients/get/address/id",
  async (searchParams: ProviderAddressGetByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getProviderAddressByIdAPIRequest(
        searchParams
      );
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchProviderAddressByIdSlice = createSlice({
  name: "fetchProviderAddressById",
  initialState,
  reducers: {
    clearFetchProviderAddressByIdResponse: (state) => {
      state.fetchProviderAddressByIdLoading = false;
      state.fetchProviderAddressByIdData = {};
      state.fetchProviderAddressByIdStatus = "IDLE";
      state.fetchProviderAddressByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderAddressByIdRequest.pending, (state, action) => {
        state.fetchProviderAddressByIdLoading = true;
        state.fetchProviderAddressByIdStatus = "PENDING";
      })
      .addCase(getProviderAddressByIdRequest.fulfilled, (state, action) => {
        state.fetchProviderAddressByIdLoading = false;
        state.fetchProviderAddressByIdStatus = "SUCCESS";
        state.fetchProviderAddressByIdData = action.payload;
      })
      .addCase(getProviderAddressByIdRequest.rejected, (state, action) => {
        state.fetchProviderAddressByIdLoading = false;
        state.fetchProviderAddressByIdError = action.payload;
        state.fetchProviderAddressByIdStatus = "FAILED";
      });
  },
});

export const { clearFetchProviderAddressByIdResponse } =
  fetchProviderAddressByIdSlice.actions;

export const getProviderAddressByIdSelector = (state: RootState) =>
  state.FetchProviderAddressById;

export default fetchProviderAddressByIdSlice.reducer;
