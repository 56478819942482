import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderCarrierFollowUpAddInterface,
  ProviderCarrierFollowUpAddPropsInterface,
} from '../../../interfaces/providersInterface';
import { addProviderCarrierFollowUpAPIRequest } from '../../../api/provider/providerCarrier';

const initialState: ProviderCarrierFollowUpAddPropsInterface = {
  addProviderCarrierFollowUpLoading: false,
  addProviderCarrierFollowUpData: {},
  addProviderCarrierFollowUpStatus: 'IDLE',
  addProviderCarrierFollowUpError: null,
};

export const addProviderCarrierFollowUpRequest: any = createAsyncThunk(
  'provider/carriers/addFollowUp',
  async (carrier: ProviderCarrierFollowUpAddInterface, thunkAPI: any) => {
    try {
      const response: any = await addProviderCarrierFollowUpAPIRequest(carrier);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addProviderCarrierFollowUpSlice = createSlice({
  name: 'addProviderCarrierFollowUp',
  initialState,
  reducers: {
    clearAddProviderCarrierFollowUp: (state) => {
      state.addProviderCarrierFollowUpLoading = false;
      state.addProviderCarrierFollowUpStatus = 'IDLE';
      state.addProviderCarrierFollowUpData = {};
      state.addProviderCarrierFollowUpError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProviderCarrierFollowUpRequest.pending, (state, action) => {
        state.addProviderCarrierFollowUpLoading = true;
        state.addProviderCarrierFollowUpStatus = 'PENDING';
      })
      .addCase(addProviderCarrierFollowUpRequest.fulfilled, (state, action) => {
        state.addProviderCarrierFollowUpData = action.payload;
        state.addProviderCarrierFollowUpLoading = false;
        state.addProviderCarrierFollowUpStatus = 'SUCCESS';
      })
      .addCase(addProviderCarrierFollowUpRequest.rejected, (state, action) => {
        state.addProviderCarrierFollowUpLoading = false;
        state.addProviderCarrierFollowUpStatus = 'FAILED';
        state.addProviderCarrierFollowUpError = action.payload;
      });
  },
});

export const { clearAddProviderCarrierFollowUp } = addProviderCarrierFollowUpSlice.actions;

export const addProviderCarrierFollowUpSelector = (state: RootState) => state.AddProviderCarrierFollowUp;

export default addProviderCarrierFollowUpSlice.reducer;
