import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserByIdAPIRequest } from '../../../api/user/user';
import {
  FetchAddressByIDInterface,
  UserFetchByIdInitialInterface,
  UserFetchInterface,
  addressrFetchByIdInitialInterface,
} from '../../../interfaces/userInterface';
import { RootState } from '../../store';
import { getAddressByIdAPIRequest } from '../../../api/user/address';

const initialState: addressrFetchByIdInitialInterface = {
  addressByIdLoading: false,
  addressByIdAddressData: {},
  addressByIdError: null,
  addressByIdStatus: 'IDLE',
};

export const getAddressByIdRequest: any = createAsyncThunk(
  'address/get/id',
  async (address: FetchAddressByIDInterface, thunkAPI: any) => {
    try {
      const response: any = await getAddressByIdAPIRequest(address);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getAddressByIdSlice = createSlice({
  name: 'fetchAddressById',
  initialState,
  reducers: {
    clearFetchAddressByIdResponse: (state) => {
      state.addressByIdLoading = false;
      state.addressByIdStatus = 'IDLE';
      state.addressByIdAddressData = {};
      state.addressByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddressByIdRequest.pending, (state, action) => {
        state.addressByIdLoading = true;
        state.addressByIdStatus = 'PENDING';
      })
      .addCase(getAddressByIdRequest.fulfilled, (state, action) => {
        state.addressByIdLoading = false;
        state.addressByIdAddressData = action.payload;
        state.addressByIdStatus = 'SUCCESS';
      })
      .addCase(getAddressByIdRequest.rejected, (state, action) => {
        state.addressByIdLoading = false;
        state.addressByIdError = action.payload;
        state.addressByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchAddressByIdResponse } = getAddressByIdSlice.actions;

export const getAddressByIdSelector = (state: RootState) => state.GetAddressById;

export default getAddressByIdSlice.reducer;
