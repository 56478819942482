import moment from 'moment';
import { validDateFormat } from '../../../utils/commonFunctions';
import { TableContent } from '../../table/TableContent';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Locality',
    dataIndex: 'locality',
    key: 'locality',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Effective date',
    dataIndex: 'effectiveDate',
    key: 'effectiveDate',
    render: (text: string) => moment(text).format(validDateFormat),
  },
];

export const FeeList = () => {
  return (
    <>
      <TableContent columns={columns} />
    </>
  );
};
