import { useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { ClaimERAFilesHeader } from '../../../components/billing/claimERAFiles/ClaimERAFilesHeader';
import moment from 'moment';
import { validDateFormat } from '../../../utils/commonFunctions';

const columns = [
  {
    title: 'Paid Date',
    dataIndex: 'paidDate',
    key: 'paidDate',
    render: (text: string) => moment(text).format(validDateFormat),
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraId',
    key: 'eraId',
  },
  {
    title: 'Files',
    dataIndex: 'files',
    key: 'files',
  },
  {
    title: 'Paid Amount',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
  },
];

const dataSource = [
  {
    paidDate: '2024-05-10',
    eraId: 'ERA987654',
    files: 'File1.pdf, File2.pdf',
    paidAmount: '$500.00',
    totalAmount: '$1000.00',
  },
];

const reportFilters = {
  month: 'Select Month',
};

export const months = [
  { id: '05-2023', name: 'May - 2023' },
  { id: '06-2023', name: 'June - 2023' },
  { id: '07-2023', name: 'July - 2023' },
  { id: '08-2023', name: 'August - 2023' },
  { id: '09-2023', name: 'September - 2023' },
  { id: '10-2023', name: 'October - 2023' },
  { id: '11-2023', name: 'November - 2023' },
  { id: '12-2023', name: 'December - 2023' },
  { id: '01-2024', name: 'January - 2024' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'claimEraFiles', label: 'Claim ERA Files', status: 'active', link: 'claim-era-files' },
];

export const ClaimERAFilesPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Claim ERA Files" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ClaimERAFilesHeader reportFilters={reportFilters} totalERA={'3550'} months={months} />
      <div className="flex justify-end">
        <TableSearch />
      </div>
      {dataSource?.length > 0 ? (
        <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
      ) : (
        <EmptyContent enableCreateButton={false} />
      )}

      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      ) : (
        <></>
      )}
    </div>
  );
};
