import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  EditPatientCaseInterface,
  PatientCaseEditPropsInterface,
  PatientClaimPrintInterface,
  PrintPatientClaimPropsInterface,
} from '../../../interfaces/patientInterface';
import { editPatientCaseAPIRequest } from '../../../api/patient/patientCase';
import { printClaimAPIRequest } from '../../../api/patient/patientClaim';

const initialState: PrintPatientClaimPropsInterface = {
  printPatientClaimLoading: false,
  printPatientClaimData: {},
  printPatientClaimError: null,
  printPatientClaimStatus: 'IDLE',
};

export const printPatientClaimRequest: any = createAsyncThunk(
  'patient/claim/print',
  async (patient: PatientClaimPrintInterface, thunkAPI: any) => {
    try {
      const response: any = await printClaimAPIRequest(patient);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const printPatientClaimSlice = createSlice({
  name: 'printPatientClaim',
  initialState,
  reducers: {
    clearPrintPatientClaimResponse: (state) => {
      state.printPatientClaimLoading = false;
      state.printPatientClaimStatus = 'IDLE';
      state.printPatientClaimData = {};
      state.printPatientClaimError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(printPatientClaimRequest.pending, (state, action) => {
        state.printPatientClaimLoading = true;
        state.printPatientClaimStatus = 'PENDING';
      })
      .addCase(printPatientClaimRequest.fulfilled, (state, action) => {
        state.printPatientClaimData = action.payload;
        state.printPatientClaimStatus = 'SUCCESS';
        state.printPatientClaimLoading = false;
      })
      .addCase(printPatientClaimRequest.rejected, (state, action) => {
        state.printPatientClaimLoading = false;
        state.printPatientClaimError = action.payload;
        state.printPatientClaimStatus = 'FAILED';
      });
  },
});

export const { clearPrintPatientClaimResponse } = printPatientClaimSlice.actions;

export const printPatientClaimSelector = (state: RootState) => state.PrintPatientClaim;

export default printPatientClaimSlice.reducer;
