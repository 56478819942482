import { useEffect, useState } from 'react';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { RightSheet } from '../../../components/rightSheet/RightSheet';
import { TableContent } from '../../../components/table/TableContent';
import { TableSearch } from '../../../components/tableSearch/TableSearch';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import {
  getModifiersRequest,
  getModifiersSelector,
  clearFetchModifiersResponse,
} from '../../../redux/slices/system/getModifiersSlice';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { ModifierRightSheetForm } from '../../../components/account/codes/ModifierRightSheetForm';
import {
  addModifierCodeRequest,
  addModifierCodeSelector,
  clearAddModifierCodeResponse,
} from '../../../redux/slices/codes/addModifierCodeSlice';
import {
  clearEditModifierCodeResponse,
  editModifierCodeSelector,
  updateModifiersRequest,
} from '../../../redux/slices/codes/editModifierCodeSlice';
import { modifierValidation } from '../../../utils/codeValidation';
import {
  clearFetchModifierByIdResponse,
  getModifierByIdRequest,
  getModifierByIdSelector,
} from '../../../redux/slices/codes/getModifierCodeByIdSlice';
import {
  clearDeleteModifierByIdResponse,
  deleteModifierByIdRequest,
  deleteModifierByIdSelector,
} from '../../../redux/slices/codes/deleteModifierCodeByIdSlice';
import { ConfirmModal } from '../../../components/list/patients/visit/ConfirmModal';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
  },
];

const dataSourceDummy = [
  {
    modifierCode: '22',
    description: 'Professional component',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'modifiers', label: 'Modifiers', status: 'active', link: 'modifier-codes' },
];

export const ModifierCodesPage = () => {
  const dispatch = useAppDispatch();
  const { modifiersData, modifiersStatus } = useAppSelector(getModifiersSelector);
  //const { status, codeData, loading } = useAppSelector(getModifierCodeSelector);
  const { addModifierCodeStatus, addModifierCodeLoading, addModifierCodeError } =
    useAppSelector(addModifierCodeSelector);
  const { editModifierCodeStatus, editModifierCodeLoading, editModifierCodeError } =
    useAppSelector(editModifierCodeSelector);
  const { modifierByIdData, modifierByIdError, modifierByIdStatus } = useAppSelector(getModifierByIdSelector);
  const { deleteModifierByIdData, deleteModifierByIdError, deleteModifierByIdStatus } =
    useAppSelector(deleteModifierByIdSelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const [onFormChange, setOnFormChange] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedId, setSelectedId] = useState(1);
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  // console.log('=== mode ===', mode);

  useEffect(() => {
    fetchModifierCodes();
    // setDataSource(dataSourceDummy);
  }, []);

  useEffect(() => {
    if (modifiersStatus === 'SUCCESS') {
      setDataSource(modifiersData?.items);
      setCurrentPage(modifiersData?.currentPage);
      setTotalPages(modifiersData?.totalPages);
    }
    dispatch(clearFetchModifiersResponse());
  }, [modifiersStatus]);

  useEffect(() => {
    if (modifierByIdStatus === 'SUCCESS') {
      setOnFormChange(modifierByIdData);
      setVisibleModal(true);
    } else if (modifierByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: modifierByIdError?.message ?? 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchModifierByIdResponse());
  }, [modifierByIdStatus]);

  useEffect(() => {
    if (addModifierCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      fetchModifierCodes(PAGE_SIZE, 0);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (addModifierCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addModifierCodeError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }

    dispatch(clearAddModifierCodeResponse());
  }, [addModifierCodeStatus]);

  useEffect(() => {
    if (editModifierCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      fetchModifierCodes(PAGE_SIZE, 0);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (editModifierCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: editModifierCodeError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }

    dispatch(clearEditModifierCodeResponse());
  }, [editModifierCodeStatus]);

  useEffect(() => {
    if (deleteModifierByIdStatus === 'SUCCESS') {
      setOpenConfirmModal(false);
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      fetchModifierCodes(PAGE_SIZE, 0);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (deleteModifierByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: deleteModifierByIdError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearDeleteModifierByIdResponse());
  }, [deleteModifierByIdStatus]);

  const fetchModifierCodes = async (pageSize = PAGE_SIZE, pageNumber = currentPage) => {
    dispatch(getModifiersRequest({ page: pageNumber, size: pageSize, code: '', desc: '' }));
  };

  const fetchModifierCodeById = async (id: number) => {
    dispatch(getModifierByIdRequest({ id }));
  };

  const onPageChange = (page: any) => {
    // console.log('=== changed page ===', page);
    setCurrentPage(page - 1);
    fetchModifierCodes(PAGE_SIZE, page - 1);
  };

  const handleModalVisible = () => {
    setOnFormChange({});
    setMode('CREATE');
    setVisibleModal(true);
  };

  const handleOnClose = () => {
    setVisibleModal(false);
    setVisibleEditForm(false);
    setMode('CREATE');
  };

  const handleFormChanges = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setOnFormChange((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  // console.log('=== onFormChange ===', onFormChange);

  const onSubmit = () => {
    const validationDetails = modifierValidation(onFormChange);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setErrorObj(validationDetails?.newErrors);
      const errorMessages = Object.values(validationDetails.newErrors);
      setAlertObj({
        color: 'failure',
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span style={{ marginLeft: '8px' }}>{msg}</span>
              </div>
            ))}
          </div>
        ),
        error: true,
      });
      setVisibleAlert(true);
    } else {
      if (mode === 'EDIT') {
        let obj = {
          id: selectedId,
          code: onFormChange?.code,
          desc: onFormChange?.desc,
        };
        dispatch(updateModifiersRequest(obj));
      } else {
        dispatch(addModifierCodeRequest(onFormChange));
      }
    }
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setMode('EDIT');
    setSelectedToEdit(dataSet);
    setSelectedId(dataSet?.id);
    fetchModifierCodeById(dataSet?.id);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setMode('VIEW');
    const dataSet = dataSource[rowIndex];
    setSelectedToEdit(dataSet);
    setSelectedId(dataSet?.id);
    fetchModifierCodeById(dataSet?.id);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
    setMode('DELETE');
    const dataSet = dataSource[rowIndex];
    setSelectedToEdit(dataSet);
    setSelectedId(dataSet?.id);
    setOpenConfirmModal(true);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const renderRightTitle = () => {
    let title = '';
    if (mode === 'CREATE') {
      title = 'Create Modifier';
    } else if (mode === 'EDIT') {
      title = 'Update Modifier';
    } else {
      title = 'View Modifier';
    }
    return title;
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const confirmDelete = () => {
    dispatch(deleteModifierByIdRequest({ id: selectedId }));
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Modifiers" breadCrumbArr={breadCrumbArr} />
      <TableSearch />
      {
        //loading
        false ? (
          <Spinner />
        ) : (
          <>
            {dataSource?.length > 0 ? (
              <>
                <TableContent
                  enableActions={true}
                  columns={columns}
                  dataSource={dataSource}
                  enableDelete={true}
                  enableEdit={true}
                  enableView={true}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onView={onView}
                />
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              </>
            ) : (
              <EmptyContent />
            )}
          </>
        )
      }

      {visibleModal ? (
        <RightSheet
          onClose={handleOnClose}
          title={renderRightTitle()}
          submitButtonTitle={mode === 'CREATE' ? 'Create' : 'Update'}
          cancelButtonTitle="Cancel"
          onSubmit={onSubmit}
          children={
            <ModifierRightSheetForm
              onChange={handleFormChanges}
              mode={mode}
              errorObject={errorObj}
              codeData={onFormChange}
            />
          }
          // loading={addModifierCodeLoading}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={errorAlertOnClose}
          enableSubmitButton={mode === 'VIEW' ? false : true}
          enableCancelButton={mode === 'VIEW' ? false : true}
        />
      ) : (
        <></>
      )}

      {openConfirmModal && (
        <ConfirmModal
          openConfirm={openConfirmModal}
          setOpenConfirm={onCloseConfirm}
          title="Delete Modifier Code"
          content="Are you sure you want to delete this Modifier Code?"
          submitButtonTitle="Yes"
          cancelButtonTitle="No"
          handleSubmit={confirmDelete}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}
    </div>
  );
};
