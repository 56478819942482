import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CPTCodePropsInterface,
  CPTFetchInitialInterface,
  FetchVisitStatusHistoryInitialInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import { getPatientsAPIRequest } from '../../../api/patient/patient';
import {
  getCPTCodeListAPIRequest,
  getVisitNoteHistoryAPIRequest,
  getVisitStatusHistoryAPIRequest,
} from '../../../api/patient/patientVisit';

const initialState: FetchVisitStatusHistoryInitialInterface = {
  loadingStatusHistory: false,
  statusHistoryData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  errorStatusHistory: null,
  historyStatus: 'IDLE',
};

export const getVisitStatusHistoryRequest: any = createAsyncThunk(
  'status-history/get',
  async (searchParams: CPTCodePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await getVisitStatusHistoryAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitStatusHistorySlice = createSlice({
  name: 'fetchVisitStatusHistory',
  initialState,
  reducers: {
    clearFetchVisitStatusHistoryResponse: (state) => {
      state.loadingStatusHistory = false;
      state.historyStatus = 'IDLE';
      state.statusHistoryData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.errorStatusHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitStatusHistoryRequest.pending, (state, action) => {
        state.loadingStatusHistory = true;
        state.historyStatus = 'PENDING';
      })
      .addCase(getVisitStatusHistoryRequest.fulfilled, (state, action) => {
        state.loadingStatusHistory = false;
        state.historyStatus = 'SUCCESS';
        state.statusHistoryData = action.payload;
      })
      .addCase(getVisitStatusHistoryRequest.rejected, (state, action) => {
        state.loadingStatusHistory = false;
        state.errorStatusHistory = action.payload;
        state.historyStatus = 'FAILED';
      });
  },
});

export const { clearFetchVisitStatusHistoryResponse } = getVisitStatusHistorySlice.actions;

export const getVisitStatusHistorySelector = (state: RootState) => state.FetchVisitStatusHistory;

export default getVisitStatusHistorySlice.reducer;
