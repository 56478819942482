import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  PatientCaseByIdInterface,
  PatientCaseFetchByIdInitialPropsInterface,
} from '../../../interfaces/patientInterface';
import { getPatientCaseByIdAPIRequest } from '../../../api/patient/patientCase';

const initialState: PatientCaseFetchByIdInitialPropsInterface = {
  fetchPatientCaseByIdLoading: false,
  fetchPatientCaseByIdData: {},
  fetchPatientCaseByIdStatus: 'IDLE',
  fetchPatientCaseByIdError: null,
};

export const getPatientCaseByIdRequest: any = createAsyncThunk(
  'patients/get/case/id',
  async (searchParams: PatientCaseByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPatientCaseByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientCaseByIdSlice = createSlice({
  name: 'fetchPatientCaseById',
  initialState,
  reducers: {
    clearFetchPatientCaseByIdResponse: (state) => {
      state.fetchPatientCaseByIdLoading = false;
      state.fetchPatientCaseByIdData = {};
      state.fetchPatientCaseByIdStatus = 'IDLE';
      state.fetchPatientCaseByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientCaseByIdRequest.pending, (state, action) => {
        state.fetchPatientCaseByIdLoading = true;
        state.fetchPatientCaseByIdStatus = 'PENDING';
      })
      .addCase(getPatientCaseByIdRequest.fulfilled, (state, action) => {
        state.fetchPatientCaseByIdLoading = false;
        state.fetchPatientCaseByIdStatus = 'SUCCESS';
        state.fetchPatientCaseByIdData = action.payload;
      })
      .addCase(getPatientCaseByIdRequest.rejected, (state, action) => {
        state.fetchPatientCaseByIdLoading = false;
        state.fetchPatientCaseByIdError = action.payload;
        state.fetchPatientCaseByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientCaseByIdResponse } = fetchPatientCaseByIdSlice.actions;

export const getPatientCaseByIdSelector = (state: RootState) => state.FetchPatientCaseById;

export default fetchPatientCaseByIdSlice.reducer;
