import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CPTCodesAddInterface,
  CPTCodesAddPropsInterface,
  ModifierCodesAddPropsInterface,
} from '../../../interfaces/codesInterfaces';
import { AddModifierInterface } from '../../../interfaces/systemInterfaces';
import { addModifiersAPIRequest } from '../../../api/system/code';

const initialState: ModifierCodesAddPropsInterface = {
  addModifierCodeLoading: false,
  addModifierCodeData: {},
  addModifierCodeStatus: 'IDLE',
  addModifierCodeError: null,
};

export const addModifierCodeRequest: any = createAsyncThunk(
  'codes/modifier/add',
  async (code: AddModifierInterface, thunkAPI: any) => {
    try {
      const response: any = await addModifiersAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addModifierCodeSlice = createSlice({
  name: 'addModifierCode',
  initialState,
  reducers: {
    clearAddModifierCodeResponse: (state) => {
      state.addModifierCodeLoading = false;
      state.addModifierCodeStatus = 'IDLE';
      state.addModifierCodeData = {};
      state.addModifierCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addModifierCodeRequest.pending, (state, action) => {
        state.addModifierCodeLoading = true;
        state.addModifierCodeStatus = 'PENDING';
      })
      .addCase(addModifierCodeRequest.fulfilled, (state, action) => {
        state.addModifierCodeLoading = false;
        state.addModifierCodeStatus = 'SUCCESS';
        state.addModifierCodeData = action.payload;
      })
      .addCase(addModifierCodeRequest.rejected, (state, action) => {
        state.addModifierCodeLoading = false;
        state.addModifierCodeStatus = 'FAILED';
        state.addModifierCodeError = action.payload;
      });
  },
});

export const { clearAddModifierCodeResponse } = addModifierCodeSlice.actions;

export const addModifierCodeSelector = (state: RootState) => state.AddModifierCode;

export default addModifierCodeSlice.reducer;
