import { MainHeader } from "../components/mainHeader/MainHeader";
import { PageTopic } from "../components/pageTopic/PageTopic";
import { Pagination } from "../components/pagination/Pagination";
import { RightSheet } from "../components/rightSheet/RightSheet";
import { TableContent } from "../components/table/TableContent";
import { TableSearch } from "../components/tableSearch/TableSearch";

export const AccountsPage = () => {
  return (
    <>
      <div className="main-content">
        <MainHeader />
        <PageTopic />
        <TableSearch />
        <TableContent />
        <Pagination />
        <RightSheet />
      </div>
    </>
  );
};
