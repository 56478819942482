import {
  ProviderLicenseAddInterface,
  ProviderLicenseGetByIdInterface,
  ProviderLicenseUpdateInterface,
  ProviderLicensePrimaryUpdateInterface,
} from '../../interfaces/providersInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addProviderLicenseAPIRequest = async (providerData: ProviderLicenseAddInterface) => {
  console.log('== providerData ==', providerData);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${providerData?.providerId}/license`,
    'post',
    {
      data: { ...providerData?.licenseData },
    }
  );
};

export const updateProviderLicenseAPIRequest = async (licenseData: ProviderLicenseUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${licenseData?.providerId}/license/${licenseData?.licenseId}`,
    'put',
    {
      data: { ...licenseData?.licenseData },
    }
  );
};

export const getProviderLicenseByIdAPIRequest = async (licenseData: ProviderLicenseGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${licenseData?.providerId}/license/${licenseData?.licenseId}`,
    'get',
    {}
  );
};

export const deleteProviderLicenseByIdAPIRequest = async (licenseData: ProviderLicenseGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${licenseData?.providerId}/license/${licenseData?.licenseId}`,
    'delete',
    {}
  );
};

export const updateProviderLicensePrimaryAPIRequest = async (licenseData: ProviderLicensePrimaryUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/provider/${licenseData?.providerId}/license/${licenseData?.licenseId}/change-primary`,
    'put',
    {
      data: { ...licenseData?.licenseData },
    }
  );
};
