import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ICDCodesAddPropsInterface } from '../../../interfaces/codesInterfaces';
import { AddICDCodeInterface } from '../../../interfaces/systemInterfaces';
import { addICDCodeAPIRequest } from '../../../api/system/code';

const initialState: ICDCodesAddPropsInterface = {
  addICDCodeLoading: false,
  addICDCodeData: {},
  addICDCodeStatus: 'IDLE',
  addICDCodeError: null,
};

export const addICDCodeRequest: any = createAsyncThunk(
  'codes/ICD/add',
  async (code: AddICDCodeInterface, thunkAPI: any) => {
    try {
      const response: any = await addICDCodeAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addICDCodeSlice = createSlice({
  name: 'addICDCode',
  initialState,
  reducers: {
    clearAddICDCodeResponse: (state) => {
      state.addICDCodeLoading = false;
      state.addICDCodeStatus = 'IDLE';
      state.addICDCodeData = {};
      state.addICDCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addICDCodeRequest.pending, (state, action) => {
        state.addICDCodeLoading = true;
        state.addICDCodeStatus = 'PENDING';
      })
      .addCase(addICDCodeRequest.fulfilled, (state, action) => {
        state.addICDCodeLoading = false;
        state.addICDCodeStatus = 'SUCCESS';
        state.addICDCodeData = action.payload;
      })
      .addCase(addICDCodeRequest.rejected, (state, action) => {
        state.addICDCodeLoading = false;
        state.addICDCodeStatus = 'FAILED';
        state.addICDCodeError = action.payload;
      });
  },
});

export const { clearAddICDCodeResponse } = addICDCodeSlice.actions;

export const addICDCodeSelector = (state: RootState) => state.AddICDCode;

export default addICDCodeSlice.reducer;
