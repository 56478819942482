import {
  AddVisitICDCodePropsInterface,
  AddVisitNotePropsInterface,
  CPTCodePropsInterface,
  CPTCodeUpdateInterface,
  PatientViewEIByIdInterface,
  PatientVisitAddInterface,
  PatientVisitDeleteCPTInterface,
  PatientVisitGetByIdInterface,
  PatientVisitUpdateInterface,
  UpdatePatientVisitEIById,
  VisitCopyCreateInterface,
  VisitStatusDataInterface,
  VisitStatusUpdateInterface,
  DeleteVisitICDCodeInterface,
} from '../../interfaces/patientInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addPatientVisitAPIRequest = async (visitData: PatientVisitAddInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${visitData?.patientId}/visit`,
    'post',
    {
      data: { ...visitData?.visitData },
    }
  );
};

export const updatePAtientVisitAPIRequest = async (visit: PatientVisitUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${visit?.patientId}/visit/${visit?.visitId}`,
    'put',
    {
      data: { ...visit?.visitData },
    }
  );
};

export const getPatientVisitByIdAPIRequest = async (visit: PatientVisitGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${visit?.patientId}/visit/${visit?.visitId}`,
    'get',
    {}
  );
};

export const getPatientElByVisitIdAPIRequest = async (visit: PatientVisitGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${visit?.patientId}/visit/${visit?.visitId}/eligibility-data`,
    'get',
    {}
  );
};

export const deletePatientVisitByIdAPIRequest = async (visitData: PatientVisitGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${visitData?.patientId}/visit/${visitData?.visitId}`,
    'delete',
    {}
  );
};

export const updatePatientVisitEIByIdAPIRequest = async (eligibilityData: UpdatePatientVisitEIById) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${eligibilityData?.patientId}/visit/${eligibilityData?.visitId}/eligibility-check`,
    'post',
    {
      data: { ...eligibilityData?.enquiryData },
    }
  );
};

//--------- NEW -------------//

export const getCPTCodeListAPIRequest = async (visitData: CPTCodePropsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visitData?.visitId}/cpt-code`,
    'get',
    {}
  );
};

export const getICDCodeListAPIRequest = async (visitData: CPTCodePropsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visitData?.visitId}/icd-code`,
    'get',
    {}
  );
};

export const getVisitNoteHistoryAPIRequest = async (visitData: CPTCodePropsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visitData?.visitId}/note-history`,
    'get',
    {}
  );
};

export const getVisitStatusHistoryAPIRequest = async (visitData: CPTCodePropsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visitData?.visitId}/status-history`,
    'get',
    {}
  );
};

export const deleteVisitCPTCodeAPIRequest = async (visitData: PatientVisitDeleteCPTInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visitData?.visitId}/cpt/${visitData?.cpt}`,
    'delete',
    {}
  );
};

export const deleteVisitICDCodeAPIRequest = async (visitData: DeleteVisitICDCodeInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visitData?.visitId}/icd/${visitData?.icd}`,
    'delete',
    {}
  );
};

export const addVisitCPTCodeAPIRequest = async (visit: CPTCodeUpdateInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/visit/${visit?.visitId}/add-cpt`, 'post', {
    data: { ...visit?.visitData },
  });
};

export const updateVisitCPTCodeAPIRequest = async (visit: CPTCodeUpdateInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/visit/${visit?.visitId}/edit-cpt`, 'put', {
    data: { ...visit?.visitData },
  });
};

export const addVisitNoteAPIRequest = async (visit: AddVisitNotePropsInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/visit/${visit?.visitId}/add-note`, 'post', {
    data: { ...visit?.visitData },
  });
};

export const addVisitICDCodeAPIRequest = async (visit: AddVisitICDCodePropsInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/visit/${visit?.visitId}/add-icd`, 'post', {
    data: { ...visit?.visitData },
  });
};

export const updateVisitStatusAPIRequest = async (visit: VisitStatusUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visit?.visitId}/status-update`,
    'post',
    {
      data: { ...visit?.visitData },
    }
  );
};

export const copyVisitRecordAPIRequest = async (visit: VisitCopyCreateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${visit?.patientId}/visit/${visit.visitId}/copy`,
    'post',
    {
      data: { ...visit?.visitData },
    }
  );
};

export const getViewEIByIdAPIRequest = async (visit: PatientViewEIByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${visit?.patientId}/visit/${visit?.visitId}/eligibility/${visit?.eligibilityId}`,
    'get',
    {}
  );
};
