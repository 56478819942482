import './sidebar.css';
import SimplibillLogo from '../../assets/images/simplibill-logo-color.svg';
// import 'flowbite';
import { Sidebar } from 'flowbite-react';
import { DashboardIcon } from '../customIcons/sidebarIcons/DashboardIcon';
import { ListIcon } from '../customIcons/sidebarIcons/ListIcon';
import { BillingIcon } from '../customIcons/sidebarIcons/BillingIcon';
import { AccountsIcon } from '../customIcons/sidebarIcons/AccountsIcon';
import { useNavigate } from 'react-router-dom';
import { ReportsIcon } from '../customIcons/sidebarIcons/ReportsIcon';

export const SidebarNew = () => {
  const navigate = useNavigate();
  return (
    <Sidebar aria-label="Sidebar with multi-level dropdown example" className="sidebar-nav">
      <div className="sidebar">
        <div className="sidebar__logo">
          <a href="dashboard.html">
            <img src={SimplibillLogo} alt="Simplibill logo" className="h-10" />
          </a>
        </div>
        <Sidebar.Items>
          <Sidebar.ItemGroup className="sidebar__menu">
            <Sidebar.Item
              onClick={(e: any) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              icon={DashboardIcon}
              className="sidebar__menu-item-link"
            >
              <span className="sidebar__menu-item-link--text">Dashboard</span>
            </Sidebar.Item>
            <Sidebar.Collapse icon={ListIcon} label="List">
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/patients');
                }}
              >
                Patients/Guarantors
              </Sidebar.Item>
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/providers');
                }}
              >
                Providers
              </Sidebar.Item>
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/facilities');
                }}
              >
                Facilities
              </Sidebar.Item>
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/insurance');
                }}
              >
                Insurance
              </Sidebar.Item>
            </Sidebar.Collapse>
            <Sidebar.Collapse icon={BillingIcon} label="Billing">
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/billing');
                }}
              >
                Visits
              </Sidebar.Item>
              <Sidebar.Collapse label="Claims">
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/primary-claims');
                  }}
                >
                  Primary Claims
                </Sidebar.Item>

                {/* <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/secondary-claims');
                  }}
                >
                  Secondary Claims
                </Sidebar.Item> */}
              </Sidebar.Collapse>

              {/* <Sidebar.Collapse label="Payments">
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/billing-batch-payment');
                  }}
                >
                  Batch Payment
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/claim-auto-post');
                  }}
                >
                  Claim MD ERA Auto Post
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/denial-reports');
                  }}
                >
                  Denial
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/claim-era-files');
                  }}
                >
                  Claim ERA Files
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/claim-era-files-by-recieved-date');
                  }}
                >
                  ERA files by Received Date
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/claimmd-era');
                  }}
                >
                  ClaimMD ERA Payments
                </Sidebar.Item>
              </Sidebar.Collapse> */}
              {/* <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/patient-statements');
                }}
              >
                Patients Statements
              </Sidebar.Item> */}
            </Sidebar.Collapse>
            <Sidebar.Collapse icon={AccountsIcon} label="Account">
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/users');
                }}
              >
                Users
              </Sidebar.Item>
              {/* <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/practices');
                }}
              >
                Practices
              </Sidebar.Item> */}

              <Sidebar.Collapse label="Codes">
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/cpt-codes');
                  }}
                >
                  CPT Codes
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/icd-codes');
                  }}
                >
                  ICD Codes
                </Sidebar.Item>
                {/* <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/payment-codes');
                  }}
                >
                  Payment/Adjustment Codes
                </Sidebar.Item> */}
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/modifier-codes');
                  }}
                >
                  Modifier Codes
                </Sidebar.Item>
              </Sidebar.Collapse>
            </Sidebar.Collapse>
            {/* <Sidebar.Collapse icon={ReportsIcon} label="Reports">
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/financial-dashboard');
                }}
              >
                Financial Dashboard
              </Sidebar.Item>
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/financial-dashboard-payer-mix');
                }}
              >
                Financial Dashboard Report by Payer Mix
              </Sidebar.Item>
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/doctor-financial');
                }}
              >
                Doctor Financial
              </Sidebar.Item>
              <Sidebar.Item
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate('/visit-count');
                }}
              >
                Visit Count
              </Sidebar.Item>
              <Sidebar.Collapse label="Account Receivable">
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/primary-insurance-report');
                  }}
                >
                  Primary Insurance Aging
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/secondary-insurance-report');
                  }}
                >
                  Secondary Insurance Aging
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/crossover-report');
                  }}
                >
                  Crossover Carrier Aging
                </Sidebar.Item>
              </Sidebar.Collapse>

              <Sidebar.Collapse label="Payment">
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/bank-reconciliation-to-be-reconciled-report');
                  }}
                >
                  Bank Reconciliation
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/bank-reconciliation-reconciled-report');
                  }}
                >
                  Bank Reconciliation - Reconciled
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/bank-reconciliation-report');
                  }}
                >
                  Bank Reconciliation Report
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/dashboard-bank-reconciliation-snapshot');
                  }}
                >
                  Dashboard & Bank Reconciliation Snapshot
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/crossover-carrier-report');
                  }}
                >
                  Crossover Carrier
                </Sidebar.Item>
              </Sidebar.Collapse>
            </Sidebar.Collapse> */}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </div>
    </Sidebar>
  );
};
