import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CPTCodePropsInterface,
  CPTFetchInitialInterface,
  PatientFetchInitialInterface,
  PatientFetchInterface,
} from '../../../interfaces/patientInterface';
import { getPatientsAPIRequest } from '../../../api/patient/patient';
import { getCPTCodeListAPIRequest } from '../../../api/patient/patientVisit';

const initialState: CPTFetchInitialInterface = {
  loadingCPT: false,
  cptData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  errorCPT: null,
  statusCPT: 'IDLE',
};

export const getCPTCodeListRequest: any = createAsyncThunk(
  'cpt/get',
  async (searchParams: CPTCodePropsInterface, thunkAPI: any) => {
    try {
      const response: any = await getCPTCodeListAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getCPTListSlice = createSlice({
  name: 'fetchCPTList',
  initialState,
  reducers: {
    clearFetchCPTResponse: (state) => {
      state.loadingCPT = false;
      state.statusCPT = 'IDLE';
      state.cptData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.errorCPT = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCPTCodeListRequest.pending, (state, action) => {
        state.loadingCPT = true;
        state.statusCPT = 'PENDING';
      })
      .addCase(getCPTCodeListRequest.fulfilled, (state, action) => {
        state.loadingCPT = false;
        state.statusCPT = 'SUCCESS';
        state.cptData = action.payload;
      })
      .addCase(getCPTCodeListRequest.rejected, (state, action) => {
        state.loadingCPT = false;
        state.errorCPT = action.payload;
        state.statusCPT = 'FAILED';
      });
  },
});

export const { clearFetchCPTResponse } = getCPTListSlice.actions;

export const getCPTListSelector = (state: RootState) => state.CPTList;

export default getCPTListSlice.reducer;
