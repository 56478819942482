import {
  GetInsuranceSubscriberInterface,
  PatientInsuranceAddInterface,
  PatientInsuranceGetByIdInterface,
  PatientInsuranceGetByTypeInterface,
  PatientInsuranceUpdateInterface,
} from '../../interfaces/patientInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const addPatientInsuranceAPIRequest = async (patientData: PatientInsuranceAddInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientData?.patientId}/insurance`,
    'post',
    {
      data: { ...patientData?.insuranceData },
    }
  );
};

export const updatePAtientInsuranceAPIRequest = async (insurance: PatientInsuranceUpdateInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${insurance?.patientId}/insurance/${insurance?.insuranceId}`,
    'put',
    {
      data: { ...insurance?.insuranceData },
    }
  );
};

export const getPatientInsuranceByIdAPIRequest = async (insurance: PatientInsuranceGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${insurance?.patientId}/insurance/${insurance?.insuranceId}`,
    'get',
    {}
  );
};

export const deletePatientInsuranceByIdAPIRequest = async (insuranceData: PatientInsuranceGetByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${insuranceData?.patientId}/insurance/${insuranceData?.insuranceId}`,
    'delete',
    {}
  );
};

export const getPatientInsuranceByTypeAPIRequest = async (insurance: PatientInsuranceGetByTypeInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${insurance?.patientId}/insurance-by-type?insuranceType=${insurance?.type}`,
    'get',
    {}
  );
};

export const getInsuranceSubscriberAPIRequest = async (subscriber: GetInsuranceSubscriberInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/search-subscriber?page=${subscriber?.page}&size=${subscriber?.size}&fName=${subscriber?.fName}&lName=${subscriber?.lName}`,
    'get',
    {}
  );
};
