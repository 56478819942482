import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  ProviderLicensePrimaryUpdateInterface,
  ProviderLicensePrimaryUpdatePropsInterface,
} from '../../../interfaces/providersInterface';
import { updateProviderLicensePrimaryAPIRequest } from '../../../api/provider/providerLicense';

const initialState: ProviderLicensePrimaryUpdatePropsInterface = {
  updateProviderLicensePrimaryLoading: false,
  updateProviderLicensePrimaryData: {},
  updateProviderLicensePrimaryStatus: 'IDLE',
  updateProviderLicensePrimaryError: null,
};

export const updateProviderLicensePrimaryRequest: any = createAsyncThunk(
  'provider/license/changePrimary',
  async (license: ProviderLicensePrimaryUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateProviderLicensePrimaryAPIRequest(license);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateProviderLicensePrimarySlice = createSlice({
  name: 'updateProviderLicensePrimary',
  initialState,
  reducers: {
    clearUpdateProviderLicensePrimary: (state) => {
      state.updateProviderLicensePrimaryLoading = false;
      state.updateProviderLicensePrimaryStatus = 'IDLE';
      state.updateProviderLicensePrimaryData = {};
      state.updateProviderLicensePrimaryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProviderLicensePrimaryRequest.pending, (state, action) => {
        state.updateProviderLicensePrimaryLoading = true;
        state.updateProviderLicensePrimaryStatus = 'PENDING';
      })
      .addCase(updateProviderLicensePrimaryRequest.fulfilled, (state, action) => {
        state.updateProviderLicensePrimaryData = action.payload;
        state.updateProviderLicensePrimaryLoading = false;
        state.updateProviderLicensePrimaryStatus = 'SUCCESS';
      })
      .addCase(updateProviderLicensePrimaryRequest.rejected, (state, action) => {
        state.updateProviderLicensePrimaryLoading = false;
        state.updateProviderLicensePrimaryStatus = 'FAILED';
        state.updateProviderLicensePrimaryError = action.payload;
      });
  },
});

export const { clearUpdateProviderLicensePrimary } = updateProviderLicensePrimarySlice.actions;

export const updateProviderLicensePrimarySelector = (state: RootState) => state.UpdateProviderLicensePrimary;

export default updateProviderLicensePrimarySlice.reducer;
