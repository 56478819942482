import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addUsersAPIRequest, getUserByIdAPIRequest, updateUsersAPIRequest } from '../../../api/user/user';
import { EditUserInitialInterface, UserAddInterface, UserUpdateInterface } from '../../../interfaces/userInterface';
import { RootState } from '../../store';

const initialState: EditUserInitialInterface = {
  editUserLoading: false,
  editUserData: {},
  editUserError: null,
  editUserStatus: 'IDLE',
  success: false,
};

export const updateUserRequest: any = createAsyncThunk(
  'users/edit',
  async (user: UserUpdateInterface, thunkAPI: any) => {
    try {
      const response: any = await updateUsersAPIRequest(user);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateUserSlice = createSlice({
  name: 'updateUser',
  initialState,
  reducers: {
    clearEditUserResponse: (state) => {
      state.editUserLoading = false;
      state.editUserStatus = 'IDLE';
      state.editUserData = {};
      state.editUserError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserRequest.pending, (state, action) => {
        state.editUserLoading = true;
        state.editUserStatus = 'PENDING';
      })
      .addCase(updateUserRequest.fulfilled, (state, action) => {
        state.editUserLoading = false;
        state.editUserData = action.payload;
        state.editUserStatus = 'SUCCESS';
      })
      .addCase(updateUserRequest.rejected, (state, action) => {
        state.editUserLoading = false;
        state.editUserError = action.payload;
        state.editUserStatus = 'FAILED';
      });
  },
});

export const { clearEditUserResponse } = updateUserSlice.actions;

export const editUserSelector = (state: RootState) => state.UpdateUser;

export default updateUserSlice.reducer;
