import { FetchDenialReportsInterface } from '../../interfaces/paymentInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getDenialReportsAPIRequest = async (searchParams: FetchDenialReportsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/denial-reports/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'get',
    {
      data: {},
    }
  );
};
