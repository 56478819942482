import { PrimaryButton } from '../atoms/PrimaryButton';
import './emptyContent.css';
import emptyIcon from '../../assets/images/empty-icon.png';
import { Spinner } from '../atoms/Spinner';
import { LoadingIcon } from '../atoms/LoadingIcon';
import { CommonButton } from '../atoms/CommonButton';

export const EmptyContent = ({ onClick, enableCreateButton = true, createButtonTitle = 'Create New', mode }: any) => {
  return (
    <>
      <div className="empty-content">
        <div className="empty-contnet__icon">
          <img src={emptyIcon} alt="Empty data" />
        </div>
        {mode !== 'VIEW' ? (
          <>
            <h2 className="xl-heading text-gray4 dark:text-gray1">Looks like empty. Get started with your data!</h2>
            <p className="mt-4">Please add your first data here</p>{' '}
          </>
        ) : (
          <h2 className="xl-heading text-gray4 dark:text-gray1">Looks like empty.</h2>
        )}
        <div className="empty-content__action-area">
          <div className="empty-content__action-area-button">
            {enableCreateButton && <CommonButton buttonType="primary" label={createButtonTitle} onClick={onClick} />}

            {/* Use primary button in empty area as main action. Bellow secondary button use only to show loading icon */}

            {/* <button type="submit" className="
                            px-4
                            py-2
                            text-gray9
                            hover:text-gray9
                            focus:text-gray9
                            focus:hover:text-gray9
                            dark:text-white
                            dark:hover:text-white
                            dark:focus:text-white
                            bg-white
                            hover:bg-gray1
                            dark:bg-gray9
                            dark:hover:bg-gray8
                            dark:focus:bg-gray7
                            dark:active:bg-gray7
                            border
                            border-gray3
                            dark:border-gray8
                            focus:z-10
                            focus:ring-2
                            focus:ring-primaryFocus
                            rounded-lg 
                            font-medium
                            flex
                            items-center
                            w-full
                            justify-center">
                                <LoadingIcon />
                                Loading Button
                            </button> */}
          </div>
        </div>

        {/* <Spinner /> */}
      </div>
    </>
  );
};
