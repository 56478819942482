import { FetchVisitCPTInterface, FetchVisitsInterface } from '../../interfaces/billingInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getVisitsAPIRequest = async (visitData: FetchVisitsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/search?page=${visitData?.page}&size=${visitData?.size}`,
    'post',
    {
      data: visitData?.filters ?? {},
    }
  );
};

export const getVisitCPTByIdAPIRequest = async (visitData: FetchVisitCPTInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/visit/${visitData?.visitId}/cpt/${visitData?.cptId}`,
    'get',
    {}
  );
};
