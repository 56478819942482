import { BatchTableProps } from '../../../interfaces/billingInterface';
import { EmptyContent } from '../../emptyContent/EmptyContent';
import { Pagination } from '../../pagination/Pagination';
import { TableContent } from '../../table/TableContent';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Batch Type',
    dataIndex: 'batchType',
    key: 'batchType',
  },
  {
    title: 'Check Date',
    dataIndex: 'checkDate',
    key: 'checkDate',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Payment Amount',
    dataIndex: 'paymentAmount',
    key: 'paymentAmount',
  },
  {
    title: 'Unapplied Amount',
    dataIndex: 'unappliedAmount',
    key: 'unappliedAmount',
  },
];

export const BatchTable: React.FC<BatchTableProps> = ({
  dataSource = [],
  onEdit,
  onView,
  onApply,
  onLock,
  totalPages,
  currentPage,
  onPageChange,
}: any) => {
  return (
    <>
      {dataSource?.length > 0 ? (
        <TableContent
          enableActions={true}
          columns={columns}
          dataSource={dataSource}
          enableView={true}
          enableEdit={true}
          enableAdd={true}
          enableLock={true}
          onEdit={onEdit}
          onView={onView}
          onHandleLock={onLock}
          onHandleAdd={onApply}
        />
      ) : (
        <EmptyContent />
      )}

      {dataSource?.length > 0 && (
        <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      )}
    </>
  );
};
