import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  GetInsuranceSubscriberInterface,
  PatientInsuranceSubscriberInitialPropsInterface,
} from '../../../interfaces/patientInterface';
import { getInsuranceSubscriberAPIRequest } from '../../../api/patient/patientInsurance';

const initialState: PatientInsuranceSubscriberInitialPropsInterface = {
  fetchPatientInsuranceSubscriberLoading: false,
  fetchPatientInsuranceSubscriberData: {},
  fetchPatientInsuranceSubscriberStatus: 'IDLE',
  fetchPatientInsuranceSubscriberError: null,
};

export const getInsuranceSubscriberRequest: any = createAsyncThunk(
  'patients/get/insurance/subscriber',
  async (searchParams: GetInsuranceSubscriberInterface, thunkAPI: any) => {
    try {
      const response: any = await getInsuranceSubscriberAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const fetchPatientInsuranceSubscriberSlice = createSlice({
  name: 'fetchPatientInsuranceSubscriber',
  initialState,
  reducers: {
    clearFetchPatientInsuranceSubscriberResponse: (state) => {
      state.fetchPatientInsuranceSubscriberLoading = false;
      state.fetchPatientInsuranceSubscriberData = {};
      state.fetchPatientInsuranceSubscriberStatus = 'IDLE';
      state.fetchPatientInsuranceSubscriberError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsuranceSubscriberRequest.pending, (state, action) => {
        state.fetchPatientInsuranceSubscriberLoading = true;
        state.fetchPatientInsuranceSubscriberStatus = 'PENDING';
      })
      .addCase(getInsuranceSubscriberRequest.fulfilled, (state, action) => {
        state.fetchPatientInsuranceSubscriberLoading = false;
        state.fetchPatientInsuranceSubscriberStatus = 'SUCCESS';
        state.fetchPatientInsuranceSubscriberData = action.payload;
      })
      .addCase(getInsuranceSubscriberRequest.rejected, (state, action) => {
        state.fetchPatientInsuranceSubscriberLoading = false;
        state.fetchPatientInsuranceSubscriberError = action.payload;
        state.fetchPatientInsuranceSubscriberStatus = 'FAILED';
      });
  },
});

export const { clearFetchPatientInsuranceSubscriberResponse } = fetchPatientInsuranceSubscriberSlice.actions;

export const getPatientInsuranceSubscriberSelector = (state: RootState) => state.FetchPatientInsuranceSubscriberReducer;

export default fetchPatientInsuranceSubscriberSlice.reducer;
