import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUsersAPIRequest } from '../../../api/user/user';
import { UserFetchInitialInterface, UsersFetchInterface } from '../../../interfaces/userInterface';
import { RootState } from '../../store';

const initialState: UserFetchInitialInterface = {
  loading: false,
  userData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  error: null,
  success: false,
  status: 'IDLE',
};

export const getUsersRequest: any = createAsyncThunk(
  'users/get',
  async (searchParams: UsersFetchInterface, thunkAPI: any) => {
    try {
      const response: any = await getUsersAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getUsersSlice = createSlice({
  name: 'fetchUsers',
  initialState,
  reducers: {
    clearFetchUserResponse: (state) => {
      state.loading = false;
      state.status = 'IDLE';
      state.userData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersRequest.pending, (state, action) => {
        state.loading = true;
        state.status = 'PENDING';
      })
      .addCase(getUsersRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        state.status = 'SUCCESS';
      })
      .addCase(getUsersRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'FAILED';
      });
  },
});

export const { clearFetchUserResponse } = getUsersSlice.actions;

export const getUserSelector = (state: RootState) => state.GetUser;

export default getUsersSlice.reducer;
