import { validPhoneFaxLength, validZipCodeLength, validateEmail } from './commonFunctions';

export const userValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  // Validate firstName
  if (!formData?.firstName?.trim()) {
    newErrors.firstName = 'First name is required';
    isValid = false;
  }

  // Validate lastName
  if (!formData?.lastName?.trim()) {
    newErrors.lastName = 'Last name is required';
    isValid = false;
  }

  // Validate email
  if (!formData?.email?.trim() || !validateEmail(formData?.email)) {
    newErrors.email = 'Valid email is required';
    isValid = false;
  }

  // Validate password
  if (!formData?.password) {
    newErrors.password = 'Password is required';
    isValid = false;
  }

  // Validate confirmPassword
  if (formData?.password !== formData?.confPassword) {
    newErrors.confPassword = 'Passwords do not match';
    isValid = false;
  }

  //Validate User Role
  if (!formData?.roleId) {
    newErrors.roleId = 'User Role is required';
  }

  //Validate User Role
  if (!formData?.status) {
    newErrors.status = 'Status is required';
  }

  return { newErrors, isValid };
};

export const editUserValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  // Validate firstName
  if (!formData?.firstName?.trim()) {
    newErrors.firstName = 'First name is required';
    isValid = false;
  }

  // Validate lastName
  if (!formData?.lastName?.trim()) {
    newErrors.lastName = 'Last name is required';
    isValid = false;
  }

  // Validate email
  if (!formData?.email?.trim() || !validateEmail(formData?.email)) {
    newErrors.email = 'Valid email is required';
    isValid = false;
  }

  //Validate User Role
  if (!formData?.status) {
    newErrors.status = 'Status is required';
  }

  return { newErrors, isValid };
};

export const userAddressValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.address1) {
    newErrors.address1 = 'Address 1 is required';
    isValid = false;
  }

  if (!formData?.city) {
    newErrors.city = 'City is required';
    isValid = false;
  }

  if (!formData?.state) {
    newErrors.state = 'State is required';
    isValid = false;
  }

  if (formData?.zipCode && formData?.zipCode?.length !== validZipCodeLength) {
    newErrors.zipCode = 'Enter a valid zip code';
    isValid = false;
  }

  if (!formData?.phone) {
    newErrors.phone = 'Phone is required';
    isValid = false;
  }

  if (formData?.phone && formData?.phone?.length !== validPhoneFaxLength) {
    newErrors.phone = 'Enter a valid phone number';
    isValid = false;
  }

  if (formData?.fax && formData?.fax?.length !== validPhoneFaxLength) {
    newErrors.fax = 'Enter a valid fax number';
    isValid = false;
  }

  return { newErrors, isValid };
};
