import { ProfessionalFormProps } from '../../../../interfaces/providersInterface';
import { Calendar } from '../../../atoms/Calendar';
import { Checkbox } from '../../../atoms/Checkbox';
import { InputField } from '../../../atoms/InputField';
import { RadioButton } from '../../../atoms/RadioButton';
import { TextArea } from '../../../atoms/TextArea';
import { formatDate } from '../../../../utils/commonFunctions';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';

export const ProviderProfession: React.FC<ProfessionalFormProps> = ({
  onChange,
  onDateChange,
  mode = 'CREATE',
  errorObject,
  professionalData,
}) => {
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="NPI No."
          name="npi"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.npi ?? undefined}
          error={errorObject && errorObject?.npi}
          errorMessage={errorObject && errorObject?.npi}
          required={true}
        />
        <InputField
          label="UserId"
          name="userId"
          type="number"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.userId ?? undefined}
        />
        <InputField
          label="Password"
          name="password"
          type="password"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.password ?? undefined}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="DEA No."
          name="deaNo"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.deaNo ?? undefined}
          error={errorObject && errorObject?.deaNo}
          errorMessage={errorObject && errorObject?.deaNo}
          required={true}
        />
        {/* <Calendar
          label="Effective Date"
          name="effectiveDate"
          onSelectedDateChanged={(date) => {
            onDateChange('effectiveDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={professionalData?.effectiveDate ? new Date(professionalData?.effectiveDate) : undefined}
          defaultDate={formatDate(professionalData?.effectiveDate)}
          error={errorObject && errorObject?.effectiveDate}
          errorMessage={errorObject && errorObject?.effectiveDate}
          required={true}
        /> */}
        <DatePickerField
          label="Effective Date"
          name="effectiveDate"
          selectedDate={formatDatePickerDate(professionalData?.effectiveDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('effectiveDate', date);
            }
          }}
          required={true}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="Expiration Date"
          name="expirationDate"
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={professionalData?.expirationDate ? new Date(professionalData?.expirationDate) : undefined}
          defaultDate={formatDate(professionalData?.expirationDate)}
          onSelectedDateChanged={(date) => {
            onDateChange('expirationDate', date);
          }}
          error={errorObject && errorObject?.expirationDate}
          errorMessage={errorObject && errorObject?.expirationDate}
        /> */}
        <DatePickerField
          label="Expiration Date"
          name="expirationDate"
          selectedDate={formatDatePickerDate(professionalData?.expirationDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('expirationDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <Checkbox
        label="Applied For"
        name="appliedFor"
        onChange={onChange}
        disabled={mode === 'VIEW' ? true : false}
        defaultChecked={professionalData?.appliedFor ?? undefined}
      />
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="Controlled Substance No."
          name="controlledSubstanceNo"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.controlledSubstanceNo ?? undefined}
        />
        {/* <Calendar
          label="Effective Date"
          name="ceffectiveDate"
          onSelectedDateChanged={(date) => {
            onDateChange('ceffectiveDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={professionalData?.ceffectiveDate ? new Date(professionalData?.ceffectiveDate) : undefined}
          defaultDate={formatDate(professionalData?.ceffectiveDate)}
        /> */}
        <DatePickerField
          label="Effective Date"
          name="ceffectiveDate"
          selectedDate={formatDatePickerDate(professionalData?.ceffectiveDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('ceffectiveDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="Expiration Date"
          name="cexpirationDate"
          onSelectedDateChanged={(date) => {
            onDateChange('cexpirationDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={professionalData?.cexpirationDate ? new Date(professionalData?.cexpirationDate) : undefined}
          defaultDate={formatDate(professionalData?.cexpirationDate)}
        /> */}
        <DatePickerField
          label="Expiration Date"
          name="cexpirationDate"
          selectedDate={formatDatePickerDate(professionalData?.cexpirationDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('cexpirationDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="PLI No."
          name="pliNo"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.pliNo ?? undefined}
        />
        {/* <Calendar
          label="Effective Date"
          name="peffectiveDate"
          disabled={mode === 'VIEW' ? true : false}
          onSelectedDateChanged={(date) => {
            onDateChange('peffectiveDate', date);
          }}
          // defaultDate={professionalData?.peffectiveDate ? new Date(professionalData?.peffectiveDate) : undefined}
          defaultDate={formatDate(professionalData?.peffectiveDate)}
        /> */}
        <DatePickerField
          label="Effective Date"
          name="peffectiveDate"
          selectedDate={formatDatePickerDate(professionalData?.peffectiveDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('peffectiveDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="Expiration Date"
          name="pexpirationDate"
          disabled={mode === 'VIEW' ? true : false}
          onSelectedDateChanged={(date) => {
            onDateChange('pexpirationDate', date);
          }}
          // defaultDate={professionalData?.pexpirationDate ? new Date(professionalData?.pexpirationDate) : undefined}
          defaultDate={formatDate(professionalData?.pexpirationDate)}
        /> */}
        <DatePickerField
          label="Expiration Date"
          name="pexpirationDate"
          selectedDate={formatDatePickerDate(professionalData?.pexpirationDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('pexpirationDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <InputField
        label="ME Number"
        name="meNumber"
        onChange={onChange}
        disabled={mode === 'VIEW' ? true : false}
        defaultValue={professionalData?.meNumber ?? undefined}
      />
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="PTAN"
          name="ptan"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.ptan ?? undefined}
        />
        {/* <Calendar
          label="Effective Date"
          name="ptanEffectiveDate"
          onSelectedDateChanged={(date) => {
            onDateChange('ptanEffectiveDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={professionalData?.ptanEffectiveDate ? new Date(professionalData?.ptanEffectiveDate) : undefined}
          defaultDate={formatDate(professionalData?.ptanEffectiveDate)}
        /> */}
        <DatePickerField
          label="Effective Date"
          name="ptanEffectiveDate"
          selectedDate={formatDatePickerDate(professionalData?.ptanEffectiveDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('ptanEffectiveDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="mt-6">
        Have you ever been enrolled with Medicare in ?
        <div className="flex">
          <RadioButton
            label="Yes"
            name="everEnrolled"
            onChange={onChange}
            defaultChecked={professionalData?.everEnrolled ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            value={true}
          />
          <RadioButton
            label="No"
            name="everEnrolled"
            onChange={onChange}
            defaultChecked={!professionalData?.everEnrolled ? true : false}
            disabled={mode === 'VIEW' ? true : false}
            value={false}
          />
        </div>
      </div>
      <div className="mt-6">
        EHR program due to lack of control over CEHRT availability?
        <div className="flex">
          <RadioButton
            label="Yes"
            name="cehrtAvailability"
            onChange={onChange}
            defaultChecked={professionalData?.cehrtAvailability ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            value={true}
          />
          <RadioButton
            label="No"
            name="cehrtAvailability"
            onChange={onChange}
            defaultChecked={!professionalData?.cehrtAvailability ? true : false}
            disabled={mode === 'VIEW' ? true : false}
            value={false}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="PECOS"
          name="pecos"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.pecos ?? undefined}
        />
        <InputField
          label="PECOS UserId"
          name="pecosUserId"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.pecosUserId ?? undefined}
          error={errorObject && errorObject?.pecosUserId}
          errorMessage={errorObject && errorObject?.pecosUserId}
          required={true}
        />
        <InputField
          label="PECOSPassword"
          name="pecosPassword"
          type="password"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.pecosPassword ?? undefined}
          error={errorObject && errorObject?.pecosPassword}
          errorMessage={errorObject && errorObject?.pecosPassword}
        />
      </div>
      <div className="mt-6">
        <Checkbox
          label=" I do not authorize Simplibill Credentialing Staff to access or update PECOS on my behalf. I understand this could delay credentialing."
          name="understand"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultChecked={professionalData?.understand ?? undefined}
        />
      </div>
      <div className="mt-6">
        It is essential that your CAQH profile is updated to reflect your facility location and employment with
        Simplibill. It is essential that your CAQH profile is updated to reflect your facility location and employment
        with PIH. If you do not supply your CAQH user ID and password in the provider application, our credentialing
        department will not have access to update this information on your behalf. Please be certain to update this
        information otherwise it may cause a delay in your network participation.
      </div>
      <div className="mt-6">
        Can Simplibill Staff update CAQH on your behalf ?
        <div className="flex">
          <RadioButton
            label="Yes"
            name="staffUpdate"
            onChange={onChange}
            defaultChecked={professionalData?.staffUpdate ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            value={true}
          />
          <RadioButton
            label="No"
            name="staffUpdate"
            onChange={onChange}
            defaultChecked={!professionalData?.staffUpdate ? true : false}
            disabled={mode === 'VIEW' ? true : false}
            value={false}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="CAQH No"
          name="caqh"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.caqh ?? undefined}
          error={errorObject && errorObject?.caqh}
          errorMessage={errorObject && errorObject?.caqh}
          required={true}
        />
        <InputField
          label="UserId"
          name="caqhUserId"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.caqhUserId ?? undefined}
          error={errorObject && errorObject?.caqhUserId}
          errorMessage={errorObject && errorObject?.caqhUserId}
          required={true}
        />
        <InputField
          label="Password"
          name="caqhPassword"
          type="password"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          defaultValue={professionalData?.caqhPassword ?? undefined}
          error={errorObject && errorObject?.caqhPassword}
          errorMessage={errorObject && errorObject?.caqhPassword}
          required={true}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        {/* <Calendar
          label="Last Reattestion Date"
          name="lastReattestionDate"
          onSelectedDateChanged={(date) => {
            onDateChange('lastReattestionDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={
          //   professionalData?.lastReattestionDate ? new Date(professionalData?.lastReattestionDate) : undefined
          // }
          defaultDate={formatDate(professionalData?.lastReattestionDate)}
        /> */}
        <DatePickerField
          label="Last Reattestion Date"
          name="lastReattestionDate"
          selectedDate={formatDatePickerDate(professionalData?.lastReattestionDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('lastReattestionDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="Next Reattestion Date"
          name="nextReattestionDate"
          onSelectedDateChanged={(date) => {
            onDateChange('nextReattestionDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={
          //   professionalData?.nextReattestionDate ? new Date(professionalData?.nextReattestionDate) : undefined
          // }
          defaultDate={formatDate(professionalData?.nextReattestionDate)}
        /> */}
        <DatePickerField
          label="Next Reattestion Date"
          name="nextReattestionDate"
          selectedDate={formatDatePickerDate(professionalData?.nextReattestionDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('nextReattestionDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-2 mt-6">
        <TextArea
          label="CAQH Notes"
          name="caqhNote"
          onChange={onChange}
          defaultValue={professionalData?.caqhNote ?? undefined}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        {/* <Calendar
          label="CAQH Effective Date"
          name="caqhEffectiveDate"
          onSelectedDateChanged={(date) => {
            onDateChange('caqhEffectiveDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={professionalData?.caqhEffectiveDate ? new Date(professionalData?.caqhEffectiveDate) : undefined}
          defaultDate={formatDate(professionalData?.caqhEffectiveDate)}
        /> */}
        <DatePickerField
          label="CAQH Effective Date"
          name="caqhEffectiveDate"
          selectedDate={formatDatePickerDate(professionalData?.caqhEffectiveDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('caqhEffectiveDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
        {/* <Calendar
          label="CAQH Expiration Date"
          name="caqhExpirationDate"
          onSelectedDateChanged={(date) => {
            onDateChange('caqhExpirationDate', date);
          }}
          disabled={mode === 'VIEW' ? true : false}
          // defaultDate={
          //   professionalData?.caqhExpirationDate ? new Date(professionalData?.caqhExpirationDate) : undefined
          // }
          defaultDate={formatDate(professionalData?.caqhExpirationDate)}
        /> */}
        <DatePickerField
          label="CAQH Expiration Date"
          name="caqhExpirationDate"
          selectedDate={formatDatePickerDate(professionalData?.caqhExpirationDate)}
          onChange={(date: Date | null) => {
            if (date) {
              onDateChange('caqhExpirationDate', date);
            }
          }}
          disabled={mode === 'VIEW' ? true : false}
        />
      </div>
    </>
  );
};
