import { useEffect, useState } from 'react';
import { CommonAlert } from '../../../atoms/Alert';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { RightSheet } from '../../../rightSheet/RightSheet';
import { CaseForm } from './CaseForm';
import { PatientCaseInterface } from '../../../../interfaces/patientInterface';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import { getPatientByIdRequest, getPatientsByIdSelector } from '../../../../redux/slices/patient/getPatientByIdSlice';
import {
  addPatientCaseRequest,
  addPatientCaseSelector,
  clearAddPatientCaseResponse,
} from '../../../../redux/slices/patient/addPatientCaseSlice';
import {
  clearFetchPatientCaseByIdResponse,
  getPatientCaseByIdRequest,
  getPatientCaseByIdSelector,
} from '../../../../redux/slices/patient/getPatientCaseByIdSlice';
import {
  clearEditPatientCaseResponse,
  editPatientCaseRequest,
  editPatientCaseSelector,
} from '../../../../redux/slices/patient/editPatientCaseSlice';
import { createPatientCaseValidation } from '../../../../utils/patientValidation';
import moment from 'moment';
import { Spinner } from '../../../atoms/Spinner';
import {
  getPatientByIdDemoRequest,
  getPatientsByIdDemoSelector,
} from '../../../../redux/slices/patient/getPatientByIdDemoSlice';
import { RightSheetLarge } from '../../../rightSheet/RightSheetLarge';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import {
  clearFetchPatientInsuranceByTypeResponse,
  getPatientInsuranceByTypeRequest,
  getPatientInsuranceByTypeSelector,
} from '../../../../redux/slices/patient/getPatientInsuranceByTypeSlice';
import { getPatientCaseOptionsRequest } from '../../../../redux/slices/patient/getPatientCaseOptionSlice';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Referring Provider',
    dataIndex: 'refProvider',
    key: 'refProvider',
  },
  {
    title: 'Facility',
    dataIndex: 'facilityName',
    key: 'facilityName',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const dataSource = [
  {
    id: '9888',
    description: '1045/A',
    referringProvider: 'SETH PARKER',
    facility: 'Gampaha',
    status: '11880',
  },
  {
    id: '9888',
    description: '1045/A',
    referringProvider: 'SETH PARKER',
    facility: 'Gampaha',
    status: '11880',
  },
  {
    id: '9888',
    description: '1045/A',
    referringProvider: 'SETH PARKER',
    facility: 'Gampaha',
    status: '11880',
  },
  {
    id: '9888',
    description: '1045/A',
    referringProvider: 'SETH PARKER',
    facility: 'Gampaha',
    status: '11880',
  },
];

export const Case: React.FC<PatientCaseInterface> = ({
  selectedId,
  fetchPatientById,
  fetchDataLoading,
  addDataLoading,
  mode,
}) => {
  const dispatch = useAppDispatch();
  const { fetchPatientByIdData, fetchPatientByIdStatus } = useAppSelector(getPatientsByIdSelector);
  const { addPatientCaseStatus, addPatientCaseLoading } = useAppSelector(addPatientCaseSelector);
  const { fetchPatientCaseByIdData, fetchPatientCaseByIdStatus } = useAppSelector(getPatientCaseByIdSelector);
  const { editPatientCaseLoading, editPatientCaseStatus } = useAppSelector(editPatientCaseSelector);
  const { fetchPatientInsuranceByTypeStatus, fetchPatientInsuranceByTypeData } = useAppSelector(
    getPatientInsuranceByTypeSelector
  );
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [caseFormData, setCaseFormData] = useState<any>({});
  const [alertObj, setAlertObj] = useState<any>({
    color: '',
    message: '',
    error: false,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const [childMode, setChildMode] = useState('CREATE');
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [selectedCaseId, setSelectedCaseId] = useState<number>();
  const [errorObj, setErrorObj] = useState<any>({});
  const [patientInsurance, setPatientInsurance] = useState<any>([]);
  const [patientInsuranceList, setPatientInsuranceList] = useState<any>([]);

  useEffect(() => {
    if (selectedId) {
      getPatientInsurance();
    }
  }, [selectedId]);

  useEffect(() => {
    if (fetchPatientByIdStatus === 'SUCCESS') {
      setDataSource(fetchPatientByIdData);
    }
  }, [fetchPatientByIdStatus]);

  useEffect(() => {
    if (fetchPatientCaseByIdStatus === 'SUCCESS') {
      setCaseFormData(fetchPatientCaseByIdData);
      setVisibleModal(true);
    } else if (fetchPatientCaseByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearFetchPatientCaseByIdResponse());
  }, [fetchPatientCaseByIdStatus]);

  useEffect(() => {
    if (addPatientCaseStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'case');
      getCaseDropdownData();
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setCaseFormData({});
        setErrorObj({});
        setChildMode('CREATE');
      }, 3000);
      dispatch(clearAddPatientCaseResponse());
    } else if (addPatientCaseStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddPatientCaseResponse());
    }
  }, [addPatientCaseStatus]);

  useEffect(() => {
    if (editPatientCaseStatus === 'SUCCESS') {
      fetchPatientById(selectedId, 'case');
      getCaseDropdownData();
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setErrorObj({});
        setCaseFormData({});
        setChildMode('CREATE');
      }, 3000);
      dispatch(clearEditPatientCaseResponse());
    } else if (editPatientCaseStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientCaseResponse());
    }
  }, [editPatientCaseStatus]);

  useEffect(() => {
    if (fetchPatientInsuranceByTypeStatus === 'SUCCESS') {
      setPatientInsuranceList(fetchPatientInsuranceByTypeData);
    } else if (fetchPatientInsuranceByTypeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong in Patient Insurance data!',
        error: true,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearFetchPatientInsuranceByTypeResponse());
  }, [fetchPatientInsuranceByTypeStatus]);

  const getPatientInsurance = () => {
    dispatch(getPatientInsuranceByTypeRequest({ patientId: selectedId, type: 'ALL' }));
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    setErrorObj({});
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setChildMode('EDIT');
    setSelectedToEdit(dataSet);
    setSelectedCaseId(dataSet?.id);
    getCaseByID(dataSet?.id);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedCaseId(dataSource[rowIndex]?.id);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const getCaseByID = (caseId: number) => {
    dispatch(getPatientCaseByIdRequest({ patientId: selectedId, caseId: caseId }));
  };

  const getCaseDropdownData = () => {
    dispatch(getPatientCaseOptionsRequest({ patientId: selectedId }));
  };

  const resetAlert = () => {
    setVisibleAlert(false);
    setAlertObj({ color: '', message: '', error: false });
  };

  const populateAlert = (color: string = '', error: boolean = false, message: any = '', errorObject: any = {}) => {
    setErrorObj(errorObject);
    setAlertObj({
      color: color,
      message: error ? (
        <div>
          {Object.keys(errorObject)?.map((msg: any, index: any) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span style={{ marginLeft: '8px' }}>{errorObject[msg]}</span>
            </div>
          ))}
        </div>
      ) : (
        message
      ),
      error: error,
    });
    setVisibleAlert(true);
  };

  const onSubmit = () => {
    let formObject = { ...caseFormData };

    if (caseFormData.primaryInsuranceId) {
      formObject.primaryInsuranceId = parseInt(caseFormData.primaryInsuranceId);
    }

    if (caseFormData.secondaryInsuranceId) {
      formObject.secondaryInsuranceId = parseInt(caseFormData.secondaryInsuranceId);
    }

    if (caseFormData.tertiaryInsurance) {
      formObject.tertiaryInsurance = parseInt(caseFormData.tertiaryInsurance);
    }

    if (caseFormData.facilityId) {
      formObject.facilityId = parseInt(caseFormData.facilityId);
    }

    if (caseFormData.providerId) {
      formObject.providerId = parseInt(caseFormData.providerId);
    }

    let obj = {
      patientId: selectedId,
      caseData: formObject,
    };

    const validationDetails = createPatientCaseValidation(obj?.caseData);

    if (childMode === 'CREATE') {
      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        populateAlert('failure', true, '', validationDetails?.newErrors);
      } else {
        dispatch(addPatientCaseRequest(obj));
      }
    } else if (childMode === 'EDIT') {
      let obj = {
        patientId: selectedId,
        caseData: formObject,
        caseId: selectedCaseId,
      };

      const validationDetails = createPatientCaseValidation(obj?.caseData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        populateAlert('failure', true, '', validationDetails?.newErrors);
      } else {
        dispatch(editPatientCaseRequest(obj));
      }
    }
  };

  const handleAlertClose = () => {
    resetAlert();
  };

  const handleAddNewModal = () => {
    setCaseFormData({});
    setVisibleModal(true);
  };

  const onCloseModal = () => {
    setVisibleModal(false);
    resetAlert();
  };

  const handleCaseCheckChange = (e: any) => {
    setCaseFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleCaseFormChanges = (e: any) => {
    setCaseFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeEndDate = (date: Date) => {
    setCaseFormData((prev: any) => ({ ...prev, endDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeStartDate = (date: Date) => {
    setCaseFormData((prev: any) => ({ ...prev, startDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeDateSimilarSymptoms = (date: Date) => {
    setCaseFormData((prev: any) => ({ ...prev, dateSimilarSymptoms: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeDateFirstConsultationDate = (date: Date) => {
    setCaseFormData((prev: any) => ({ ...prev, firstConsultationDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeHospitalizationDateFrom = (date: Date) => {
    setCaseFormData((prev: any) => ({ ...prev, hospitalizationDateFrom: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeHospitalizationDateTo = (date: Date) => {
    setCaseFormData((prev: any) => ({ ...prev, hospitalizationDateTo: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeIllnessIndicatorDate = (date: Date) => {
    setCaseFormData((prev: any) => ({ ...prev, illnessIndicatorDate: moment(date).format('YYYY-MM-DD') }));
  };

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <div className="w-64 ml-auto">
        {mode !== 'VIEW' && (
          <CommonButton
            label="Add New"
            buttonType="primary"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fill-rule="evenodd"
                  d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            onClick={handleAddNewModal}
          />
        )}
      </div>
      {fetchDataLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={mode === 'VIEW' ? false : true}
              columns={columns}
              dataSource={dataSource}
              enableDelete={false}
              enableEdit={true}
              enableView={true}
              onDelete={onDelete}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent onClick={handleAddNewModal} enableCreateButton={mode === 'VIEW' ? false : true} mode={mode} />
          )}
        </>
      )}

      {visibleModal && (
        <RightSheetLarge
          title="Cases"
          submitButtonTitle="Submit"
          cancelButtonTitle="Cancel"
          children={
            <CaseForm
              onChange={handleCaseFormChanges}
              errorObject={errorObj}
              caseFormEditData={caseFormData}
              onChangeEndDate={onChangeEndDate}
              onChangeStartDate={onChangeStartDate}
              onChangeHospitalizationDateTo={onChangeHospitalizationDateTo}
              onChangeHospitalizationDateFrom={onChangeHospitalizationDateFrom}
              onChangeDateFirstConsultationDate={onChangeDateFirstConsultationDate}
              onChangeDateSimilarSymptoms={onChangeDateSimilarSymptoms}
              onChangeIllnessIndicatorDate={onChangeIllnessIndicatorDate}
              patientInsurance={patientInsuranceList}
              handleCaseCheckChange={handleCaseCheckChange}
            />
          }
          onClose={onCloseModal}
          onSubmit={onSubmit}
          loading={addPatientCaseLoading || editPatientCaseLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          enableStepper={false}
        />
      )}
    </>
  );
};
