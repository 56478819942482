import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchCPTCodesInitialInterface, GetCPTCodesInterface } from '../../../interfaces/systemInterfaces';
import { getCPTCodesAPIRequest } from '../../../api/system/code';

const initialState: FetchCPTCodesInitialInterface = {
  cptCodesLoading: false,
  cptCodesData: [],
  cptCodesError: null,
  cptCodesStatus: 'IDLE',
};

export const getCPTCodesRequest: any = createAsyncThunk(
  'codes/cpt/get',
  async (code: GetCPTCodesInterface, thunkAPI: any) => {
    try {
      const response: any = await getCPTCodesAPIRequest(code);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getCPTCodesSlice = createSlice({
  name: 'fetchCPTCodes',
  initialState,
  reducers: {
    clearFetchCPTCodesResponse: (state) => {
      state.cptCodesLoading = false;
      state.cptCodesStatus = 'IDLE';
      state.cptCodesData = [];
      state.cptCodesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCPTCodesRequest.pending, (state, action) => {
        state.cptCodesLoading = true;
        state.cptCodesStatus = 'PENDING';
      })
      .addCase(getCPTCodesRequest.fulfilled, (state, action) => {
        state.cptCodesLoading = false;
        state.cptCodesData = action.payload;
        state.cptCodesStatus = 'SUCCESS';
      })
      .addCase(getCPTCodesRequest.rejected, (state, action) => {
        state.cptCodesLoading = false;
        state.cptCodesError = action.payload;
        state.cptCodesStatus = 'FAILED';
      });
  },
});

export const { clearFetchCPTCodesResponse } = getCPTCodesSlice.actions;

export const getCPTCodesSelector = (state: RootState) => state.GetCPTCodes;

export default getCPTCodesSlice.reducer;
