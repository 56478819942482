import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  FetchClaimEraAutoPostInterface,
  FetchClaimEraAutoPostPropsInterface,
} from '../../../../interfaces/paymentInterfaces';
import { getClaimEraAutoPostAPIRequest } from '../../../../api/payments/claimEraAutoPost';

const initialState: FetchClaimEraAutoPostPropsInterface = {
  fetchClaimEraAutoPostLoading: false,
  fetchClaimEraAutoPostData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchClaimEraAutoPostError: null,
  fetchClaimEraAutoPostStatus: 'IDLE',
};

export const getClaimEraAutoPostRequest: any = createAsyncThunk(
  'claim-era-auto-post/get',
  async (searchParams: FetchClaimEraAutoPostInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimEraAutoPostAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimEraAutoPostSlice = createSlice({
  name: 'fetchClaimEraAutoPost',
  initialState,
  reducers: {
    clearFetchClaimEraAutoPostResponse: (state) => {
      state.fetchClaimEraAutoPostLoading = false;
      state.fetchClaimEraAutoPostStatus = 'IDLE';
      state.fetchClaimEraAutoPostData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchClaimEraAutoPostError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimEraAutoPostRequest.pending, (state, action) => {
        state.fetchClaimEraAutoPostLoading = true;
        state.fetchClaimEraAutoPostStatus = 'PENDING';
      })
      .addCase(getClaimEraAutoPostRequest.fulfilled, (state, action) => {
        state.fetchClaimEraAutoPostLoading = false;
        state.fetchClaimEraAutoPostStatus = 'SUCCESS';
        state.fetchClaimEraAutoPostData = action.payload;
      })
      .addCase(getClaimEraAutoPostRequest.rejected, (state, action) => {
        state.fetchClaimEraAutoPostLoading = false;
        state.fetchClaimEraAutoPostError = action.payload;
        state.fetchClaimEraAutoPostStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimEraAutoPostResponse } = getClaimEraAutoPostSlice.actions;

export const getClaimEraAutoPostSelector = (state: RootState) => state.GetClaimEraAutoPost;

export default getClaimEraAutoPostSlice.reducer;
