import { Button, Modal } from 'flowbite-react';
import { CommonButton } from '../../atoms/CommonButton';
import { Checkbox } from '../../atoms/Checkbox';
import { SelectInput } from '../../atoms/SelectInput';
import { useEffect, useState } from 'react';
import { Calendar } from '../../atoms/Calendar';
import moment from 'moment';
import { InputField } from '../../atoms/InputField';
import { ClaimCPTSearch } from './ClaimCPTSearch';
import { HiOutlineSearch, HiShoppingCart } from 'react-icons/hi';
// import { ModifierSearchModal } from './ModifierSearchModal';
import { DatePickerField } from '../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../utils/commonFunctions';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import {
  addVisitCPTCodeRequest,
  addVisitCPTCodeSelector,
  clearAddVisitCPTCodeResponse,
} from '../../../redux/slices/patient/addVisitCPTCodeSlice';
import { CommonAlert } from '../../atoms/Alert';
import { CustomBinButton, CustomSearchButton } from '../../list/patients/visit/AddCPTModal';
import { ClaimEditModifierSearchModal } from './ClaimEditModifierSearchModal';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { patientVisitEditCPTValidation } from '../../../utils/patientValidation';

const customSelectArr = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
];

const customUnitArr = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
];

export const ClaimEditCPTModal = ({
  mode,
  editData = {},
  openAddCPT,
  setOpenAddCPT,
  visitId,
  icdCodesDataSource,
  cptCodesDataSource,
  modifiersDataSource,
  getCPTCodeList,
  icdDataSource,
  handleRemoveCPTCodeDataSource,
}: any) => {
  const dispatch = useAppDispatch();
  const { addVisitCPTCodeStatus, addVisitCPTCodeData, addVisitCPTCodeError } = useAppSelector(addVisitCPTCodeSelector);

  const [groupCheckBox, setGroupCheckbox] = useState<any>([]);
  const [visitDate, setVisitDate] = useState<any>();
  const [otherFormData, setOtherFormData] = useState<any>(editData);
  const [visibleCPTSearch, setVisibleCPTSearch] = useState<boolean>(false);
  const [visibleModifierSearch, setVisibleModifierSearch] = useState<boolean>(false);
  const [selectedModifier, setSelectedModifier] = useState<string>('');
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  // console.log('=== editData ===', editData);

  useEffect(() => {
    if (icdCodesDataSource?.length > 0) {
      setGroupCheckbox(icdCodesDataSource);
    }
  }, [icdCodesDataSource]);

  useEffect(() => {
    if (addVisitCPTCodeStatus === 'SUCCESS') {
      getCPTCodeList(visitId);
      setAlertObj({
        color: 'success',
        message: 'Successfully Added!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
        setOpenAddCPT(false);
      }, 3000);
    } else if (addVisitCPTCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addVisitCPTCodeError?.message,
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearAddVisitCPTCodeResponse());
  }, [addVisitCPTCodeStatus]);

  const resetAlert = () => {
    setVisibleAlert(false);
    setAlertObj({ color: '', message: '', error: false });
  };

  const populateAlert = (color: string = '', error: boolean = false, message: any = '', errorObject: any = {}) => {
    setErrorObj(errorObject);
    setAlertObj({
      color: color,
      message: !error ? (
        <div>
          {Object.keys(errorObject)?.map((msg: any, index: any) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span style={{ marginLeft: '8px' }}>{errorObject[msg]}</span>
            </div>
          ))}
        </div>
      ) : (
        message
      ),
      error: error,
    });
    setVisibleAlert(true);
  };

  const handleSubmit = () => {
    let icdCodeArr = [];
    for (let index = 0; index < groupCheckBox.length; index++) {
      const element = groupCheckBox[index];
      let obj: any = {};
      if (element?.checked) {
        obj['code'] = element?.code;
        obj['value'] = element?.value;
      }
      icdCodeArr[icdCodeArr.length] = obj;
    }

    let visitData = {
      icdCodes: icdCodeArr,
      visitDate: otherFormData?.visitDate ?? moment().format('YYYY/MM/DD'),
      cptCode: otherFormData?.cptCode,
      unit: parseInt(otherFormData?.unit),
      modifier1: otherFormData?.modifier1 ?? '',
      modifier2: otherFormData?.modifier2 ?? '',
      modifier3: otherFormData?.modifier3 ?? '',
      modifier4: otherFormData?.modifier4 ?? '',
    };

    const validationResults = patientVisitEditCPTValidation(visitData);
    // console.log('=== validationResults ===', validationResults);

    if (Object.keys(validationResults?.newErrors)?.length > 0) {
      populateAlert('failure', false, '', validationResults?.newErrors);
    } else {
      dispatch(addVisitCPTCodeRequest({ visitId, visitData }));
    }
  };

  const handleCheckboxes = (e: any) => {
    const { name, checked } = e.target;
    setGroupCheckbox((prev: any) =>
      prev.map((item: any) => (item?.code.toString() === name ? { ...item, checked, value: undefined } : item))
    );
  };

  const handleRelatedSelect = (e: any) => {
    const { name, value } = e.target;
    // console.log('=== value ===', value, name);

    setGroupCheckbox((prev: any) => {
      // console.log('=== prev ===', prev, prev[0]?.code === name);
      return prev.map((item: any) => (item?.code?.toString() === name ? { ...item, value: parseInt(value) } : item));
    });
  };

  const onChangeVisitDate = (date: Date | null) => {
    setOtherFormData((prev: any) => ({ ...prev, visitDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeOtherFields = (e: any) => {
    setOtherFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onCloseCPTSearch = (data: boolean) => {
    setVisibleCPTSearch(data);
  };

  const onCloseModifierSearch = (data: boolean) => {
    setVisibleModifierSearch(data);
  };

  const handleAddedCPTRow = (data: any) => {
    setOtherFormData((prev: any) => ({ ...prev, cptCode: data?.code }));
    // console.log('=== data - added row ===', data);
  };

  const handleRemoveCPTCode = () => {
    setOtherFormData((prevState: any) => {
      const { cptCode: _, ...newState } = prevState;
      return newState;
    });
  };

  const handleAddedModifierRow = (data: any) => {
    setOtherFormData((prev: any) => ({ ...prev, [selectedModifier]: data?.code }));
    // console.log('=== data - added row ===', data);
  };

  const handleDeleteModifiers = (id: string) => {
    setOtherFormData((prevState: any) => {
      const { [id]: _, ...newState } = prevState;
      return newState;
    });
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  return (
    <>
      <Modal show={openAddCPT} onClose={() => setOpenAddCPT(false)}>
        <Modal.Header>Add CPT Code</Modal.Header>
        <Modal.Body>
          {visibleAlert && !alertObj?.error && (
            <CommonAlert
              color={alertObj?.color}
              message={alertObj?.message}
              onClose={handleAlertClose}
              alertClassName="w-3/6"
            />
          )}
          <div className="grid md:grid-cols-3 gap-5 mt-6">
            {groupCheckBox?.length > 0 &&
              groupCheckBox?.map((item: any, key: any) => {
                return (
                  <div>
                    <Checkbox
                      label={item?.code}
                      onChange={handleCheckboxes}
                      name={item?.code}
                      defaultChecked={item?.checked}
                    />
                    <SelectInput
                      options={customSelectArr}
                      name={item?.code}
                      onChange={handleRelatedSelect}
                      disabled={!item?.checked}
                      value={item?.value ?? ''}
                    />
                  </div>
                );
              })}
          </div>
          <div className="grid md:grid-cols-2 gap-5 mt-6">
            <InputField
              label="CPT"
              name="cptCode"
              onChange={onChangeOtherFields}
              disabled={true}
              value={otherFormData?.cptCode ?? ''}
              required={true}
            />
            <div className="flex gap-2 mt-7">
              <CommonButton
                style={{ height: '37px' }}
                type="button"
                label={'Reset'}
                buttonType="secondary"
                onClick={() => handleRemoveCPTCode()}
              />
              <CommonButton
                style={{ height: '37px' }}
                type="button"
                label={'Search'}
                buttonType="primary"
                onClick={() => setVisibleCPTSearch(true)}
              />
            </div>
            {/* <CustomSearchButton onClick={() => setVisibleCPTSearch(true)} /> */}
          </div>
          <div className="grid md:grid-cols-2 gap-5 mt-6">
            {/* <Calendar
              onSelectedDateChanged={onChangeVisitDate}
              name="visitDate"
              label="Visit Date"
              defaultDate={editData?.visitDate ? new Date(editData?.visitDate) : undefined}
            /> */}
            <DatePickerField
              label="Visit Date"
              name="visitDate"
              selectedDate={formatDatePickerDate(editData?.visitDate ?? otherFormData?.visitDate)}
              onChange={onChangeVisitDate}
            />
            <SelectInput
              label="Unit"
              options={customUnitArr}
              name="unit"
              onChange={onChangeOtherFields}
              defaultValue={editData?.unit ?? undefined}
            />
          </div>
          <div className="grid md:grid-cols-4 gap-5 mt-6">
            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 1"
                name="modifier1"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier1 ? otherFormData?.modifier1 : ''}
              />
              {otherFormData?.modifier1 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier1')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier1');
                  }}
                />
              )}
            </div>

            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 2"
                name="modifier2"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier2 ? otherFormData?.modifier2 : ''}
              />
              {otherFormData?.modifier2 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier2')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier2');
                  }}
                />
              )}
            </div>

            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 3"
                name="modifier3"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier3 ? otherFormData?.modifier3 : ''}
              />
              {otherFormData?.modifier3 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier3')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier3');
                  }}
                />
              )}
            </div>

            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 4"
                name="modifier4"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier4 ? otherFormData?.modifier4 : ''}
              />
              {otherFormData?.modifier4 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier4')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier4');
                  }}
                />
              )}
              {/* <CustomSearchButton onClick={() => setVisibleCPTSearch(true)} /> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={() => setOpenAddCPT(false)} />
          <CommonButton
            type="button"
            label={'Submit'}
            buttonType={!otherFormData?.cptCode ? 'disabled' : 'primary'}
            onClick={handleSubmit}
            disabled={!otherFormData?.cptCode}
          />
        </Modal.Footer>
      </Modal>
      {visibleCPTSearch && (
        <ClaimCPTSearch
          openClaimCPTSearch={visibleCPTSearch}
          setOpenClaimCPTSearch={onCloseCPTSearch}
          cptCodesDataSource={cptCodesDataSource}
          handleAddedCPTRow={handleAddedCPTRow}
          modifiersDataSource={modifiersDataSource}
          handleRemoveCPTCodeDataSource={handleRemoveCPTCodeDataSource}
        />
      )}

      {visibleModifierSearch && (
        <ClaimEditModifierSearchModal
          modifiersDataSource={modifiersDataSource}
          modifierModalOpen={visibleModifierSearch}
          setOpenModifierModal={onCloseModifierSearch}
          handleAddedModifierRow={handleAddedModifierRow}
        />
      )}
    </>
  );
};
